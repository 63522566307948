import { defaultOptionsLoading } from "../../assets/lottie-animations/animationOptions";
import "./index.scss";
import Lottie from "react-lottie";

const DeleteExtraModal = ({
  closeModal,
  doDeleteExtra,
  doGetAllExtras,
  extra,
  loading,
}) => {
  return (
    <div className="delete-extra-container">
      <div className="delete-extra-header">
        <p>Vous êtes sûre de vouloir supprimer</p>
      </div>
      <div className="delete-extra-body">
        <p className="delete-extra-title-txt">Extra</p>
        <p className="delete-extra-name-txt">{extra?.name}</p>
      </div>
      <div className="delete-extra-footer">
        <button
          className="delete-extra-btn-cancel"
          onClick={() => closeModal()}
        >
          Annuler
        </button>
        {loading ? (
          <Lottie options={defaultOptionsLoading} height={80} width={80} />
        ) : (
          <button
            className="delete-extra-btn-confirm"
            onClick={() => doDeleteExtra()}
          >
            Supprimer
          </button>
        )}
      </div>
    </div>
  );
};
export default DeleteExtraModal;
