import "./App.scss";

import React from "react";

import "boxicons/css/boxicons.min.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import AppLayout from "./layout/app-layout";

import OrdersScreen from "./screens/orders-screen";
import HomeScreen from "./screens/home-screen";
import StoresScreen from "./screens/stores-screens";
import PizzaScreen from "./screens/pizzas-screen";
import RhScreen from "./screens/rh-screen";

import { useSocket, useSocketConfig } from "./services/socket";
import PizzaDetailScreen from "./screens/pizza-detail";
import PizzaUpdateScreen from "./screens/pizza-update";
import StoreDetail from "./screens/store-detail";
import StoreUpdate from "./screens/store-update";
import Ingrediants from "./screens/ingrediants-screens";
import ExtraScreen from "./screens/extra-screen";
import UsersScreen from "./screens/users-screens";
import CourrierScreen from "./screens/CourrierScreen";
import ContextApp from "./components/Context/AppContext";

function App() {
  useSocketConfig({
    init: true,
  });

  useSocket({
    socket: useSocketConfig(),
  });

  return (
    <ContextApp>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AppLayout />}>
            <Route index element={<Navigate to="/accueil" />} />
            <Route path="/commandes" element={<OrdersScreen />} />
            <Route path="/accueil" element={<HomeScreen />} />
            <Route path="/stores" element={<StoresScreen />} />
            <Route path="/ingrediants" element={<Ingrediants />} />

            <Route path="/pizza" element={<PizzaScreen />} />
            <Route path="/pizza/:pizzaName" element={<PizzaDetailScreen />} />
            <Route path="/stores/:id" element={<StoreDetail />} />
            <Route path="/extras" element={<ExtraScreen />} />
            <Route path="/users" element={<UsersScreen />} />
            <Route path="/courier" element={<CourrierScreen />} />

            <Route
              path="/pizza/update/:pizzaNameUpdate"
              element={<PizzaUpdateScreen />}
            />
            <Route path="/stores/update/:id" element={<StoreUpdate />} />
            <Route path="/rh" element={<RhScreen />} />
            <Route path="/*" element={<Navigate to="/accueil" />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ContextApp>
  );
}

export default App;
