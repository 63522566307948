import "./index.scss";

import React from "react";

import { ReactComponent as CloseIcon } from "../../../../assets/icons/close-icon.svg";
import { ReactComponent as BackIcon } from "../../../../assets/icons/back-icon.svg";
import { ReactComponent as InvoiceIcon } from "../../../../assets/icons/invoice-icon.svg";
import { OrderLineTypes } from "../../../../utils/Types/OrdersDataTypes";

import jsPDF from "jspdf";
import getInvoiceHtml from "./getInvoiceHtml";

type SlideOutInvoicePagePropsType = {
  setActivePage: (
    args: "orderDetailsPage" | "InvoicePage" | "OrderTrackingPage"
  ) => void;
  onClose: Function;
  orderLine: OrderLineTypes | undefined;
  deliveryFrees: number | undefined;
  totalFees: number | undefined;
  orderId: string | undefined;
};

function SlideOutInvoicePage({
  setActivePage,
  onClose,
  orderLine,
  deliveryFrees,
  totalFees,
  orderId,
}: SlideOutInvoicePagePropsType): JSX.Element {
  function downloadPDF() {
    const doc = new jsPDF({
      orientation: "portrait",
    });

    doc.html(
      getInvoiceHtml(
        orderLine?.product,
        deliveryFrees,
        totalFees,
        orderLine?.orderNumber,
        orderId
      ),
      {
        callback: function (doc) {
          doc.save("invoice.pdf");
        },
        margin: [10, 10, 10, 10],
        autoPaging: "text",
        x: 0,
        y: 0,
        width: 190, //target width in the PDF document
        windowWidth: 675, //window width in CSS pixels
      }
    );
  }

  console.log("orderLine" , orderLine);
  
  return (
    <>
      <div className="header">
        <div
          onClick={() => setActivePage("orderDetailsPage")}
          className="header-icon"
        >
          <BackIcon width={15} height={15} />
        </div>
        <div className="invoice-title">Facture</div>
        <div className="header-icon" onClick={() => onClose()}>
          <CloseIcon width={15} height={15} />
        </div>
      </div>

      <div className="content-slideout">
        <div className="invoice-header">
          <>N°{orderLine?.orderNumber}</>
          <button className="invoice-button" onClick={downloadPDF}>
            <InvoiceIcon />
            <>Download as PDF</>
          </button>
        </div>
        <div className="invoice-details">
          {orderLine?.product?.map((oneProduct, index) => {
            console.log("one" , oneProduct);
            
            return (
              <React.Fragment key={index}>
                <div className="pizza-name-price">
                  <div>{oneProduct?.name.replace("PZZ_", "Pizza ")} </div>
                  <div>{oneProduct.Price.toFixed(3) + " DT"} </div>
                </div>
                <div className="pizza-size"> Taille : {oneProduct?.size} </div>
                <div className="pizza-toppings-list">
                  {oneProduct?.toppings?.map((topping, index) => {
                    return (
                      <React.Fragment key={index}>
                        {topping?.toppingName}
                        {index === oneProduct?.toppings?.length - 1
                          ? "."
                          : ", "}
                      </React.Fragment>
                    );
                  })}
                </div>
              </React.Fragment>
            );
          })}
          <div className="delivery-fees">
            <div>Livraison</div>
            <div> {deliveryFrees?.toFixed(3) + " DT" ?? ""}</div>
          </div>

          <div className="invoice-divider" />
          <div className="total-fees">
            <div>Total</div>
            <div className="total-fees-price">
              {" "}
              {totalFees?.toFixed(3) + " DT"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SlideOutInvoicePage;
