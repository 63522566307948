import runnerIcon from "../../../assets/icons/runner.svg";
import ordersIcon from "../../../assets/icons/home-page-orders-icon.svg";
import { useState, useEffect } from "react";
import reclamationIcon from "../../../assets/icons/reclamation-icon.svg";
import timerIcon from "../../../assets/icons/timer-icon.svg";
import getAverageEstimatedTime from "../../../services/api/get-Average-estimated-time";
import { toast } from "react-toastify";
import getCommandesCount from "../../../services/api/get-commandes-number";

const HomePageData = () => {
  const [commandesNumber, setCommandesNumber] = useState<number>();
  // const [RunnersNumber, setRunnersNumber] = useState<any>();
  const [deliveryTimeAverage, setDeliveryTimeAverage] = useState<number>();

  const doGetAverageEstimatedTime = () => {
    getAverageEstimatedTime()
      .then((response) => {
        if (response.data.status === 500) {
          toast.error("Internal server error");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);

          return;
        }
        if (response.data.success) {
          setDeliveryTimeAverage(response.data.content.averageDeliveryTime);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const doGetCommandesCount = () => {
    getCommandesCount()
      .then((response) => {

        if (response.data.status === 500) {
          toast.error("Internal server error");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);

          return;
        }
        if (response.data.success) {
          setCommandesNumber(response.data.content.orderCount.count);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    doGetAverageEstimatedTime();
    doGetCommandesCount();
  }, []);


  return [
    {
      title: "Commandes",
      icon: ordersIcon,
      iconBackgroundColor: "rgba(21, 155, 123, 0.15)",
      textColor: "rgba(21, 155, 123, 1)",
      value: commandesNumber,
    },
    {
      title: "Runner",
      icon: runnerIcon,
      iconBackgroundColor: "rgba(110, 184, 201, 0.15)",
      textColor: "rgba(204, 66, 66, 1)",
      value: "02",
    },
    {
      title: "Réclamations",
      icon: reclamationIcon,
      iconBackgroundColor: "rgba(155, 21, 21, 0.15)",
      textColor: "rgba(204, 66, 66, 1)",

      value: "02",
    },
    {
      title: "Temps moyen de livraison",
      icon: timerIcon,
      iconBackgroundColor: "rgba(254, 191, 63, 0.18)",
      textColor: "rgba(21, 155, 123, 1)",

      value: deliveryTimeAverage?.toFixed(3),
    },
  ];
};

export default HomePageData;
