// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-titles-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  color: rgb(98, 98, 98);
  font-weight: 700;
  flex-wrap: nowrap;
}
.page-titles-container .line-after-title {
  height: 1px;
  background-color: rgb(219, 219, 219);
  margin: 0px 45px 5px 30px;
  width: 80%;
}`, "",{"version":3,"sources":["webpack://./src/components/page-title/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,sBAAA;EACA,gBAAA;EACA,iBAAA;AACF;AAAE;EACE,WAAA;EACA,oCAAA;EACA,yBAAA;EACA,UAAA;AAEJ","sourcesContent":[".page-titles-container {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-end;\n  color: rgba(98, 98, 98, 1);\n  font-weight: 700;\n  flex-wrap: nowrap;\n  .line-after-title {\n    height: 1px;\n    background-color: rgba(219, 219, 219, 1);\n    margin: 0px 45px 5px 30px;\n    width: 80%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
