import ExtraType from "../../../utils/Types/ExtraType";
import {
  OrderTypes,
  productOrderLine,
} from "../../../utils/Types/OrdersDataTypes";
import { Topping } from "../../../utils/Types/Topping";
import "./index.scss";
interface props {
  order: OrderTypes;
}
const OrderCard = ({ order }: props) => {
  return (
    <div className="order-card-container">
      <div className="order-card-header">
        <p className="order-card-header-txt">order n°{order?.orderNumber}</p>
        <p className="order-card-header-status-txt">en cours</p>
      </div>
      <div className="order-card-body">
        <div
          className={
            order?.OrderType === "delivery"
              ? "order-card-order-type-delivery-container"
              : "order-card-order-type-pickup-container"
          }
        >
          <p
            className={
              order?.OrderType === "delivery"
                ? "order-card-order-type-delivery-txt"
                : "order-card-order-type-pickup-txt"
            }
          >
            {order?.OrderType}
          </p>
        </div>
        <div className="order-card-animation-container">
          <img
            src={require("./../../../assets/animationLoading.gif")}
            alt="gifanimation"
            className="order-card-img-animation"
          />
        </div>
      </div>
      <div className="order-card-footer">
        {order?.orderLine?.product?.length > 0 ? (
          order?.orderLine?.product?.map((p: productOrderLine, index: any) => (
            <div className="order-card-item-container">
              <p className="order-card-item-txt">
                {p?.quantity} X Pizza {p?.name?.substring(4)}
              </p>
              <div className="order-card-toppings-container">
                <p className="order-card-topping-title-txt">Toppings : </p>
                {p.toppings.length > 0 ? (
                  p?.toppings?.map((topping: Topping, index: any) => (
                    <div className="order-card-items-toppings-container">
                      <p className="order-card-item-topping-txt">
                        {topping?.quantity} X {topping?.toppingName}
                      </p>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          ))
        ) : (
          <></>
        )}
        <div style={{ paddingTop: 15 }}>
          {order?.orderLine?.extras?.length > 0 && (
            <p className="extra-txt">Extra</p>
          )}
          {order?.orderLine?.extras?.length > 0 ? (
            order?.orderLine?.extras?.map((ext: ExtraType, index: any) => (
              <div className="order-card-item-container">
                <p style={{ fontSize: 16, fontWeight: "500", color: "#000" }}>
                  {ext?.quantity} X {ext?.name}
                </p>
                <div className="oc-empty-wrapper"></div>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
export default OrderCard;
