import React, { useEffect } from "react";
import "./index.scss";
import Select from "react-select";
import { Formik, Field, Form } from "formik";
import { toast } from "react-toastify";
import getIngredientsFromInv from "../../../services/api/get-ingredients-from-inventory";
import ContextApp, {
  AppContext,
  AppContextType,
} from "../../Context/AppContext";

const options = [
  { label: "Fromage", value: "cheese" },
  { label: "Légumes", value: "vegtables" },
  { label: "Viande", value: "meat" },
];
const optionsDose = [
  { label: "gramme", value: "gr" },
  { label: "litre", value: "l" },
];

interface Props {
  toUpdateIng: any;
  setStep: (args: string) => void;
  closeModal: () => void;
  setDose: (args: string) => void;
  setValuesForm?: (args: any) => void;
  valuesForm: any;
}

const NewIngredientModal = ({
  toUpdateIng,
  setStep,
  closeModal,
  setDose,
  setValuesForm,
  valuesForm,
}: Props) => {
  const {
    optionsToppings,
    setOptionsToppings,
    selectedOptionTypeTopping,
    setSelectedOptionTypeTopping,
  } = React.useContext(AppContext) as AppContextType;

  const doGetIngredientsFromInv = () => {
    getIngredientsFromInv()
      .then((response) => {
        if (response.data.status === 500) {
          toast.error("Internal server error");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);
          return;
        }
        if (response.data.success) {
          const transformedArray = response?.data?.content?.products.reduce(
            (acc: any, item: any) => {
              acc.push({
                label: `${item.name} prix moy : ${item.averagePrice} dt`,
                value: item.name,
              });
              return acc;
            },
            []
          );
          setOptionsToppings(transformedArray);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error);
      });
  };

  useEffect(() => {
    doGetIngredientsFromInv();

    if (toUpdateIng) {
      // Set initial form values based on toUpdateIng
      setValuesForm(toUpdateIng);
    }
  }, [toUpdateIng]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        toppingName: valuesForm?.toppingName || "",
        portion: valuesForm?.portion || "",
        unit: valuesForm?.unit || "gramme",
        price: valuesForm?.price || "",
        type: valuesForm?.type || "",
      }}
      onSubmit={(values) => {
        setValuesForm(values);
        setStep("second");
      }}
    >
      {({ setFieldValue, values }) => (
        <Form className="pzz-new-ingredient-container">
          <div className="pzz-new-ingredient-header">
            <h2 style={{ fontSize: 16, fontWeight: "500", color: "#000" }}>
              {Boolean(toUpdateIng)
                ? "Modifier Ingrédient"
                : "Nouvel Ingrédient"}
            </h2>
            <i
              className="bx bx-x"
              style={{ fontSize: 22 }}
              onClick={() => closeModal()}
            ></i>
          </div>

          <div style={{ height: "55%", overflow: "auto" }}>
            <div className="name-wrapper">
              <div style={{ width: "50%" }}>
                <label className="label-name">Name</label>
              </div>
              <div style={{ width: "50%", paddingLeft: 20 }}>
                <label className="label-type">Type</label>
              </div>
            </div>

            <div className="pzz-new-ing-inputs-container">
              <div className="pzz-name-container">
                <Select
                  className="pzz-input-type"
                  options={optionsToppings}
                  value={optionsToppings.find(
                    (option) => option.value === values.toppingName
                  )}
                  name="toppingName"
                  placeholder="Ingrédiant"
                  onChange={(selected) => {
                    setFieldValue("toppingName", selected?.value);
                    setSelectedOptionTypeTopping({
                      label: selected?.label,
                      value: selected?.value,
                    });
                  }}
                />
              </div>
              <div className="pzz-type-container">
                <Select
                  className="pzz-input-type"
                  options={options}
                  name="type"
                  value={options.find(
                    (option) => option.value === valuesForm?.type
                  )}
                  placeholder="Type"
                  onChange={(selected) => setFieldValue("type", selected.value)}
                />
              </div>
            </div>

            <div className="dose-wrapper">
              <div style={{ width: "50%" }}>
                <label className="label-dose">Valeur de la dose</label>
              </div>
              <div style={{ width: "50%", paddingLeft: 20 }}>
                <label className="label-price">Prix</label>
              </div>
            </div>

            <div className="pzz-new-ing-inputs-dose-container">
              <div className="pzz-dose-container-s">
                <Field
                  type="number"
                  name="portion"
                  className="pzz-input-dose"
                  placeholder="500"
                />
                <Select
                  className="pzz-input-unity"
                  options={optionsDose}
                  placeholder="gramme"
                  value={optionsDose.find(
                    (option) => option.value === values.unit
                  )}
                  onChange={(selected) => setFieldValue("unit", selected.value)}
                />
              </div>
              <div
                style={{
                  height: "90%",
                  width: "40%",
                  backgroundColor: "#fff",
                  padding: 10,
                  borderRadius: 4,
                }}
              >
                <Field
                  type="number"
                  name="price"
                  className="price-input"
                  placeholder="500"
                />
              </div>
            </div>
          </div>

          <div className="pzz-footer-add-ing">
            <div className="pzz-stepper-content">
              <div className="pzz-active-step" />
              <div className="pzz-inactive-step" />
            </div>
            <div className="next-btn-wrapper">
              <div className="pzz-next-btn-content-add-ing">
                <button type="submit" className="pzz-btn-next">
                  Suivant
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default NewIngredientModal;
