const ordersStatusDescription = {
  notAssigned: "Commande placée",
  canceled: "Commande annulée par le client",
  assigned: "Commande acceptée par le livreur",
  confirmed: "Commande confirmée par le magasin",
  rejected: "Commande rejectée par le magasin",
  ready: "Commande prête",
  picked: "Commande ramassée par le coureur",
  delivered: "Commande livrée",
  notdelivered: "Commande non livrée (pas encore retournée au magasin)",
  returned: "Commande non livrée",
  taken: "Commande prise par le client",
};

export default ordersStatusDescription;
