import "./index.scss";

import React, { useState } from "react";

import moment from "moment";

import "moment/locale/fr";

import { ReactComponent as RunnersAvailableIcon } from "../../../assets/icons/runner-availabe.svg";

import Lottie from "react-lottie";

import animationData from "../../../assets/lottie-animations/loading.json";
import { useSelector } from "react-redux";

function RightHeader() {
  const [currentDate, setCurrentDate] = useState(
    moment().locale("fr").format("D MMMM YYYY - HH:mm")
  );

  const [currentDay, setCurrentDay] = useState(
    moment().locale("fr").format("dddd, ")
  );

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(moment().locale("fr").format("D MMMM YYYY - HH:mm"));
      setCurrentDay(moment().locale("fr").format("dddd, "));
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const activeRunners = useSelector(
    (state: Storage) => state?.entities?.omsActiveRunners?.activeRunner
  );

  const loadingRunnerList = useSelector(
    (state: Storage) => state?.entities?.omsActiveRunners?.loadingRunnerData
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="right-header-container">
      <div className="date-container">
        <>
          <strong>
            {currentDay}
            {currentDate}
          </strong>
        </>
      </div>
      <div className="meteo-container">
        {loadingRunnerList ? (
          <div className="loader-container-r">
            <Lottie options={defaultOptions} height={20} width={20} />
          </div>
        ) : (
          <>
            <div style={{ padding: "0px 6px" }}>
              <RunnersAvailableIcon width={25} height={25} />
            </div>
            <div style={{ padding: "0px 6px" }}>Courreurs disponible </div>
            <p>{activeRunners?.length}</p>
          </>
        )}
      </div>
    </div>
  );
}

export default RightHeader;
