import "./index.scss";
import RunnerItem from "../RunnerItem";
import RunnersTitle from "../RunnersTitle";
import { RunnerType } from "../../utils/Types/RunnerType";
import updateStatusVerify from "../../services/api/patch-verify-status";
import { toast } from "react-toastify";
import updateStatusRunner from "../../services/api/patch-block-deblock-runner";
import { useState } from "react";
import ModalDeletePermission from "../Modals/ModalDeletePermission";
import Modal from "react-modal";

interface props {
  listRunners: RunnerType[];
  loadMoreAction: Function;
  getInitialeListRunners: Function;
}
const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Change the background color and opacity here
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "25vh",
    width: "25%",
    minWidth: "350px",

    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(269deg, #F1FFFC 0%, #FFFCF7 100%), #FFF`,
  },
};
const ListRunners = ({
  listRunners,
  loadMoreAction,
  getInitialeListRunners,
}: props) => {
  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      console.log("bottom");
      loadMoreAction();
    }
  };

  const [action, setAction] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentRunner, setCurrentRunner] = useState<any>(null);

  const BlockRunner = () => {
    updateStatusRunner(currentRunner?.phoneNumber, "Blocked")
      .then((response) => {
        if (response.data.status === 500) {
          toast.error("Internal Server Error !");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);
          return;
        }
        if (response.data.success) {
          toast.success("Runner Blocked Successfuly !");
          getInitialeListRunners();
          closeModal();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const DeblockRunner = () => {
    updateStatusRunner(currentRunner?.phoneNumber, "Valid")
      .then((response) => {
        if (response.data.status === 500) {
          toast.error("Internal Server Error !");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);
          return;
        }
        if (response.data.success) {
          toast.success("Runner Deblocked Successfuly !");
          getInitialeListRunners();
          closeModal();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  function openModal(actionState, runner) {
    setCurrentRunner(runner);
    setAction(actionState);
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const AprouveRunner = () => {
    updateStatusVerify(currentRunner?.phoneNumber)
      .then((response) => {
        if (response.data.status === 500) {
          toast.error("Internal Server Error !");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);
          return;
        }
        if (response.data.success) {
          toast.success("Runner verified !");
          getInitialeListRunners();
          closeModal();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <div className="list-runners" onScroll={handleScroll}>
      <div className="list-runners-container" style={{ minWidth: 300 }}>
        <table className="table-style">
          <thead style={{ height: 60 }}>
            <tr>
              <th className="title-txt">Nom et Prénom</th>
              <th className="title-txt">Tel</th>
              <th className="title-txt">Email</th>
              <th className="title-txt">Etat</th>
              <th className="title-txt">Action</th>
            </tr>
          </thead>

          <tbody className="t-body" onScroll={handleScroll}>
            {listRunners?.length > 0 ? (
              listRunners
                ?.map((runner: any, index: any) => {
                  console.log("runner"  , runner?.Status);
                  
                  return (
                    <tr key={index} className="line-table-style">
                      <td className="td-right">
                        <p
                          className="td-right-text-style"
                          style={
                            runner?.Status?.toUpperCase() === "BLOCKED"
                              ? { backgroundColor: "#FFF3F3" }
                              : {}
                          }
                        >
                          {Boolean(runner?.FullName)
                            ? runner?.FullName
                            : "Not set yet"}
                        </p>
                      </td>
                      <td className="td-center">
                        <p
                          className="td-center-txt"
                          style={
                            runner?.Status?.toUpperCase() === "BLOCKED"
                              ? { backgroundColor: "#FFF3F3" }
                              : {}
                          }
                        >
                          {" "}
                          {Boolean(runner?.phoneNumber)
                            ? runner?.phoneNumber
                            : "Not set yet"}
                        </p>
                      </td>
                      <td className="td-center">
                        <p
                          className="td-center-txt"
                          style={
                            runner?.Status?.toUpperCase() === "BLOCKED"
                              ? { backgroundColor: "#FFF3F3" }
                              : {}
                          }
                        >
                          {Boolean(runner?.Email)
                            ? runner?.Email
                            : "Not set yet"}
                        </p>
                      </td>
                      <td className="td-left">
                        <p
                          className="td-left-txt"
                          style={
                            runner?.Status?.toUpperCase() === "BLOCKED"
                              ? { backgroundColor: "#FFF3F3" }
                              : {}
                          }
                        >
                          {runner?.Status.toUpperCase() === "VERIFIED" ? (
                            <p className="runner-item-field-4-txt-active">
                              active
                            </p>
                          ) : runner?.Status.toUpperCase() === "VALID" ? (
                            <div
                              style={{ cursor: "pointer" }}
                              className="runner-item-field-4-approuver-container"
                              onClick={() => openModal("aprouve", runner)}
                            >
                              <p className="runner-item-field-4-txt-approuver">
                                Approuver
                              </p>
                            </div>
                          ) : (
                            <p className="runner-item-field-4-txt-inactive">
                              inactive
                            </p>
                          )}
                        </p>
                      </td>
                      <td
                        className="td-right"
                       
                      >
                        <p
                          className="td-right-text-style"
                          style={
                            runner?.Status?.toUpperCase() === "BLOCKED"
                            ? { backgroundColor: "#FFF3F3" }
                            : {}
                          }
                        >
                          <span
                            style={{
                              height: "100%",
                              width: "100%",
                              backgroundColor:
                                runner?.Status?.toUpperCase() === "VALID"
                                  ? "#FFECC5"
                                  : "#CEF2EA",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 4,
                            }}
                          >
                            {runner?.Status.toUpperCase() !== "BLOCKED" ? (
                              <div
                                style={{ cursor: "pointer" }}
                                className="runner-item-block-btn-container"
                                onClick={() => openModal("block" , runner)}
                              >
                                <p className="bloquer-txt">Bloquer</p>
                              </div>
                            ) : (
                              <div
                                style={{ cursor: "pointer" }}
                                className="runner-item-deblock-btn-container"
                                onClick={() => openModal("deblock" , runner)}
                              >
                                <p className="debloquer-txt">Débloquer</p>
                              </div>
                            )}
                          </span>
                        </p>
                      </td>
                    </tr>
                  );
                })
                .reverse()
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <ModalDeletePermission
          runner={currentRunner}
          DeblockRunner={DeblockRunner}
          BlockRunner={BlockRunner}
          closeModal={closeModal}
          AprouveRunner={AprouveRunner}
          action={action}
        />
      </Modal>
    </div>
  );
};
export default ListRunners;
