import Select from "react-select";
import "./index.scss";
import { useEffect } from "react";
import { useSelector, useStore } from "react-redux";
import {
  PIZZA_SLICE_ADD_PIZZA_NAME,
  PIZZA_SLICE_ADD_TOPPINGS,
  PIZZA_SLICE_REMOVE_TOPPING,
} from "../../store/slices/reducers/pizzaSlice";
import { Topping } from "../../utils/Types/Topping";
import { Pizza } from "../../utils/Types/PizzaDataTypes";

interface props {
  closeModal: any;
  setStep: (args: string) => void;
  selectedOption: Topping;
  filteredOptions: any;
}

const PizzaCreateFirstStep = ({
  closeModal,
  setStep,
  selectedOption,
  filteredOptions,
}: props) => {
  const store = useStore();

  const pizza: Pizza = useSelector(
    (state: Storage) => state?.entities?.pizzaSlice?.pizza
  );

  const handleOptionSelectToStore = (option: Topping): void => {
    const topping = {
      value: option.value,
      label: option.label,
      toppingName: option.value,
      quantity: 1,
    };
    try {
      store.dispatch({
        type: PIZZA_SLICE_ADD_TOPPINGS,
        payload: topping,
      });
    } catch (error) {
      console.log("errror redux");
    }
  };

  useEffect(() => {}, []);
  const removeIngrediantFromStore = (option: Topping) => {
    const topping = {
      value: option.value,
      label: option.label,
      toppingName: option.value,
      quantity: 1,
    };
    try {
      store.dispatch({
        type: PIZZA_SLICE_REMOVE_TOPPING,
        payload: topping,
      });
    } catch (error) {
      console.log("errror redux");
    }
  };

  const onChangePizzaNameStore = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    e.preventDefault();
    const name = e.target.value;
    try {
      store.dispatch({
        type: PIZZA_SLICE_ADD_PIZZA_NAME,
        payload: { name: name },
      });
    } catch (error) {
      console.log("errror redux");
    }
  };

  return (
    <div className="hight-container-pcf">
      <div className="header-pcf">
        <p className="pzz-title">New Pizza</p>
        <div style={{ height: "100%" }}>
          <i
            onClick={closeModal}
            style={{
              fontSize: 25,
              color: "#000",
              textAlign: "end",
            }}
            className="bx bx-x"
          ></i>
        </div>
      </div>
      <div className="pzz-body">
        <div className="pzz-body-content-pcf">
          <div style={{ padding: 10 }}>
            <p className="label-name-txt">Name</p>
            <input
              className="input-name"
              color="#BDBDBD"
              placeholder="ex: PZZ_Tuna"
              value={pizza?.name}
              onChange={(e) => onChangePizzaNameStore(e)}
            ></input>
          </div>
          <div style={{ padding: 10 }}>
            <p className="label-name-txt">Ingrédiants</p>
            <div>
              <Select
                placeholder="ex: thon"
                options={filteredOptions}
                value={selectedOption}
                onChange={handleOptionSelectToStore}
              />
            </div>
          </div>
          <div className="pzz-ingrediant-content">
            {pizza?.toppings.length > 0 ? (
              pizza?.toppings?.map((opt: Topping, index: any) => (
                <div key={index} className="pzz-ingrediant-container-first">
                  <p style={{ fontSize: 12, fontWeight: 700, color: "#000" }}>
                    {opt?.value}
                  </p>
                  <i
                    onClick={() => removeIngrediantFromStore(opt)}
                    style={{ fontSize: 25, color: "#000" }}
                    className="bx bx-x"
                  ></i>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="resume-container">
          <p className="resume-title-txt">Résumé</p>
          <p className="pizza-name-txt">PIZZA {pizza?.name?.substring(4)}</p>
          <div className="resume-content">
            {pizza?.toppings.length > 0 ? (
              pizza?.toppings?.map((opt, index) => (
                <div key={index} className="pzz-ing-text-container">
                  <p style={{ fontSize: 12, fontWeight: 700, color: "#000" }}>
                    {opt.value}
                  </p>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="pzz-footer-f">
        <div className="steppers-container">
          <div className="pzz-stepper-content-f">
            <div className="pzz-active-step" />
            <div className="pzz-inactive-step" />
            <div className="pzz-inactive-step" />
          </div>
        </div>
        <div className="next-btn-container">
          <button
            disabled={Boolean(pizza?.name) ? false : true}
            style={{
              borderWidth: 0,
              backgroundColor: Boolean(pizza?.name) ? "#1ea182" : "#e2e2e2e2",
              color: "#fff",
              fontSize: 16,
              width: "50%",
              height: 50,
              fontWeight: "700",
              borderRadius: 4,
            }}
            onClick={() => setStep("second")}
          >
            Suivant
          </button>
        </div>
      </div>
    </div>
  );
};
export default PizzaCreateFirstStep;
