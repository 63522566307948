import { useEffect, useState } from "react";
import deleteTopping from "../../../services/api/delete-topping";
import getIngredientImages from "../../../services/api/get-topping-images";
import "./index.scss";
import { toast } from "react-toastify";
import Lottie from "react-lottie";

import Modal from "react-modal";
import ModalDeletePermission from "../../Modals/ModalDeletePermission";
import { Topping } from "../../../utils/Types/Topping";
import { defaultOptionsLoading } from "../../../assets/lottie-animations/animationOptions";
const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "25vh",
    width: "25%",
    minWidth : "350px",

    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(269deg, #F1FFFC 0%, #FFFCF7 100%), #FFF`,
  },
};
interface props {
  topping: Topping;
  doGetAllIgredients: () => void;
  openToUpdate: () => void;
}
interface ingredientImagesType {
  photo1: string;
  photo2: string;
  photo3: string;
  photo4: string;
  icon: string;
}
const IngredientCard = ({
  topping,
  doGetAllIgredients,
  openToUpdate,
}: props) => {
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loading , setLoading] = useState(false)
  const [ingredientImages, setIngredientImages] =
    useState<ingredientImagesType>();

  const doGetIgredientImages = (): void => {
    setLoadingImage(true);

    getIngredientImages(topping?.toppingName)
      .then((response) => {
        setLoadingImage(false);
        if (response?.data?.status === 500) {
          toast.error("server error");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success)
          setIngredientImages(response?.data?.content);
      })
      .catch((error) => {
        setLoadingImage(false);

        toast.error(error);
      });
  };
  useEffect(() => {
    doGetIgredientImages();
  }, []);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const doDeleteTopping = () => {
    setLoading(true)
    deleteTopping(topping?.id)
      .then((response) => {
        setLoading(false)

        if (response.data.status === 500) {
          toast.error("server error !");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);
          return;
        }
        if (response.data.success) {
          closeModal();
          toast.success("Topping deleted successfuly !");
          doGetAllIgredients();
        }
      })
      .catch((error) => {
        setLoading(false)

        toast.error(error);
      });
  };

  return (
    <>
      <div className="pzz-card-ing-container">
        <div className="pzz-ing-card-body">
          <div className="pzz-ing-body-header">
            {loadingImage ? (
              <div className="ing-card-wrapper">
                <Lottie
                  options={defaultOptionsLoading}
                  height={"90%"}
                  width={"40%"}
                />
              </div>
            ) : ingredientImages?.icon ? (
              <img
                src={`data:image/*;base64,${ingredientImages?.icon}`}
                alt="icon"
                className="img-stll"
              />
            ) : (
              <div className="icon-image-wrapper">
                <i className="bx bx-image" style={{ fontSize: 98 }}></i>
              </div>
            )}
            <div>
              <p
                className="topping-name-text-style"
                onClick={() => openToUpdate()}
              >
                {topping?.toppingName}
              </p>
              <p className="topping-type-text-style">{topping?.type}</p>
            </div>
          </div>

          <div className="pzz-trash-container" onClick={() => openModal()}>
            <i className="bx bx-trash" style={{ fontSize: 22 }}></i>
          </div>
        </div>
        <div className="pzz-ing-card-footer-container">
          <div className="pzz-ing-card-footer-content">
            <p className="pzz-ing-text-portion-style">Portion :</p>
            <p className="pzz-ing-text-portion-value-style">
              {topping?.portion}
            </p>
          </div>
          <div className="pzz-ing-price-content">
            <p className="pzz-ing-price-text-style">Prix :</p>
            <p className="pzz-ing-price-value-text-style">{topping?.price}</p>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <ModalDeletePermission
          topping={topping}
          doDeleteTopping={doDeleteTopping}
          closeModal={closeModal}
          loading={loading}
        />
      </Modal>
    </>
  );
};
export default IngredientCard;
