type topping = {
  id: number;
  quantity: number;
  toppingName: string;
  price: number;
  portion: string;
  type: string;
};

export type productFromOrderLine = {
  id: number;
  Price: number;
  quantity: number;
  doughPrice: number;
  name: string;
  size: string;
  toppings: topping[];
  type: string;
};

function getInvoiceHtml(
  orderLine: productFromOrderLine[],
  deliveryFees: number,
  totalCost: number,
  orderNumber: string,
  id: string
): string {
  return `
<html>
  <head>
    <meta charset = "UTF-8">  
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no" />
    <meta http-equiv = "X-UA-Compatible" content = "ie=edge">  
    <link rel ="stylesheet" href ="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.7/css/bootstrap.min.css" />
  </head>
  <body style="text-align: center;">
  <table class="body-wrap">
  <tbody>
    <tr>
      <td class="container" width="600">
        <div class="content">
          <table class="main" width="100%" cellpadding="0" cellspacing="0">
            <tbody>
              <tr>
                <td class="content-wrap aligncenter">
                  <table width="100%" cellpadding="0" cellspacing="0">
                    <tbody>
                    <img src ="https://i.ibb.co/SNtStZj/pizzaie-logo.png"  
                    class = "qr-code aligncenter" />  
                      <tr>
                        <td class="content-block aligncenter">
                          <h2>Invoice N° ${orderNumber}</h2>
                        </td>
                      </tr>
                      <tr>
                        <td class="content-block">
                          <table class="invoice">
                              <tr>
                                <td>
                                  <table
                                    class="invoice-items"
                                    cellpadding="0"
                                    cellspacing="0"
                                  >
                                    ${orderLine.map((item) => {
                                      return `<tr>
                                        <td> 
                                         <p>
                                         ${
                                           item.quantity ? item.quantity : "1"
                                         }x Pizza ${item.name.split("_")[1]} ${
                                        item.size
                                      }
                                      </p>
                                      <p>
                                      ${item?.toppings?.map((topping) => {
                                        return ` ${topping.quantity}x${topping?.toppingName} `;
                                      })}
                                      </p>
                                      </td>
                                        <td class="alignright">     
                                        ${(
                                          item.Price * (item.quantity ?? 1)
                                        ).toFixed(3)} DT
                                        </td>
                                      </tr>`;
                                    })}
                                    <tr class="total">
                                    <td class="alignright" width="80%">
                                      Delivery
                                    </td>
                                    <td class="alignright">${deliveryFees.toFixed(
                                      3
                                    )} DT</td>
                                  </tr>
                                      <tr class="total">
                                        <td class="alignright" width="80%">
                                          Total
                                        </td>
                                        <td class="alignright">${totalCost.toFixed(
                                          3
                                        )} DT</td>
                                      </tr>
                                  </table>
                                </td>
                              </tr>
                              <td class="content-block aligncenter">
                              <img src ="https://chart.googleapis.com/chart?cht=qr&chl=${id}&chs=160x160&chld=L|0"  
                              class = "qr-code aligncenter img-thumbnail img-responsive" />  
                            </td>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td class="content-block aligncenter">
                          Pizzaie, 54 Rue Yasser Aarefet , Sahloul
                        </td>
                      </tr>
                      <tr>
                      <td class="content-block aligncenter">
                    Thank you for your order
                    </td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </td>
    </tr>
  </tbody>
</table>
  </body>
  <style>
* {
margin: 0;
padding: 0;
font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
box-sizing: border-box;
font-size: 14px;
}
img {
max-width: 100%;
}
body {
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: none;
width: 100% !important;
height: 100%;
line-height: 1.6;
}
table td {
vertical-align: top;
}
body {
background-color: #f6f6f6;
}
.qr-code {  
    max-width: 200px;  
    margin: 10px;  
}  
.body-wrap {
background-color: #f6f6f6;
width: 100%;
}
.container {
display: block !important;
max-width: 600px !important;
margin: 0 auto !important;
/* makes it centered */
clear: both !important;
}
.content {
max-width: 600px;
margin: 0 auto;
display: block;
padding: 20px;
}
.main {
background: #fff;
border: 1px solid #e9e9e9;
border-radius: 3px;
}
.content-wrap {
padding: 20px;
}
.content-block {
padding: 0 0 20px;
}
.header {
width: 100%;
margin-bottom: 20px;
}
.footer {
width: 100%;
clear: both;
color: #999;
padding: 20px;
}
.footer a {
color: #999;
}
.footer p,
.footer a,
.footer unsubscribe,
.footer td {
font-size: 12px;
}
h1,
h2,
h3 {
font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
color: #000;
margin: 40px 0 0;
line-height: 1.2;
font-weight: 400;
}
h1 {
font-size: 32px;
font-weight: 500;
}
h2 {
font-size: 24px;
}
h3 {
font-size: 18px;
}
h4 {
font-size: 14px;
font-weight: 600;
}

ul,
ol {
margin-bottom: 10px;
font-weight: normal;
}
ul li,
ol li {
margin-left: 5px;
list-style-position: inside;
}
a {
color: #1ab394;
text-decoration: underline;
}
.btn-primary {
text-decoration: none;
color: #fff;
background-color: #1ab394;
border: solid #1ab394;
border-width: 5px 10px;
line-height: 2;
font-weight: bold;
text-align: center;
cursor: pointer;
display: inline-block;
border-radius: 5px;
text-transform: capitalize;
}
.last {
margin-bottom: 0;
}
.first {
margin-top: 0;
}
.aligncenter {
text-align: center;
}
.alignright {
text-align: right;
}
.alignleft {
text-align: left;
}
.clear {
clear: both;
}
.invoice {
margin: 10px auto;
text-align: left;
width: 80%;
}
.invoice td {
padding: 5px 0;
}
.invoice .invoice-items {
width: 100%;
}
.invoice .invoice-items td {
border-top: #eee 1px solid;
}
.invoice .invoice-items .total td {
border-top: 2px solid #333;
border-bottom: 2px solid #333;
font-weight: 700;
}
</style>
</html>
`;
}

export default getInvoiceHtml;

// <img
// src="../../../assets/logo.png"
// style="
//   width: 100px;
//   height: 100px;
//   object-fit: contain;
// "
// />
