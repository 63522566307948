// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-runners-container {
  min-width: 300px;
}

.list-runners-titles-container {
  min-width: 300px;
}

.list-runners {
  overflow-x: scroll;
  overflow-y: scroll;
  max-height: 600px;
}`, "",{"version":3,"sources":["webpack://./src/components/ListRunners/index.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AACA;EACE,kBAAA;EACA,kBAAA;EACA,iBAAA;AAEF","sourcesContent":[".list-runners-container {\n  min-width: 300px;\n}\n\n.list-runners-titles-container {\n  min-width: 300px;\n}\n.list-runners {\n  overflow-x: scroll;\n  overflow-y: scroll;\n  max-height: 600px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
