import ListIngredient from "../../components/ListIngrediants";
import ScreenTitle from "../../components/page-title";
import "./index.scss";
const Ingrediants = () => {
  return (
    <div>
      <ScreenTitle title="Ingrédients" />
      <ListIngredient />
    </div>
  );
};
export default Ingrediants;
