export const getImageByName = (productName: any): any => {
  switch (productName) {
    case "PZZ_Tuna":
      return "./../../assets/PizzaTuna.png";
    case "PZZ_Pepperoni":
      return "./../../assets/PizzaPepperoni.png";
    case "PZZ_Mexican":
      return "./../../assets/PizzaMexican.png";
    case "PZZ_Margherita":
      return "./../../assets/PizzaMargherita.png";

    default:
      return "./../../assets/PizzaTuna.png";
  }
  // return "./../../assets/PizzaTuna.png";
};

export const getPizzaName = (productName: any): any => {
  switch (productName) {
    case "PZZ_Tuna":
      return "Pizza Tuna";
    case "PZZ_Pepperoni":
      return "Pizza Pepperoni";
    case "PZZ_Mexican":
      return "Pizza Mexican";
    case "PZZ_Margherita":
      return "Pizza Margherita";
    case "PZZ_Vegetarian":
      return "Pizza Vegetarian";
    case "PZZ_4Season":
      return "Pizza 4 Season";
    case "PZZ_Start":
      return "Pizza Start";
    case "PZZ_Seafood":
      return "Pizza Seafood";

    default:
      return "Pizza";
  }
  // return "./../../assets/PizzaTuna.png";
};


// export const unitABV = (unit) => {
//   switch (productName) {
//     case "PZZ_Tuna":
//       return "Pizza Tuna";
//     case "PZZ_Pepperoni":
//       return "Pizza Pepperoni";
//     case "PZZ_Mexican":
//       return "Pizza Mexican";
//     case "PZZ_Margherita":
//       return "Pizza Margherita";
//     case "PZZ_Vegetarian":
//       return "Pizza Vegetarian";
//     case "PZZ_4Season":
//       return "Pizza 4 Season";
//     case "PZZ_Start":
//       return "Pizza Start";
//     case "PZZ_Seafood":
//       return "Pizza Seafood";

//     default:
//       return "Pizza";
//   }

// }

export const sidebarNavItems = [
  {
    display: "Accueil",
    icon: <i className="bx bx-home"></i>,
    to: "/accueil",
    section: "accueil",
  },
  {
    display: "Commandes",
    icon: <i className="bx bx-mobile-alt"></i>,
    to: "/commandes",
    section: "commandes",
  },
  {
    display: "Stores",
    icon: <i className="bx bx-store"></i>,
    to: "/stores",
    section: "stores",
  },

  {
    display: "Pizzas",
    // icon: <i className="bx bxs-pizza"></i>,
    icon: <i className="bx bx-dish"></i>,
    to: "/pizza",
    section: "pizza",
  },
  {
    display: "Ingrédients",
    icon: (
      <img
        src={require("./../assets/ingrediantIcon.png")}
        alt="gifanimation"
        style={{ height: "100%", width: "100%" }}
      />
    ),
    to: "/ingrediants",
    section: "ingrediants",
  },
  {
    display: "Extras",
    icon: (
      <img
        src={require("./../assets/extra.png")}
        alt="gifanimation"
        style={{ height: "100%", width: "100%" }}
      />
    ),
    to: "/extras",
    section: "extras",
  },
  {
    display: "Users",
    icon: <i className="bx bx-group"></i>,
    to: "/users",
    section: "users",
  },

  {
    display: "Ressources Humaines",
    icon: <i className="bx bx-group"></i>,
    to: "/rh",
    section: "rh",
  },
];