import QRCode from "qrcode.react";

function QRCodePrintComponent({ order }: any) {
  return (
    <QRCode
      id="qr-code-print"
      level="L"
      size={300}
      value={order.id}
      autoFocus={true}
    />
  );
}

export default QRCodePrintComponent;
