import "./index.scss";
import { useEffect, useState } from "react";
import { useSelector, useStore } from "react-redux";
import addMultiplePizza from "../../services/api/post-multiple-pizza";
import addPizzaPhoto from "../../services/api/post-pizza-photo";
import { toast } from "react-toastify";
import { PIZZA_SLICE_RESET } from "../../store/slices/reducers/pizzaSlice";
import { Topping } from "../../utils/Types/Topping";
import { Pizza } from "../../utils/Types/PizzaDataTypes";
import { useNavigate, useNavigation } from "react-router-dom";
import Lottie from "react-lottie";
import { defaultOptionsLoading } from "../../assets/lottie-animations/animationOptions";

interface props {
  closeModal: any;
  setStep: (args: string) => void;
  getAllPizzas?: any;
}
const PizzaCreateThirdStep = ({ closeModal, setStep, getAllPizzas }: props) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<any>();
  const [success, setSuccess] = useState<boolean>(false);
  const store = useStore();
  const navigate = useNavigate();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const pizza: Pizza = useSelector(
    (state: Storage) => state.entities.pizzaSlice.pizza
  );
  const pizzaSmall: Pizza = useSelector(
    (state: Storage) => state.entities.pizzaSlice.pizzaSmall
  );
  const pizzaMedium: Pizza = useSelector(
    (state: Storage) => state.entities.pizzaSlice.pizzaMedium
  );
  const pizzaLarge: Pizza = useSelector(
    (state: Storage) => state.entities.pizzaSlice.pizzaLarge
  );

  const savePhoto = (): void => {
    const bodyData = { file: selectedImage };
    addPizzaPhoto(bodyData, pizza?.name)
      .then((response) => {
        setLoading(false);
        if (response.data.status === 500) {
          toast.error(`Erreur du serveur}`);
          console.log("internal server error");
          return;
        }
        if (!response.data.success) {
          toast.error(
            `Erreur dans l'enregistrement de pizza ${response.data.message}`
          );

          return;
        }
        if (response.data.success) {
          toast.success(`pizza added successfuly`);
          try {
            store.dispatch({
              type: PIZZA_SLICE_RESET,
              payload: {},
            });
          } catch (error) {
            console.log("errror redux");
          }
          getAllPizzas();

          fireSuccess();
        }
      })
      .catch((error) => {
        toast.error(`Erreur dans l'enregistrement de l'image ${error}`);

        setLoading(false);

        console.log(error);
      });
  };

  const fireSuccess = (): void => {
    setTimeout(() => {
      setSuccess(true);
    }, 5000);
    closeModal();
    navigate("/pizza");
  };

  const doSave = (): void => {
    setLoading(true);
    const pizzaSmallNew = { ...pizzaSmall };
    pizzaSmallNew.doughPrice = Number(pizzaSmallNew.doughPrice);
    const pizzaMediumNew = { ...pizzaMedium };
    pizzaMediumNew.doughPrice = Number(pizzaMediumNew.doughPrice);
    const pizzaLargeNew = { ...pizzaLarge };
    pizzaLargeNew.doughPrice = Number(pizzaLargeNew.doughPrice);
    const bodyData = {
      smallPizza: pizzaSmallNew,
      mediumPizza: pizzaMediumNew,
      largePizza: pizzaLargeNew,
    };
    addMultiplePizza(bodyData)
      .then((response) => {
        if (response.data.status === 500) {
          setLoading(false);
          toast.error(`Erreur du serveur}`);
          return;
        }
        if (!response.data.success) {
          setLoading(false);
          toast.error(
            `Erreur dans l'enregistrement de pizza ${response.data.message}`
          );

          return;
        }
        if (response.data.success) {
          savePhoto();
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(`Erreur dans l'enregistrement de pizza ${error}`);

        console.log(error);
      });
  };

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  return (
    <>
      <>
        {loading ? (
          <div className="loading-wrapper-pct">
            <Lottie options={defaultOptionsLoading} height={150} width={150} />
          </div>
        ) : success ? (
          <div className="success-wrapper">
            <i className="bx bx-check" style={{ fontSize: 36 }}></i>
            <h2>success</h2>
          </div>
        ) : (
          <div className="pct-hight-container">
            <div className="pct-container">
              <p className="pzz-title">New Pizza</p>
              <i
                onClick={closeModal}
                style={{
                  fontSize: 25,
                  color: "#000",
                  textAlign: "end",
                  alignSelf: "flex-end",
                  justifyContent: "flex-start",
                }}
                className="bx bx-x"
              ></i>
            </div>
            <div className="pzz-body-t">
              <div className="pzz-right-side">
                {selectedImage ? (
                  <div className="selected-imge-container">
                    <div className="upload-file-content-img-selected">
                      <img
                        src={imageUrl}
                        alt="Selected"
                        className="selcted-img-stl"
                      />
                    </div>
                    <div style={{ height: 80, width: "15%", marginLeft: 10 }}>
                      <div className="custom-file-upload">
                        <input
                          type="file"
                          id="file-upload"
                          accept=".jpg,.gif,.png"
                          multiple
                          onChange={handleFileChange}
                        />
                        <label
                          style={{
                            borderRadius: 6,
                            backgroundColor: "#FFF",
                            boxShadow: `0px 4px 16px 0px rgba(0, 0, 0, 0.05)`,
                            color: "#159B7B",
                            borderWidth: 0,
                          }}
                          htmlFor="file-upload"
                        >
                          <i
                            style={{
                              fontSize: 25,
                              color: "#000",
                              marginRight: 10,
                            }}
                            className="bx bx-repost"
                          ></i>
                        </label>
                      </div>

                      <div
                        className="trash-container"
                        onClick={() => setSelectedImage(null)}
                      >
                        <i
                          style={{
                            fontSize: 25,
                            color: "#000",
                            marginRight: 10,
                          }}
                          className="bx bx-trash"
                        ></i>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="upload-file-content">
                    {selectedImage ? (
                      <div style={{ width: "100%", height: "100%" }}>
                        <img
                          src={imageUrl}
                          alt="Selected"
                          className="selected-img-stl-pct"
                        />
                      </div>
                    ) : (
                      <div className="image-choose-wrapper">
                        <i
                          onClick={closeModal}
                          style={{
                            fontSize: 50,
                            color: "#787878",
                            marginBottom: 10,
                            alignSelf: "center",
                            display: "flex",
                            textAlign: "center",
                          }}
                          className="bx bx-image"
                        ></i>
                        <p
                          style={{
                            color: "#787878",
                            fontSize: 12,
                            fontWeight: 400,
                            textAlign: "center",
                            marginBottom: 10,
                          }}
                        >
                          jpg.png.pdf.doc
                        </p>

                        <div className="custom-file-upload">
                          <input
                            type="file"
                            id="file-upload"
                            accept=".jpg,.gif,.png"
                            multiple
                            onChange={handleFileChange}
                          />
                          <label
                            style={{
                              borderRadius: 6,
                              backgroundColor: "#FFF",
                              boxShadow: `0px 4px 16px 0px rgba(0, 0, 0, 0.05)`,
                              color: "#159B7B",
                              borderWidth: 0,
                            }}
                            htmlFor="file-upload"
                          >
                            Upload
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="pzz-left-side">
                <p className="resume-title-txt-pct">Résumé</p>
                <p className="pizza-name-txt-pct">
                  PIZZA {pizza?.name?.substring(4)}
                </p>
                <div className="toppings-container-pct">
                  {pizza?.toppings.length > 0 ? (
                    pizza?.toppings?.map((topping: Topping, index: any) => (
                      <p
                        key={index}
                        style={{
                          fontSize: 12,
                          fontWeight: 700,
                          color: "#000",
                        }}
                      >
                        {topping?.value}
                      </p>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="pzz-footer-t">
              <div className="steppers-container">
                <div className="pzz-stepper-content-t">
                  <div className="pzz-inactive-step" />
                  <div className="pzz-inactive-step" />
                  <div className="pzz-active-step" />
                </div>
              </div>
              <div className="btns-container-pcs">
                <button
                  className="back-btn-pct"
                  onClick={() => setStep("second")}
                >
                  Precedent
                </button>

                <button
                  disabled={!selectedImage || loading}
                  className={
                    selectedImage ? "next-btn-pct" : "next-btn-pct-inactive"
                  }
                  onClick={() => doSave()}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};
export default PizzaCreateThirdStep;
