import { OrderStatusType } from "../../utils/Types/OrderStatusTypes";
import { postRequest } from "../../utils/axios";

type DataFromAxiosType = {
  data: {
    success: boolean;
    message: string;
    status: number;
    content: Record<string, any>;
  };
};

type body =
  | {
      [key in OrderStatusType]: string | null;
    }
  | {};

async function getDataByFilter(body: body): Promise<DataFromAxiosType> {
  try {
    const { data }: DataFromAxiosType = await postRequest(
      `/Orders-Oms/query`,
      body
    );

    if (!data.success) {
      throw new Error(data?.message);
    }

    return {
      data,
    };
  } catch (error: any) {
    const returnedData: DataFromAxiosType = {
      data: {
        success: false,
        message: error?.message ?? "",
        status: error?.response?.status ?? 500,
        content: [],
      },
    };
    return returnedData;
  }
}

export default getDataByFilter;
