import "./index.scss";
import { useEffect } from "react";
import { ExtraEnum } from "../../utils/Types/enum-unit";
import Lottie from "react-lottie";
import { defaultOptionsLoading } from "../../assets/lottie-animations/animationOptions";

interface value {
  name: string;
  volume: number;
  price: number;
  unity: string;
}
interface props {
  setStep: (args: string) => void;
  values: value;
  selectedImage: Blob | MediaSource;
  setSelectedImage: (args: object) => void;
  imageUrl: string;
  setImageUrl: (args: string) => void;
  doAddExtra: () => void;
  loadingAdd: any;
}

const AddExtraPhoto = ({
  setStep,
  values,
  selectedImage,
  setSelectedImage,
  imageUrl,
  setImageUrl,
  doAddExtra,
  loadingAdd,
}: props) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };
  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  return (
    <div className="pzz-add-extra-photo-container">
      <div className="pzz-add-extra-photo-header">
        <p style={{ color: "#000" }}>Nouvelle élément Extra</p>
      </div>
      <div className="pzz-add-extra-photo-body">
        <div className="body-left-side ">
          {selectedImage ? (
            <div className="selected-img-container">
              <div className="upload-file-content-img-selected">
                <img src={imageUrl} alt="Selected" className="img-styyl" />
              </div>
              <div className="label-repost-trash-container">
                <div className="custom-file-upload">
                  <input
                    type="file"
                    id="file-upload"
                    accept=".jpg,.gif,.png"
                    multiple
                    onChange={handleFileChange}
                  />
                  <label className="label-repost" htmlFor="file-upload">
                    <i
                      style={{
                        fontSize: 25,
                        color: "#000",
                        marginRight: 10,
                      }}
                      className="bx bx-repost"
                    ></i>
                  </label>
                </div>

                <div
                  className="trash-icon-container-style"
                  onClick={() => setSelectedImage(null)}
                >
                  <i
                    style={{
                      fontSize: 25,
                      color: "#000",
                    }}
                    className="bx bx-trash"
                  ></i>
                </div>
              </div>
            </div>
          ) : (
            <div className="upload-file-content">
              {selectedImage ? (
                <div className="selected-wrapeer">
                  <img src={imageUrl} alt="Selected" className="s-img-style" />
                </div>
              ) : (
                <div className="not-selected-wrapper">
                  <i
                    style={{
                      fontSize: 50,
                      color: "#787878",
                      marginBottom: 10,
                      alignSelf: "center",
                      display: "flex",
                      textAlign: "center",
                    }}
                    className="bx bx-image"
                  ></i>
                  <span className="txt-img-type-stl">jpg.png.pdf.doc</span>

                  <div className="custom-file-upload">
                    <input
                      type="file"
                      id="file-upload"
                      accept=".jpg,.gif,.png"
                      multiple
                      onChange={handleFileChange}
                    />
                    <label className="label-input-stlyy" htmlFor="file-upload">
                      Upload
                    </label>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div style={{ width: "50%" }}>
          <p className="title-txt-stl">Résumé</p>
          <p className="txt-stl">{values?.name}</p>
          <p className="txt-stl">
            {values?.volume} {ExtraEnum?.[values.unity]}
          </p>
          <p className="txt-stl">{values?.price}</p>
          <div className="empty-warpper"></div>
        </div>
      </div>
      <div className="add-extra-photo-footer">
        <button
          onClick={() => setStep("first")}
          className="add-extra-p-back-btn"
        >
          Precedent
        </button>
        {loadingAdd ? (
          <div className="add-extra-loading-wrapper">
            <Lottie options={defaultOptionsLoading} height={80} width={80} />
          </div>
        ) : (
          <button className="add-extra-p-save-btn" onClick={() => doAddExtra()}>
            Sauvegarder
          </button>
        )}
      </div>
    </div>
  );
};

export default AddExtraPhoto;
