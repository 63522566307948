const pizzaSlice = {
  initialState: {
    pizza: { toppings: [] },
    pizzaSmall: { toppings: [], size: "SMALL" , doughPrice :  "0"},
    pizzaMedium: { toppings: [], size: "MEDIUM" , doughPrice :  "0" },
    pizzaLarge: { toppings: [], size: "LARGE"  , doughPrice :  "0"},
  },
  reducers: {
    addTopping(state, action) {
      state.pizza.toppings.push(action.payload);
      state.pizzaSmall.toppings.push(action.payload);
      state.pizzaMedium.toppings.push(action.payload);
      state.pizzaLarge.toppings.push(action.payload);
    },
    addDoughPrice(state, action) {
      const { size, price } = action.payload;

      // Add dough price based on size
      switch (size) {
        case "SMALL":
          state.pizzaSmall.doughPrice = price;
          break;
        case "MEDIUM":
          state.pizzaMedium.doughPrice = price;
          break;
        case "LARGE":
          state.pizzaLarge.doughPrice = price;
          break;
        default:
          // Handle invalid size
          break;
      }
    },
    deleteDoughPrice(state, action) {
      const { size } = action.payload;

      // Delete dough price based on size
      switch (size) {
        case "SMALL":
          delete state.pizzaSmall.doughPrice;
          break;
        case "MEDIUM":
          delete state.pizzaMedium.doughPrice;
          break;
        case "LARGE":
          delete state.pizzaLarge.doughPrice;
          break;
        default:
          // Handle invalid size
          break;
      }
    },
    removeTopping(state, action) {
      state.pizza.toppings = state.pizza.toppings.filter(
        (topping) => topping?.toppingName !== action.payload.value
      );
      state.pizzaSmall.toppings = state.pizzaSmall.toppings.filter(
        (topping) => topping?.toppingName !== action.payload.value
      );
      state.pizzaMedium.toppings = state.pizzaMedium.toppings.filter(
        (topping) => topping?.toppingName !== action.payload.value
      );
      state.pizzaLarge.toppings = state.pizzaLarge.toppings.filter(
        (topping) => topping?.toppingName !== action.payload.value
      );
    },
    increaseToppingQuantity(state, action) {
      const { value, size } = action.payload;

      switch (size) {
        case "SMALL":
          state.pizzaSmall.toppings.forEach((topping) => {
            if (topping?.toppingName === value) {
              topping.quantity += 1;
            }
          });
          break;
        case "MEDIUM":
          state.pizzaMedium.toppings.forEach((topping) => {
            if (topping?.toppingName === value) {
              topping.quantity += 1;
            }
          });
          break;
        case "LARGE":
          state.pizzaLarge.toppings.forEach((topping) => {
            if (topping?.toppingName === value) {
              topping.quantity += 1;
            }
          });
          break;
        default:
          // Handle invalid size
          break;
      }
    },
    decreaseToppingQuantity(state, action) {
      const { value, size } = action.payload;

      const decreaseQuantity = (toppings) => {
        toppings.forEach((topping) => {
          if (topping?.toppingName === value) {
            if (topping.quantity > 1) {
              // Check if quantity is greater than 1
              topping.quantity -= 1;
            }
          }
        });
      };

      switch (size) {
        case "SMALL":
          decreaseQuantity(state.pizzaSmall.toppings);
          break;
        case "MEDIUM":
          decreaseQuantity(state.pizzaMedium.toppings);
          break;
        case "LARGE":
          decreaseQuantity(state.pizzaLarge.toppings);
          break;
        default:
          // Handle invalid size
          break;
      }
    },

    addPizzaName(state, action) {
      const { name } = action.payload;

      // Add dough price based on size
      state.pizza.name = name;

      state.pizzaSmall.name = name;

      state.pizzaMedium.name = name;

      state.pizzaLarge.name = name;
    },

    reset(state, action) {
      state.pizza = { toppings: [] };
      state.pizzaSmall = { toppings: [], size: "SMALL" };
      state.pizzaMedium = { toppings: [], size: "MEDIUM" };
      state.pizzaLarge = { toppings: [], size: "LARGE" };
    },
    setPizza(state, action) {
      const { pizzaSmall, pizzaMedium, pizzaLarge } = action.payload;

      state.pizza = { toppings: [] };
      state.pizzaSmall = pizzaSmall;
      state.pizzaMedium = pizzaMedium;
      state.pizzaLarge = pizzaLarge;
      state.pizza = pizzaMedium;
    },
  },
};

export default pizzaSlice;

export const PIZZA_SLICE_ADD_TOPPINGS = "pizzaSlice/addTopping";
export const PIZZA_SLICE_REMOVE_TOPPING = "pizzaSlice/removeTopping";
export const PIZZA_SLICE_ADD_DOUGHTPRICE = "pizzaSlice/addDoughPrice";
export const PIZZA_SLICE_ADD_PIZZA_NAME = "pizzaSlice/addPizzaName";
export const PIZZA_SLICE_RESET = "pizzaSlice/reset";
export const PIZZA_SLICE_SETPIZZA = "pizzaSlice/setPizza";

export const PIZZA_SLICE_INCREASE_QUANTITY =
  "pizzaSlice/increaseToppingQuantity";
export const PIZZA_SLICE_DECREASE_QUANTITY =
  "pizzaSlice/decreaseToppingQuantity";
