// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.store-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.store-container .list-store-lottie-container {
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.store-container .list-store-empty-lottie-container {
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 470px) {
  .store-container {
    flex-direction: column;
  }
}
@media (min-width: 471px) and (max-width: 1200px) {
  .store-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
@media (min-width: 1201px) {
  .store-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
.list-store-container {
  height: 100%;
  width: 100%;
}
.list-store-container .btn-add-store-container .list-store-header {
  min-width: 120px;
  width: 10%;
  height: 35px;
  border-width: 0px;
  background-color: #159b7b;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/ListStore/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,2BAAA;AACF;AAAE;EACE,WAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AAEJ;AAAE;EACE,WAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AAEJ;;AAEA;EACE;IACE,sBAAA;EACF;AACF;AAEA;EACE;IACE,mBAAA;IACA,eAAA;IACA,2BAAA;EAAF;AACF;AAGA;EACE;IACE,mBAAA;IACA,eAAA;IACA,2BAAA;EADF;AACF;AAGA;EACE,YAAA;EACA,WAAA;AADF;AAGI;EACE,gBAAA;EACA,UAAA;EACA,YAAA;EACA,iBAAA;EACA,yBAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,6BAAA;EACA,gBAAA;EACA,mBAAA;AADN","sourcesContent":[".store-container {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  .list-store-lottie-container {\n    height: 60%;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n  }\n  .list-store-empty-lottie-container {\n    height: 60%;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n  }\n}\n\n@media (max-width: 470px) {\n  .store-container {\n    flex-direction: column;\n  }\n}\n\n@media (min-width: 471px) and (max-width: 1200px) {\n  .store-container {\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: flex-start;\n  }\n}\n\n@media (min-width: 1201px) {\n  .store-container {\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: flex-start;\n  }\n}\n.list-store-container {\n  height: 100%;\n  width: 100%;\n  .btn-add-store-container {\n    .list-store-header {\n      min-width: 120px;\n      width: 10%;\n      height: 35px;\n      border-width: 0px;\n      background-color: #159b7b;\n      color: #fff;\n      border-radius: 4px;\n      padding: 5px;\n      display: flex;\n      justify-content: space-around;\n      margin-top: 10px;\n      margin-bottom: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
