// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pzz-store-update-container {
  height: 100vh;
  width: 100%;
  margin-top: 25px;
  padding: 20px;
}
.pzz-store-update-container .pzz-store-update-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.pzz-store-update-container .pzz-store-update-header .pzz-btn-edit {
  border-width: 0px;
  background-color: #159b7b;
  padding: 8px;
  color: #fff;
  border-radius: 4px;
  width: 74px;
}
.pzz-store-update-container .pzz-store-update-head {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
}
.pzz-store-update-container .pzz-store-update-map-container {
  height: 30vh;
  width: 40%;
  min-width: 40vw;
  margin-top: 25px;
}

.pzz-store-update-header-input-store-name {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border-width: 0px;
  font-weight: 700;
  font-size: 24px;
  color: #000;
  padding: 8px;
}

.pzz-store-update-header-update-btn {
  border-width: 0px;
  background-color: #159b7b;
  padding: 8px;
  color: #fff;
  border-radius: 4px;
}

.pzz-store-update-input-address {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border-width: 0px;
  font-weight: 700;
  font-size: 16px;
  color: #000;
  padding: 8px;
}`, "",{"version":3,"sources":["webpack://./src/screens/store-update/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAEJ;AADI;EACE,iBAAA;EACA,yBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;AAGN;AAAE;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;AAEJ;AAAE;EACE,YAAA;EACA,UAAA;EACA,eAAA;EACA,gBAAA;AAEJ;;AAEA;EACE,gDAAA;EACA,kBAAA;EACA,sBAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,iBAAA;EACA,yBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;AACF;;AACA;EACE,gDAAA;EACA,kBAAA;EACA,sBAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;AAEF","sourcesContent":[".pzz-store-update-container {\n  height: 100vh;\n  width: 100%;\n  margin-top: 25px;\n  padding: 20px;\n  .pzz-store-update-header {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    .pzz-btn-edit {\n      border-width: 0px;\n      background-color: #159b7b;\n      padding: 8px;\n      color: #fff;\n      border-radius: 4px;\n      width: 74px;\n    }\n  }\n  .pzz-store-update-head {\n    display: flex;\n    flex-direction: row;\n    padding-top: 20px;\n  }\n  .pzz-store-update-map-container {\n    height: 30vh;\n    width: 40%;\n    min-width: 40vw;\n    margin-top: 25px;\n  }\n}\n\n.pzz-store-update-header-input-store-name {\n  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);\n  border-radius: 6px;\n  background-color: #fff;\n  border-width: 0px;\n  font-weight: 700;\n  font-size: 24px;\n  color: #000;\n  padding: 8px;\n}\n\n.pzz-store-update-header-update-btn {\n  border-width: 0px;\n  background-color: #159b7b;\n  padding: 8px;\n  color: #fff;\n  border-radius: 4px;\n}\n.pzz-store-update-input-address {\n  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);\n  border-radius: 6px;\n  background-color: #fff;\n  border-width: 0px;\n  font-weight: 700;\n  font-size: 16px;\n  color: #000;\n  padding: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
