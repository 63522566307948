import "./index.scss";
import Select from "react-select";
const optionsDose = [
  { label: "gramme", value: "gram" },
  { label: "litre", value: "liter" },
  { label: "centilitre", value: "centiliter" },
  { label: "millilitre ", value: "milliliter" },
];

const NewExtra = ({ setStep, values, setValues }: any) => {
  const handleChangeName = (e) => {
    e.preventDefault();
    setValues({ ...values, name: e.target.value });
  };
  const handleChangeVolume = (e) => {
    e.preventDefault();
    setValues({ ...values, volume: e.target.value });
  };

  const handleChangePrice = (e) => {
    e.preventDefault();
    setValues({ ...values, price: e.target.value });
  };
  const handleSelectUnityChange = (selected) => {
    setValues({ ...values, unity: selected?.value });
  };

  return (
    <div className="add-extra-container">
      <div className="add-extra-header">
        <p style={{ color: "#000" }}>Nouvelle élément Extra</p>
      </div>
      <div className="add-extra-body">
        <div className="add-extra-label-name-container">
          <label className="add-extra-label-name-txt">Name</label>
        </div>
        <div className="pzz-input-price-add-extra">
          <input
            name="name"
            placeholder="Pizza pinnable"
            value={values.name}
            className="add-extra-input-name"
            onChange={(e) => handleChangeName(e)}
          />
        </div>
        <div className="add-extra-volume-price-container">
          <div className="add-extra-volume-container">
            <div className="add-extra-volume-label-container">
              <label className="add-extra-volume-label-txt">Volume</label>
            </div>
            <div className="input-volume">
              <input
                name="volume"
                placeholder="Pizza pinnable"
                value={values.volume}
                className="input-volume-input"
                onChange={(e) => handleChangeVolume(e)}
              />
              <Select
                className="pzz-input-unity"
                options={optionsDose}
                placeholder={values?.unity}
                value={values.unity}
                defaultInputValue="gramme"
                onChange={handleSelectUnityChange}
              />
            </div>
          </div>
          <div className="add-extra-price-container">
            <div className="add-extra-label-price-container">
              <label className="add-extra-label-price-txt">Prix</label>
            </div>
            <div className="pzz-input-price-add-extra-container">
              <input
                name="price"
                type="number"
                placeholder="500"
                value={values.price}
                className="add-extra-input-price-input"
                onChange={(e) => handleChangePrice(e)}
              />
            </div>
          </div>
        </div>
      </div>
      <div
       className="add-extra-footer"
      >
        <button
        className="add-extra-footer-btn-next"
          onClick={() => setStep("second")}
        >
          Suivant
        </button>
      </div>
    </div>
  );
};
export default NewExtra;
