import { toast } from "react-toastify";
import getIngredientImages from "../../../services/api/get-topping-images";
import "./index.scss";
import { useEffect, useLayoutEffect, useState } from "react";
import AnimationIconComponent from "../../AnimationIconComponent";
import { Topping } from "../../../utils/Types/Topping";
import Lottie from "react-lottie";
import { defaultOptionsLoading } from "../../../assets/lottie-animations/animationOptions";

const imageIcons = [
  { value: 1, name: "first" },
  { value: 2, name: "second" },
  { value: 3, name: "third" },
  { value: 4, name: "fourth" },
];
interface ingredientImagesType {
  photo1: string;
  photo2: string;
  photo3: string;
  photo4: string;
  icon: string;
}
interface initialImageStateType {
  selectedImage: Blob | MediaSource;
  imageUrl: string;
}

interface props {
  toUpdateIng: Topping;
  setStep: (args: string) => void;
  images: initialImageStateType[] | [];
  setImages: (args: Function) => void;
  SubmitTopping: () => void;
  loading: any;
  valuesForm : any;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useLayoutEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
const AddIngredientPhoto = ({
  toUpdateIng,
  setStep,
  images,
  setImages,
  SubmitTopping,
  loading,
  valuesForm
}: props) => {
  const { height, width } = useWindowDimensions();

  const [ingredientImages, setIngredientImages] =
    useState<ingredientImagesType>();
  const [loadingImages, setLoadingImages] = useState(false);
  const allImagesNotEmpty = images
    .slice(0, 5)
    .every((image) => image?.selectedImage);

  const handleFileChangeIcon = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ): Topping | null => {
    console.log(type);

    if (type === "icon") {
      const file = event.target.files[0];
      setImages((prevImages) => {
        const updatedImages = [...prevImages];
        updatedImages[0].selectedImage = file;
        updatedImages[0].imageUrl = URL.createObjectURL(file);

        return updatedImages;
      });
      return;
    }

    if (type === "first") {
      const file = event.target.files[0];
      setImages((prevImages) => {
        const updatedImages = [...prevImages];
        updatedImages[1].selectedImage = file;
        updatedImages[1].imageUrl = URL.createObjectURL(file);

        return updatedImages;
      });
      return;
    }
    if (type === "second") {
      const file = event.target.files[0];
      setImages((prevImages) => {
        const updatedImages = [...prevImages];
        updatedImages[2].selectedImage = file;
        updatedImages[2].imageUrl = URL.createObjectURL(file);

        return updatedImages;
      });
      return;
    }
    if (type === "third") {
      const file = event.target.files[0];
      setImages((prevImages) => {
        const updatedImages = [...prevImages];
        updatedImages[3].selectedImage = file;
        updatedImages[3].imageUrl = URL.createObjectURL(file);

        return updatedImages;
      });
      return;
    }
    if (type === "fourth") {
      const file = event.target.files[0];
      setImages((prevImages) => {
        const updatedImages = [...prevImages];
        updatedImages[4].selectedImage = file;
        updatedImages[4].imageUrl = URL.createObjectURL(file);
        return updatedImages;
      });
      return;
    }
  };

  const doGetIgredientImages = (topping) => {
    setLoadingImages(true);
    getIngredientImages(topping?.toppingName)
      .then((response) => {
        setLoadingImages(false);

        if (response?.data?.status === 500) {
          toast.error("server error");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success)
          setIngredientImages(response?.data?.content);
      })
      .catch((error) => {
        setLoadingImages(false);

        toast.error(error);
      });
  };

  useEffect(() => {
    if (Boolean(toUpdateIng)) {
      doGetIgredientImages(toUpdateIng);
    }
  }, []);

  return (
    <div className="h-container">
      <div>
        {Boolean(toUpdateIng) ? (
          <p style={{ color: "#000" }}>Modifier Ingrédient</p>
        ) : (
          <p style={{ color: "#000" }}>Nouvel Ingrédient</p>
        )}
      </div>
      <div className="add-photo-ing-body-container">
        <div className="add-photo-ing-body-content">
          <div className="add-photo-ing-icon-right-content">
            <div className="add-photo-ing-body-title-side">
              <p>Icon</p>
              <p className="svg-png-txt">png , svg</p>
            </div>
            <div className="add-photo-ing-body-body-side">
              <div style={{ display: "flex", flexDirection: "column" }}>
                {images[0].selectedImage ? (
                  <div className="wrapperr">
                    <div className="imgUpld">
                      {loadingImages ? (
                        <div className="loading-warpperr">
                          <Lottie
                            options={defaultOptionsLoading}
                            height={"90%"}
                            width={"40%"}
                          />
                        </div>
                      ) : (
                        <img
                          src={images[0].imageUrl}
                          alt="Selected"
                          className="img-stlr"
                        />
                      )}
                    </div>
                    <div className="input-h-container">
                      <div className="input-wrapper">
                        <input
                          type="file"
                          id="file-upload"
                          accept=".jpg,.gif,.png"
                          className="hidden-input"
                          multiple
                          onChange={(e) => handleFileChangeIcon(e, "icon")}
                        />
                        <label className="label-stlr" htmlFor="file-upload">
                          <i
                            style={{
                              fontSize: 25,
                              color: "#000",
                              alignSelf: "center",
                              textAlign: "center",
                            }}
                            className="bx bx-repost"
                          ></i>
                        </label>
                      </div>
                      <div
                        className="trash-wrapper"
                        onClick={() =>
                          setImages((prevImages) => {
                            const updatedImages = [...prevImages];
                            updatedImages[0].selectedImage = null;
                            updatedImages[0].imageUrl = null;
                            return updatedImages;
                          })
                        }
                      >
                        <i className="bx bx-trash"></i>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {Boolean(toUpdateIng) ? (
                      <div className="wrapperr">
                        <div className="imgUpld">
                          {loadingImages ? (
                            <div className="loading-warpperr">
                              <Lottie
                                options={defaultOptionsLoading}
                                height={"50%"}
                                width={"80%"}
                              />
                            </div>
                          ) : (
                            <img
                              src={`data:image/*;base64,${ingredientImages?.icon}`}
                              alt="ing"
                              className="img-stlr"
                            />
                          )}
                        </div>
                        <div className="input-h-container">
                          <div className="input-wrapper">
                            <label className="label-stlr" htmlFor="file-upload">
                              <i
                                style={{
                                  fontSize: 25,
                                  color: "#000",
                                  alignSelf: "center",
                                  textAlign: "center",
                                }}
                                className="bx bx-repost"
                              ></i>
                            </label>
                            <input
                              type="file"
                              id="file-upload"
                              accept=".jpg,.gif,.png"
                              className="hidden-input"
                              multiple
                              onChange={(e) => handleFileChangeIcon(e, "icon")}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="custom-file-upload-icon">
                        <input
                          type="file"
                          id="file-upload"
                          accept=".jpg,.gif,.png"
                          className="hidden-input"
                          multiple
                          onChange={(e) => handleFileChangeIcon(e, "icon")}
                        />
                        <label
                          style={{
                            borderRadius: 6,
                            backgroundColor: "#FFF",
                            boxShadow: `0px 4px 16px 0px rgba(0, 0, 0, 0.05)`,
                            color: "#159B7B",
                            borderWidth: 0,
                          }}
                          htmlFor="file-upload"
                        >
                          <img
                            src={require("./../../../assets/uploadImg.png")}
                            alt="imgupload"
                            style={{ height: "100%", width: "100%" }}
                          />
                        </label>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="add-photo-ing-icon-left-content">
            <div className="add-photo-ing-icon-title-container">
              <p className="animation-elements-title-txt">
                Elements d’animation
              </p>
              <div className="animation-img-info-container">
                <i className="bx bx-error-circle"></i>
                <p className="animation-info-txt">
                  Utilisés pour l’animation lors de l’ajout des toppings
                </p>
              </div>
              <p className="png-svg-title-info-txt">png , svg</p>
            </div>
            <div className="animations-imgs-wrapper">
              {imageIcons?.length > 0 ? (
                imageIcons.map((imginc, index) => (
                  <AnimationIconComponent
                    key={index}
                    images={images}
                    handleFileChangeIcon={handleFileChangeIcon}
                    setImages={setImages}
                    toUpdateIng={toUpdateIng}
                    ingredientImages={ingredientImages}
                    imginc={imginc}
                    loadingImages={loadingImages}
                  />
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="pzz-footer-ing-p">
        <div className="pzz-stepper-content">
          <div className="pzz-inactive-step" />
          <div className="pzz-active-step" />
        </div>
        <div className="button-h-wrapper">
          <div className="button-wrapper">
            <button
              className="button-back-stlr"
              onClick={() => setStep("first")}
            >
              {width > 1022 ? "Precedent" : "<"}
            </button>
          </div>
          {loading ? (
            <Lottie options={defaultOptionsLoading} height={80} width={80} />
          ) : (
            <div className="buttton-r-wrapper">
              <button
                disabled={!allImagesNotEmpty && !toUpdateIng}
                className={
                  allImagesNotEmpty || toUpdateIng
                    ? "btn-r-stlr"
                    : "btn-r-stlr-inactive"
                }
                onClick={() => SubmitTopping()}
              >
                {width > 1022 ? (
                  "Enregistrer"
                ) : (
                  <i className="bx bx-save" style={{ fontSize: 22 }}></i>
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default AddIngredientPhoto;
