import "./index.scss";
import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { toast } from "react-toastify";
interface positionMap {
  lat: number;
  lng: number;
}
interface position {
  latitude: number;
  longitude: number;
}
interface addressMap {
  addressName: string;
  position: position;
}

interface props {
  addressPosition: addressMap;
  setStep?: (args : string) => void;
  setAddressPosition: (args: addressMap) => void;
  edit?: boolean;
  closeModal?: () => void;
}

const AddAddressModal = ({
  setStep,
  setAddressPosition,
  addressPosition,
  edit,
  closeModal,
}: props) => {
  const [value, setValue] = useState(null);
  const center: positionMap = {
    lat: addressPosition?.position?.latitude,
    lng: addressPosition?.position?.longitude,
  };

  /*************************get the position from address ************************* */
  const getPosition = async (addressObject: any) => {
    if (addressObject?.label == null) {
      return;
    }
    try {
      const { label } = addressObject;
      const results = await geocodeByAddress(label);
      const latLng = await getLatLng(results[0]);

      setAddressPosition({
        addressName: label,
        position: { latitude: latLng?.lat, longitude: latLng?.lng },
      });
      return latLng;
    } catch (error) {
      console.error("Error", error);
    }
  };

  /**********************************************get the address from the position ******************* */
  const handleGeocode = async (latitude: number, longitude: number) => {

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDQdZ3NJ_ScT4WsJG00f1drRUksu062db4`
      );
      const data = await response.json();

      if (data.status === "OK") {
        const results = data.results;
        if (results.length > 0) {
          setValue({
            label: results?.[0]?.formatted_address,
            value: {
              description: results?.[0]?.formatted_address,
              place_id: results?.[0]?.formatted_address,
            },
          });
          setAddressPosition({
            addressName: results?.[0]?.formatted_address,
            position: { latitude: latitude, longitude: longitude },
          });
        } else {
          return;
        }
      } else {
        toast.error("Geo address api failed");
      }
    } catch (error) {
      toast.error("Error occurred while fetching data.");
      console.error("Error:", error);
    }
  };
  /***************************************************the drag pin action ****************** */

  const handleMarkerDragEnd = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    handleGeocode(lat, lng);
  };

  useEffect(() => {
    // getPosition(value);
    getPosition(value).then((response) => {
      console.log("response", response);

      handleGeocode(
        addressPosition?.position?.latitude,
        addressPosition?.position?.longitude
      );
    });
  }, []);
  useEffect(() => {
    getPosition(value);
  }, [value]);

  return (
    <div className="pzz-modal-add-address-container">
      {edit ? (
        <div className="pzz-modal-add-address-header-edit">
          <p className="pzz-text-select-position">Select position</p>
          <div
            className="pzz-go-back-btn"
            onClick={() => {
              edit ? closeModal() : setStep("first");
            }}
          >
            <i className="bx bx-x" style={{ fontSize: 28 }}></i>
          </div>
        </div>
      ) : (
        <div className="pzz-modal-add-address-header">
          <div
            className="pzz-go-back-btn"
            onClick={() => {
              edit ? closeModal() : setStep("first");
            }}
          >
            <i className="bx bx-chevron-left" style={{ fontSize: 28 }}></i>
          </div>
          <p className="pzz-text-select-position">Select position</p>
        </div>
      )}

      <div className="pzz-select-address-container">
        <div style={{ width: "80%" }}>
          <PlacesAutocomplete
            selectProps={{
              value,
              onChange: setValue,
            }}
            onLoadFailed={(error) =>
              console.error("Could not inject Google script", error)
            }
            apiOptions={{ language: "fr", region: "fr" }}
            ref={value}
          />
        </div>
        <div className="pzz-save-btn-container">
          <button className="pzz-btn-save" onClick={() => edit ? closeModal() : setStep("first")}>Save</button>
        </div>
      </div>
      <div style={{ height: "70%", width: "100%" }}>
        <GoogleMap
          clickableIcons={false}
          zoom={15}
          center={center}
          mapContainerClassName="map-container"
        >
          <Marker
            position={center}
            visible={true}
            zIndex={1000}
            cursor="pointer"
            draggable={true}
            onDragEnd={handleMarkerDragEnd}
          />
        </GoogleMap>
      </div>
    </div>
  );
};

export default AddAddressModal;
