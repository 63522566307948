import "./index.scss";

import ScreenTitle from "../../components/page-title";
import ListStore from "../../components/ListStore";

function StoreScreen() {
  return (
    <>
      <ScreenTitle title="Stores" />
      <ListStore/>
    </>
  );
}

export default StoreScreen;
