import { OrderStatusType } from "../../../../utils/Types/OrderStatusTypes";
import { OrderTypeTypes } from "../../../../utils/Types/orders-type-types";

type getAllStatusOutput = {
  [key in OrderStatusType]?: string;
};

function getAllStatusByOrderType(orderType: string): getAllStatusOutput {
  if (orderType?.toUpperCase() === OrderTypeTypes.delivery.toUpperCase()) {
    return {
      notAssigned: "Placée",
      canceled: "Annulée",
      assigned: "Acceptée",
      confirmed: "Confirmée",
      rejected: "Rejectée",
      ready: "Prête",
      picked: "Ramassée",
      delivered: "Livrée",
      notdelivered: "Non livré",
      returned: "Retournée",
    };
  }
  if (orderType?.toUpperCase() === OrderTypeTypes.pickup.toUpperCase()) {
    return {
      notAssigned: "Placée",
      canceled: "Annulée",
      confirmed: "Confirmée",
      rejected: "Rejectée",
      ready: "Prête",
      returned: "Retournée",
      taken: "Prise",
    };
  }

  return {
    notAssigned: "Placée",
    canceled: "Annulée",
    assigned: "Acceptée",
    confirmed: "Confirmée",
    rejected: "Rejectée",
    ready: "Prête",
    picked: "Ramassée",
    delivered: "Livrée",
    notdelivered: "Non livré",
    returned: "Retournée",
  };
}

export default getAllStatusByOrderType;
