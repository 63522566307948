import { OrderDataTypes } from "../../../utils/Types/OrdersDataTypes";

const mockOrder: OrderDataTypes = {
  // id: " ",
  Client: {
    address: { latitude: 211, longtitude: 0, name: " " },
    FullName: " ",
    phoneNumber: 0,
  },
  CreationDate: " ",
  deliveredBy: "",
  deliveryFees: 0,
  estimatedDeliverytime: "",
  isPaid: false,
  // orderLine: [],
  orderNumber: " ",
  OrderType: "",
  Runner: {},
  Status: "assigned",
  Store: {
    address: { latitude: 0, longtitude: 0, name: " " },
    name: "",
    phoneNumber: "",
    publicKey: "",
  },
  totalCost: 0,
};

export default mockOrder;
