import { useNavigate, useParams } from "react-router-dom";
import ScreenTitle from "../../components/page-title";
import getOneStore from "../../services/api/get-one-store";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { GoogleMap, Marker } from "@react-google-maps/api";

import "./index.scss";
import getCurrentOrdersByStore from "../../services/api/get-current-orders-by-store";
import ListOrdersCard from "../../components/ListOrdersCard";
import { OrderDataTypes, OrderTypes } from "../../utils/Types/OrdersDataTypes";
const StoreDetail = () => {
  const [store, setStore] = useState<any>();
  const [listCurrentOrders, setListCurrentOrders] = useState<OrderTypes[]>([]);
  const [center, setCenter] = useState({ lat: 10.55, lng: 30.55 });
  const navigate = useNavigate();
  const { id } = useParams();
  const doGetOneStore = () => {
    getOneStore(id)
      .then((response) => {
        if (response.data.status === 500) {
          console.log("server error");
          toast.error("server error");
          return;
        }
        if (!response.data.success) {
          toast.error("error : " + response.data.message);
          return;
        }
        if (response.data.success) {
          setStore(response.data.content.pizzaStore);
        }
      })
      .catch((error) => {
        toast.error(error);
        console.log(error);
      });
  };

  const doGetCurrentOrders = () => {
    getCurrentOrdersByStore(id).then((response) => {
      if (response?.data.status === 500) {
        toast.error("server error");
        return;
      }
      if (!response?.data?.success) {
        toast.error(response.data.message);
        return;
      }
      if (response.data.success) {
        console.log("orders", response?.data?.content?.orders);

        setListCurrentOrders(response?.data?.content?.orders);
      }
    });
  };
  useEffect(() => {
    doGetOneStore();
    doGetCurrentOrders();
  }, []);
  useEffect(() => {
    const latitude = parseFloat(store?.adresses?.latitude);
    const longitude = parseFloat(store?.adresses?.longtitude);

    if (!isNaN(latitude) && !isNaN(longitude)) {
      setCenter({
        lat: +latitude,
        lng: +longitude,
      });
    }
  }, [store?.adresses?.latitude, store?.adresses?.longtitude]);
  return (
    <div>
      <ScreenTitle title="Détails" />
      <div className="pzz-store-detail-container">
        <div className="pzz-store-detail-header">
          <h2>{store?.name}</h2>
          <button
            className="pzz-btn-edit"
            onClick={() => navigate(`/stores/update/${id}`)}
          >
            Edit
          </button>
        </div>
        <div className="pzz-store-detail-head">
          <div>
            <p style={{ marginTop: 15 }}>Adresse</p>
            <p style={{ marginTop: 15, color: "#000" }}>
              {store?.adresses?.name}
            </p>
          </div>
          <div style={{ marginLeft: 35 }}>
            <p style={{ marginTop: 15 }}>Téléphone</p>
            <p style={{ marginTop: 15, color: "#000" }}>{store?.phoneNumber}</p>
          </div>
        </div>
        <div className="pzz-store-detail-map-container">
          <GoogleMap
            clickableIcons={false}
            zoom={15}
            center={center}
            mapContainerClassName="map-container"
          >
            <Marker
              position={center}
              visible={true}
              zIndex={1000}
              cursor="pointer"
            />
          </GoogleMap>
        </div>
        <ListOrdersCard listCurrentOrders={listCurrentOrders} />
      </div>
    </div>
  );
};
export default StoreDetail;
