// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-layout {
  display: flex;
  height: 10px;
  background-color: rgb(21, 155, 123);
  width: "100%";
}`, "",{"version":3,"sources":["webpack://./src/layout/app-layout/header-layout/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,mCAAA;EACA,aAAA;AACF","sourcesContent":[".header-layout {\n  display: flex;\n  height: 10px;\n  background-color: rgba(21, 155, 123, 1);\n  width: \"100%\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
