import "./index.scss";
import React from "react";
import { Outlet } from "react-router-dom";
import HeaderLayout from "./header-layout";
import Sidebar from "../../components/sidebar";
import RightHeader from "./right-header-layout";

const AppLayout = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  

  return (
    <>
      <div className="app-layout-container">
        <HeaderLayout />
        <div className="content-layout">
          <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? (
              <i style={{ fontSize: 32 }} className="bx bx-x" color="#000"></i>
            ) : (
              <i
                style={{ fontSize: 32 }}
                className="bx bx-menu"
                color="#000"
              ></i>
            )}
          </button>
          <div
            className={`navbar-menu ${isMobileMenuOpen ? "mobile-menu" : ""}`}
          >
            <Sidebar isMobileMenuOpen={isMobileMenuOpen} toggleMobileMenu={toggleMobileMenu} />
          </div>

          <div className="center-layout">
            <RightHeader />
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default AppLayout;
