import { toast } from "react-toastify";
import { UserType } from "../../utils/Types/UserType";
import ListUsersTitle from "../ListUsersTitle";
import UserItem from "../UserItem";
import "./index.scss";
import updateClientStatus from "../../services/api/patch-status";
import { useState } from "react";
import ModalChangeStatusClientConfirm from "../Modals/ModalChangeStatusClientConfirm";
import Modal from "react-modal";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Change the background color and opacity here
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "20vh",
    width: "20%",
    minWidth: "350px",

    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(269deg, #F1FFFC 0%, #FFFCF7 100%), #FFF`,
  },
};

interface props {
  listUsersState: UserType[];
  loadMoreAction: () => void;
  getInitialeListUsers: () => void;
  setListUsersState: (args: UserType[]) => void;
}

const ListUsers = ({
  listUsersState,
  loadMoreAction,
  getInitialeListUsers,
  setListUsersState,
}: props) => {
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      console.log("bottom");
      loadMoreAction();
    }
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [userToUpDate, setUserToUpdate] = useState<any>(null);
  function closeModal() {
    setIsOpen(false);
  }
  function openModal(user: any) {
    setUserToUpdate(user);
    setIsOpen(true);
  }

  const blokUserAction = () => {
    updateClientStatus(userToUpDate?.phoneNumber, "Blocked")
      .then((response) => {
        if (response?.data.status === 500) {
          toast.error("Internal server error");
          return;
        }
        if (!response.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          closeModal();

          getInitialeListUsers();
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error);
      });
  };

  const deblokUserAction = () => {
    updateClientStatus(userToUpDate?.phoneNumber, "Valid")
      .then((response) => {
        if (response?.data.status === 500) {
          toast.error("Internal server error");
          return;
        }
        if (!response.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          closeModal();

          getInitialeListUsers();
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error);
      });
  };

  const ChangeStatusAction = () => {
    if (userToUpDate?.Status?.toUpperCase() === "VALID") {
      blokUserAction();
    } else {
      deblokUserAction();
    }
  };

  return (
    <div className="list-users-container">
      <div
        className="list-user-item"
        onScroll={handleScroll}
        style={{ overflowY: "scroll", maxHeight: "600px" }}
      >
        <table className="table-style">
          <thead style={{ height: 60 }}>
            <tr>
              <th className="title-txt">Nom et Prénom</th>
              <th className="title-txt">Tel</th>
              <th className="title-txt">Email</th>
              <th className="title-txt">Etat</th>
              <th className="title-txt">Action</th>
            </tr>
          </thead>

          <tbody className="t-body" onScroll={handleScroll}>
            {listUsersState?.length > 0 ? (
              listUsersState
                ?.map((user: any, index: any) => {
                  return (
                    <tr key={index} className="line-table-style">
                      <td className="td-right">
                        <p
                          className="td-right-text-style"
                          style={
                            user?.Status?.toUpperCase() === "VALID"
                              ? {}
                              : { backgroundColor: "#FFF3F3" }
                          }
                        >
                          {Boolean(user?.FullName)
                            ? user?.FullName
                            : "Not set yet"}
                        </p>
                      </td>
                      <td className="td-center">
                        <p
                          className="td-center-txt"
                          style={
                            user?.Status?.toUpperCase() === "VALID"
                              ? {}
                              : { backgroundColor: "#FFF3F3" }
                          }
                        >
                          {" "}
                          {Boolean(user?.phoneNumber)
                            ? user?.phoneNumber
                            : "Not set yet"}
                        </p>
                      </td>
                      <td className="td-center">
                        <p
                          className="td-center-txt"
                          style={
                            user?.Status?.toUpperCase() === "VALID"
                              ? {}
                              : { backgroundColor: "#FFF3F3" }
                          }
                        >
                          {Boolean(user?.Email) ? user?.Email : "Not set yet"}
                        </p>
                      </td>
                      <td className="td-left">
                        <p
                          className="td-left-txt"
                          style={
                            user?.Status?.toUpperCase() === "VALID"
                              ? {}
                              : { backgroundColor: "#FFF3F3" }
                          }
                        >
                          {user?.Status?.toUpperCase() === "VALID"
                            ? "Active"
                            : "Inactive"}
                        </p>
                      </td>
                      <td className="td-right" onClick={() => openModal(user)}>
                        <p
                          className="td-right-text-style"
                          style={
                            user?.Status?.toUpperCase() === "VALID"
                              ? {}
                              : { backgroundColor: "#FFF3F3" }
                          }
                        >
                          <span
                            style={{
                              height: "100%",
                              width: "100%",
                              backgroundColor:
                                user?.Status?.toUpperCase() === "VALID"
                                  ? "#FFECC5"
                                  : "#CEF2EA",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius : 4
                            }}
                          >
                            {user?.sender?.owner?.name}
                            {user?.Status?.toUpperCase() === "VALID"
                              ? "Bloquer"
                              : "Débloquer"}
                          </span>
                        </p>
                      </td>
                    </tr>
                  );
                })
                .reverse()
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <ModalChangeStatusClientConfirm
          ChangeStatusAction={ChangeStatusAction}
          user={userToUpDate}
          closeModal={closeModal}
        />
      </Modal>
    </div>
  );
};

export default ListUsers;
