import "./index.scss";

import { useLoadScript } from "@react-google-maps/api";

import PageScreen from "../../components/page-title";

import HomePageData from "./utils/home-page-data";
import { homePageDataTypes } from "./utils/HomePageDataTypes";
import HomeScrreenDetailsCard from "./details-card";
import HomeScreenMapView from "./map";

function HomePage() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDQdZ3NJ_ScT4WsJG00f1drRUksu062db4",
    language: "fr",
    region: "Tunisia",
  });

  return (
    <div>
      <PageScreen title="Acceuil" />
      <div className="home-page-container">
       
        <div className="home-page-map-container">
          {!isLoaded ? <div>Loading...</div> : <HomeScreenMapView />}
        </div>
        <div className="home-page-details-container">
          {HomePageData().length > 0 ? (
            HomePageData().map((homedata: homePageDataTypes, index: number) => {
              return <HomeScrreenDetailsCard data={homedata} key={index} />;
            })
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default HomePage;
