import "./index.scss";

import ScreenTitle from "../../components/page-title";

function RhScreen() {
  return (
    <>
      <ScreenTitle title="RessourceHumaines" />
    </>
  );
}

export default RhScreen;
