import { OrderDataTypes } from "../../../utils/Types/OrdersDataTypes";

function searchOrders(searchTerm, ordersData: OrderDataTypes[]) {
  const results = [];
  for (const orderId in ordersData) {
    const order = ordersData[orderId];
    if (isObjectMatch(order, searchTerm)) {
      results.push(order);
    }
  }

  return results;
}

function isObjectMatch(obj, searchTerm) {
  for (const key in obj) {
    const value = obj[key];
    if (typeof value === "object") {
      if (isObjectMatch(value, searchTerm)) {
        return true;
      }
    } else if (
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return true;
    }
  }
  return false;
}

export default searchOrders;
