import "./index.scss";
import { useState, useEffect } from "react";
import IngredientCard from "../cards/IngredientCard";
import NewIngredientModal from "../Modals/NewIngredientModal";
import AddIngredientPhoto from "../Modals/AddIngredientPhoto";
import getAllIngrediants from "../../services/api/get-all-ingrediants";
import { toast } from "react-toastify";
import addTopping from "../../services/api/post-topping";
import addToppingPhotos from "../../services/api/post-photos-topping";
import Modal from "react-modal";
import Lottie from "react-lottie";
import {
  defaultOptionsEmpty,
  defaultOptionsLoading,
} from "../../assets/lottie-animations/animationOptions";
import updateToppingPhotos from "../../services/api/patch-ing-images";
import updateToppingProperties from "../../services/api/patch-ing-properties";
import { Topping } from "../../utils/Types/Topping";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Change the background color and opacity here
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "80vh",
    minHeight: "380px",
    maxHeight: "480px",
    width: "55%",
    minWidth: "350px",

    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(269deg, #F1FFFC 0%, #FFFCF7 100%), #FFF`,
  },
};

interface initialImageStateType {
  selectedImage: Blob | MediaSource;
  imageUrl: string;
}

const initialImageState: initialImageStateType = {
  selectedImage: null,
  imageUrl: null,
};

const ListIngredient = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingIng, setLoadingIng] = useState<boolean>(false);

  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [step, setStep] = useState<string>("first");
  const [toppingName, setToppingName] = useState<string>("");
  const [dose, setDose] = useState<number | string>(0);
  const [selectedOptionType, setSelectedOptionType] = useState<any>();
  const [unit, setUnit] = useState<string>("");
  const [price, setPrice] = useState<string | number>("");
  const [listIngredients, setListIngredients] = useState<Topping[]>([]);
  const [toUpdateIng, setToUpdateIng] = useState<Topping>();
  const [images, setImages] = useState<any>([
    { ...initialImageState },
    { ...initialImageState },
    { ...initialImageState },
    { ...initialImageState },
    { ...initialImageState },
  ]);
  const [valuesForm, setValuesForm] = useState<any>(null);

  function openModal(): void {
    setIsOpen(true);
  }
  function closeModal() {
    setImages([
      { ...initialImageState },
      { ...initialImageState },
      { ...initialImageState },
      { ...initialImageState },
      { ...initialImageState },
    ]);
    setToppingName("");
    setDose(0);
    setPrice(null);
    setSelectedOptionType(null);
    setUnit(null);
    setIsOpen(false);
    setStep("first");
    setToUpdateIng(null);
  }

  console.log("selectedOptionType", selectedOptionType);

  const doGetAllIgredients = (): void => {
    setLoading(true);
    getAllIngrediants()
      .then((response) => {
        if (response.data.status === 500) {
          toast.error("server error");
          setLoading(false);
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);
          setLoading(false);

          return;
        }
        if (response.data.success) {
          setListIngredients(response.data.content.toppings);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
        console.log("error", error);
      });
  };

  const doUpdateTopping = (): void => {
    setLoadingIng(true);
    const dataTopping = {
      toppingName: toppingName,
      type: selectedOptionType,
      unit: unit,
      price: Number(price),
      portion: Number(dose),
    };
    updateToppingProperties(valuesForm, toUpdateIng?.id)
      .then((response) => {
        if (response.data.status === 500) {
          toast.error("server errror");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);
          return;
        }
        if (response.data.success) {
          const bodyData = {
            photo1: images?.[1]?.selectedImage,
            photo2: images?.[2]?.selectedImage,
            photo3: images?.[3]?.selectedImage,
            photo4: images?.[4]?.selectedImage,
            icon: images?.[0]?.selectedImage,
          };
          updateToppingPhotos(bodyData, toppingName)
            .then((response) => {
              setLoadingIng(false);

              if (response.data.status === 500) {
                toast.error("server errror");
                return;
              }
              if (!response.data.success) {
                toast.error(response.data.message);
                return;
              }
              if (response.data.success) {
                toast.success("topping updated successfuly ! ");
                closeModal();
                doGetAllIgredients();
              }
            })
            .catch((error) => {
              setLoadingIng(false);

              toast.error(error);
            });
        }
      })
      .catch((error) => {
        setLoadingIng(false);
        toast.error(error);
        console.log("error", error);
      });
  };

  const doAddTopping = (): void => {
    setLoadingIng(true);

    addTopping(valuesForm)
      .then((response) => {
        if (response.data.status === 500) {
          toast.error("server errror");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);
          return;
        }
        if (response.data.success) {
          const bodyData = {
            photo1: images?.[1]?.selectedImage,
            photo2: images?.[2]?.selectedImage,
            photo3: images?.[3]?.selectedImage,
            photo4: images?.[4]?.selectedImage,
            icon: images?.[0]?.selectedImage,
          };
          addToppingPhotos(bodyData, valuesForm?.toppingName)
            .then((response) => {
              setLoadingIng(false);

              if (response.data.status === 500) {
                toast.error("server errror");
                return;
              }
              if (!response.data.success) {
                toast.error(response.data.message);
                return;
              }
              if (response.data.success) {
                toast.success("topping added successfuly ! ");
                closeModal();
                doGetAllIgredients();
              }
            })
            .catch((error) => {
              setLoadingIng(false);

              toast.error(error);
            });
        }
      })
      .catch((error) => {
        setLoadingIng(false);

        console.log("error", error);
        toast.error(error);
      });
  };

  const SubmitTopping = () => {
    if (!Boolean(toUpdateIng)) {
      doAddTopping();
    } else {
      doUpdateTopping();
    }
  };

  const openToUpdate = (topping: Topping) => {
    setToUpdateIng(topping);
    setIsOpen(true);
    setToppingName(topping?.toppingName);
    setDose(topping?.portion);
    setUnit(topping?.unit);
    setPrice(topping?.price);
    setSelectedOptionType({
      label:
        toUpdateIng?.type == "cheese"
          ? "Fromage"
          : toUpdateIng?.type == "vegtables"
          ? "Légumes"
          : "meat",
      value: toUpdateIng?.type,
    });
  };

  useEffect(() => {
    doGetAllIgredients();
  }, []);

  return (
    <div>
      <div className="lg-content">
        <div className="btn-add-store-container">
          <div className="add-ing-btn-wrapper" onClick={() => openModal()}>
            <i className="bx bx-plus" style={{ fontSize: 22 }}></i>
            <button className="add-btn-stl">Ajouter Ingrédient</button>
          </div>
        </div>
        {loading ? (
          <div className="ing-container">
            <div className="loading-wrapper">
              <Lottie
                options={defaultOptionsLoading}
                height={120}
                width={120}
              />
            </div>
          </div>
        ) : (
          <div className="ing-container">
            {listIngredients?.length > 0 ? (
              listIngredients?.map((topping: Topping, index) => (
                <IngredientCard
                  key={index}
                  topping={topping}
                  doGetAllIgredients={doGetAllIgredients}
                  openToUpdate={() => openToUpdate(topping)}
                />
              ))
            ) : (
              <div className="loading-wrapper">
                <Lottie
                  options={defaultOptionsEmpty}
                  height={"40%"}
                  width={"40%"}
                />
                <h2 style={{ color: "#fd6644" }}>
                  La liste d'Ingrédient est vide
                </h2>
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        {step === "first" ? (
          <NewIngredientModal
            toUpdateIng={toUpdateIng}
            setStep={setStep}
            closeModal={closeModal}
            setValuesForm={setValuesForm}
            valuesForm={valuesForm}
          />
        ) : (
          <AddIngredientPhoto
            toUpdateIng={toUpdateIng}
            setStep={setStep}
            images={images}
            setImages={setImages}
            SubmitTopping={SubmitTopping}
            loading={loadingIng}
            valuesForm={valuesForm}
          />
        )}
      </Modal>
    </div>
  );
};
export default ListIngredient;
