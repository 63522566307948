// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page-container {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}

.home-page-details-container {
  display: flex;
  flex-wrap: wrap;
  min-width: 50%;
  height: 70vh;
}

.home-page-map-container {
  width: 70%;
  margin: 12px 0px 0px 0px;
}

@media (max-width: 1024px) {
  .home-page-container {
    flex-direction: column;
    align-items: center;
  }
  .home-page-details-container {
    width: 100%;
    min-width: 100%;
    height: 100vh;
    min-height: 100vh;
  }
  .home-page-map-container {
    width: 100%;
    min-height: 20vh;
    height: 30vh;
  }
}
@media (max-width: 768px) {
  .home-page-details-container {
    height: auto;
    min-height: 130vh;
    width: 90vw;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 576px) {
  .home-page-details-container {
    height: 40vh;
  }
}`, "",{"version":3,"sources":["webpack://./src/screens/home-screen/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;EACA,cAAA;EACA,YAAA;AACF;;AAEA;EACE,UAAA;EACA,wBAAA;AACF;;AAEA;EACE;IACE,sBAAA;IACA,mBAAA;EACF;EAEA;IACE,WAAA;IACA,eAAA;IACA,aAAA;IACA,iBAAA;EAAF;EAGA;IACE,WAAA;IACA,gBAAA;IACA,YAAA;EADF;AACF;AAIA;EACE;IACE,YAAA;IACA,iBAAA;IACA,WAAA;IACA,aAAA;IACA,uBAAA;EAFF;AACF;AAKA;EACE;IACE,YAAA;EAHF;AACF","sourcesContent":[".home-page-container {\n  display: flex;\n  justify-content: space-between;\n  padding-right: 20px;\n}\n\n.home-page-details-container {\n  display: flex;\n  flex-wrap: wrap;\n  min-width: 50%;\n  height: 70vh;\n}\n\n.home-page-map-container {\n  width: 70%;\n  margin: 12px 0px 0px 0px;\n}\n\n@media (max-width: 1024px) {\n  .home-page-container {\n    flex-direction: column;\n    align-items: center;\n  }\n\n  .home-page-details-container {\n    width: 100%;\n    min-width: 100%;\n    height: 100vh;\n    min-height: 100vh;\n  }\n\n  .home-page-map-container {\n    width: 100%;\n    min-height: 20vh;\n    height: 30vh;\n  }\n}\n\n@media (max-width: 768px) {\n  .home-page-details-container {\n    height: auto;\n    min-height: 130vh;\n    width: 90vw;\n    display: flex;\n    justify-content: center;\n  }\n}\n\n@media (max-width: 576px) {\n  .home-page-details-container {\n    height: 40vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
