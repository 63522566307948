import moment from "moment";

import { OrderDataTypes } from "../../../utils/Types/OrdersDataTypes";

import { FilterType } from "./allHeadersFilterTypes";

type sortSettingsType = {
  column: FilterType;
  isDesc: boolean;
};

function getSortFunction(sortSettings: sortSettingsType) {
  if (
    sortSettings.column === "id" ||
    sortSettings.column === "orderNumber" ||
    sortSettings.column === "Status" ||
    sortSettings.column === "OrderType"
  ) {
    return function (a: OrderDataTypes, b: OrderDataTypes) {
      return sortSettings.isDesc
        ? b[sortSettings.column].localeCompare(
            a[sortSettings.column],
            undefined,
            { numeric: true }
          )
        : a[sortSettings.column].localeCompare(
            b[sortSettings.column],
            undefined,
            { numeric: true }
          );
    };
  }
  if (sortSettings.column === "client") {
    return function (a: OrderDataTypes, b: OrderDataTypes) {
      return sortSettings.isDesc
        ? b.Client.phoneNumber
            .toString()
            .localeCompare(a.Client.phoneNumber.toString(), undefined, {
              numeric: true,
            })
        : a.Client.phoneNumber
            .toString()
            .localeCompare(b.Client.phoneNumber.toString(), undefined, {
              numeric: true,
            });
    };
  }

  if (sortSettings.column === "runner") {
    return function (a: OrderDataTypes, b: OrderDataTypes) {
      return sortSettings.isDesc
        ? b?.Runner?.fullName ??
            "".localeCompare(a?.Runner?.fullName ?? "", undefined, {
              numeric: true,
            })
        : a.Runner?.fullName ??
            "".localeCompare(b?.Runner?.fullName ?? " ", undefined, {
              numeric: true,
            });
    };
  }

  if (sortSettings.column === "store") {
    return function (a: OrderDataTypes, b: OrderDataTypes) {
      return sortSettings.isDesc
        ? b?.Store?.name ??
            "".localeCompare(a?.Store?.name ?? "", undefined, {
              numeric: true,
            })
        : a?.Store?.name ??
            "".localeCompare(b?.Store?.name ?? " ", undefined, {
              numeric: true,
            });
    };
  }

  if (sortSettings.column === "orderDate") {
    return function (a: OrderDataTypes, b: OrderDataTypes) {
      if (sortSettings.column === "orderDate") {
        const dateA = moment(a.CreationDate, "DD/MM/YYYY HH:mm:ss");
        const dateB = moment(b.CreationDate, "DD/MM/YYYY HH:mm:ss");
        if (sortSettings.isDesc) {
          return dateB.diff(dateA);
        } else {
          return dateA.diff(dateB);
        }
      }
    };
  }
}

export default getSortFunction;
