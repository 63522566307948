import { useNavigate, useParams } from "react-router-dom";
import ScreenTitle from "../../components/page-title";
import getOneStore from "../../services/api/get-one-store";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { GoogleMap, Marker } from "@react-google-maps/api";
import Input from "react-phone-number-input/input";
import Modal from "react-modal";

import "./index.scss";
import getCurrentOrdersByStore from "../../services/api/get-current-orders-by-store";
import updateStore from "../../services/api/patch-store";
import { StoreType } from "../../utils/Types/StoreType";
import ListOrdersCard from "../../components/ListOrdersCard";
import AddAddressModal from "../../components/Modals/AddAddressModal";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "70vh",
    width: "50%",
    minWidth : "350px",

    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(269deg, #F1FFFC 0%, #FFFCF7 100%), #FFF`,
  },
};

interface mapPosition {
  lat: number;
  lng: number;
}

const StoreUpdate = () => {
  const [addressPosition, setAddressPosition] = useState<any>();
  const [store, setStore] = useState<StoreType>();
  const [storeAddress, setStoreAddress] = useState<string>("");
  const [storeName, setStoreName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [latitude, setLatitude] = useState<number>();
  const [longtitude, setLongtitude] = useState<number>();
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [step, setStep] = useState<any>("");
  const [center , setCenter] = useState({lat : 0 , lng : 0})

  const [listCurrentOrders, setListCurrentOrders] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const doGetOneStore = (): void => {
    getOneStore(id)
      .then((response) => {
        if (response.data.status === 500) {
          console.log("server error");
          toast.error("server error");
          return;
        }
        if (!response.data.success) {
          console.log("error :", response.data.message);
          toast.error("error : " + response.data.message);
          return;
        }
        if (response.data.success) {
          setStore(response.data.content.pizzaStore);
          setStoreName(response.data.content.pizzaStore.name);
          setPhoneNumber("+216" +response.data.content.pizzaStore?.phoneNumber);
          setAddressPosition({
            addressName: response.data.content.pizzaStore?.adresses?.name,
            position: {
              latitude: response.data.content.pizzaStore?.adresses?.latitude,
              longitude: response.data.content.pizzaStore?.adresses?.longtitude,
            },
          });
          setStoreAddress(response.data.content.pizzaStore?.adresses?.name);
          setLatitude(response.data.content.pizzaStore?.adresses?.latitude);
          setLongtitude(response.data.content.pizzaStore?.adresses?.longtitude);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error);
      });
  };

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const doUpdateStore = (): void => {
    const dataBody = {
      phoneNumber: phoneNumber,
      name: storeName,
      adresses: {
        name: addressPosition?.addressName,
        latitude: addressPosition?.position?.latitude,
        longtitude: addressPosition?.position?.longitude,
      },
    };
    updateStore(id, dataBody)
      .then((response) => {
        if (response.data.status === 500) {
          toast.error("server error");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);
          return;
        }
        if (response.data.success) {
          toast.success("store updated successfuly ");
          navigate("/stores");
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const doGetCurrentOrders = (): void => {
    getCurrentOrdersByStore(id)
      .then((response) => {
        if (response?.data.status === 500) {
          toast.error("server error");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response.data.message);
          return;
        }
        if (response.data.success) {
          setListCurrentOrders(response?.data?.content?.orders);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  useEffect(() => {
    doGetOneStore();
    doGetCurrentOrders();
  }, []);

  useEffect(() => {
    const latitude = parseFloat(addressPosition?.position?.latitude);
    const longitude = parseFloat(addressPosition?.position?.longitude);

    if (!isNaN(latitude) && !isNaN(longitude)) {
      setCenter({
        lat: +latitude,
        lng: +longitude,
      });
    }
  }, [addressPosition?.position?.latitude, addressPosition?.position?.longitude]);
  return (
    <div>
      <div>
        <ScreenTitle title="Détails" />
        <div className="pzz-store-update-container">
          <div className="pzz-store-update-header">
            <input
              value={storeName}
              onChange={(e) => setStoreName(e.target.value)}
              className="pzz-store-update-header-input-store-name"
            />
            <button
              className="pzz-store-update-header-update-btn"
              onClick={() => doUpdateStore()}
            >
              Enregistrer
            </button>
          </div>
          <div className="pzz-store-update-head">
            <div>
              <p style={{ marginTop: 15 }}>Adresse</p>
              <input
                disabled={true}
                value={addressPosition?.addressName}
                className="pzz-store-update-input-address"
                onChange={(e) => setStoreAddress(e.target.value)}
              />
            </div>
            <div style={{ marginLeft: 35 }}>
              <p style={{ marginTop: 15 }}>Téléphone</p>
              <Input
                style={{
                  minHeight: 54,
                  minWidth: "40%",
                  width: "100%",
                  borderWidth: 0,
                  borderRadius: 4,
                  backgroundColor: "#fff",
                  paddingLeft: 10,
                }}
                country="TN"
                international
                withCountryCallingCode={true}
                value={phoneNumber}
                onChange={setPhoneNumber}
              />
            </div>
          </div>
          <div
            className="pzz-store-update-map-container"
            onClick={() => openModal()}
          >
            <GoogleMap
              clickableIcons={false}
              zoom={15}
              center={center}
              mapContainerClassName="map-container"
            >
              <Marker
                position={center}
                visible={true}
                zIndex={1000}
                cursor="pointer"
              />
            </GoogleMap>
          </div>

          <ListOrdersCard listCurrentOrders={listCurrentOrders} />
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <AddAddressModal
          setAddressPosition={setAddressPosition}
          addressPosition={addressPosition}
          setStep={setStep}
          edit={true}
          closeModal={closeModal}
        />
      </Modal>
    </div>
  );
};
export default StoreUpdate;
