// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pzz-modal-add-address-container {
  width: 100%;
  height: 100%;
}

.pzz-modal-add-address-header {
  height: 15%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 15px;
  padding-top: 10px;
}
.pzz-modal-add-address-header .pzz-go-back-btn {
  height: 28px;
  width: 28px;
  border-radius: 500px;
  background-color: #f2f2f2;
}
.pzz-modal-add-address-header .pzz-text-select-position {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-left: 15px;
}

.pzz-select-address-container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.pzz-select-address-container .pzz-save-btn-container {
  width: 20%;
  display: flex;
  justify-content: center;
}
.pzz-select-address-container .pzz-save-btn-container .pzz-btn-save {
  width: 70%;
  border-width: 0px;
  background-color: #159b7b;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 4px;
}

.pzz-modal-add-address-header-edit {
  height: 15%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 15px;
  padding-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/AddAddressModal/index.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;;AACA;EACE,WAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,uBAAA;EACA,kBAAA;EACA,iBAAA;AAEF;AADE;EACE,YAAA;EACA,WAAA;EACA,oBAAA;EACA,yBAAA;AAGJ;AADE;EACE,eAAA;EACA,gBAAA;EACA,WAAA;EACA,iBAAA;AAGJ;;AAAA;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;AAGF;AAFE;EACE,UAAA;EACA,aAAA;EACA,uBAAA;AAIJ;AAHI;EACE,UAAA;EACA,iBAAA;EACA,yBAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AAKN;;AAAA;EACE,WAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,uBAAA;EACA,kBAAA;EACA,iBAAA;AAGF","sourcesContent":[".pzz-modal-add-address-container {\n  width: 100%;\n  height: 100%;\n}\n.pzz-modal-add-address-header {\n  height: 15%;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  padding-left: 15px;\n  padding-top: 10px;\n  .pzz-go-back-btn {\n    height: 28px;\n    width: 28px;\n    border-radius: 500px;\n    background-color: #f2f2f2;\n  }\n  .pzz-text-select-position {\n    font-size: 16px;\n    font-weight: 500;\n    color: #000;\n    margin-left: 15px;\n  }\n}\n.pzz-select-address-container {\n  margin-bottom: 20px;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  .pzz-save-btn-container {\n    width: 20%;\n    display: flex;\n    justify-content: center;\n    .pzz-btn-save {\n      width: 70%;\n      border-width: 0px;\n      background-color: #159b7b;\n      color: #fff;\n      font-size: 16px;\n      font-weight: 700;\n      border-radius: 4px;\n    }\n  }\n}\n\n.pzz-modal-add-address-header-edit {\n  height: 15%;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: flex-start;\n  padding-left: 15px;\n  padding-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
