import React from "react";
import "./index.scss";
import { useSelector, useStore } from "react-redux";
import {
  PIZZA_SLICE_ADD_DOUGHTPRICE,
  PIZZA_SLICE_DECREASE_QUANTITY,
  PIZZA_SLICE_INCREASE_QUANTITY,
} from "../../store/slices/reducers/pizzaSlice";
import { Pizza } from "../../utils/Types/PizzaDataTypes";
import { Topping } from "../../utils/Types/Topping";
import { PizzaSizeEnum } from "../../utils/Types/enum-size";
interface props {
  closeModal: any;
  setStep: (args: string) => void;
  setModeSize: (args: string) => void;
  modeSize: string;
}
const PizzaCreateSecondStep = ({
  closeModal,
  setStep,
  setModeSize,
  modeSize,
}: props) => {
  const store = useStore();

  console.log("modeSize", modeSize);

  const pizzaSmall: Pizza = useSelector(
    (state: Storage) => state.entities.pizzaSlice.pizzaSmall
  );

  const pizzaMedium: Pizza = useSelector(
    (state: Storage) => state.entities.pizzaSlice.pizzaMedium
  );

  const pizzaLarge: Pizza = useSelector(
    (state: Storage) => state.entities.pizzaSlice.pizzaLarge
  );

  console.log("small , medium , large", pizzaSmall, pizzaMedium, pizzaLarge);

  const increase = (size: string, value: string) => {
    try {
      store.dispatch({
        type: PIZZA_SLICE_INCREASE_QUANTITY,
        payload: { size, value },
      });
    } catch (error) {
      console.log("errror redux");
    }
  };

  const decrease = (size: string, value: string) => {
    try {
      store.dispatch({
        type: PIZZA_SLICE_DECREASE_QUANTITY,
        payload: { size, value },
      });
    } catch (error) {
      console.log("errror redux");
    }
  };
  const changeDoughtPriceMedium = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    try {
      store.dispatch({
        type: PIZZA_SLICE_ADD_DOUGHTPRICE,
        payload: { size: PizzaSizeEnum.medium, price: e.target.value },
      });
    } catch (error) {
      console.log("errror redux");
    }
  };
  const changeDoughtPriceSmall = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    try {
      store.dispatch({
        type: PIZZA_SLICE_ADD_DOUGHTPRICE,
        payload: { size: PizzaSizeEnum.small, price: e.target.value },
      });
    } catch (error) {
      console.log("errror redux");
    }
  };
  const changeDoughtPriceLarge = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    try {
      store.dispatch({
        type: PIZZA_SLICE_ADD_DOUGHTPRICE,
        payload: { size: PizzaSizeEnum.large, price: e.target.value },
      });
    } catch (error) {
      console.log("errror redux");
    }
  };

  const validationDoughPrices = (small, medium, large) => {
    if (small?.doughPrice && medium?.doughPrice && large?.doughPrice) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="high-container-pcs">
      <div className="header-pcs">
        <p className="pzz-title">New Pizza</p>
        <i
          onClick={closeModal}
          style={{
            fontSize: 25,
            color: "#000",
            textAlign: "end",
            alignSelf: "flex-end",
            justifyContent: "flex-start",
          }}
          className="bx bx-x"
        ></i>
      </div>
      <div className="pzz-body">
        <div className="pzz-body-content">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{ marginRight: 40 }}
              onClick={() => setModeSize("small")}
            >
              <p
                className={
                  modeSize === "small"
                    ? "txt-size-selected"
                    : Boolean(pizzaSmall.doughPrice)
                    ? "txt-validated"
                    : "txt-size-inselected"
                }
              >
                Small
              </p>
            </div>
            <div
              style={{ marginRight: 40 }}
              onClick={() => setModeSize("medium")}
            >
              <p
                className={
                  modeSize === "medium"
                    ? "txt-size-selected"
                    : Boolean(pizzaMedium.doughPrice)
                    ? "txt-validated"
                    : "txt-size-inselected"
                }
              >
                Medium
              </p>
            </div>
            <div
              style={{ marginRight: 40 }}
              onClick={() => setModeSize("large")}
            >
              <p
                className={
                  modeSize === "large"
                    ? "txt-size-selected"
                    : Boolean(pizzaLarge.doughPrice)
                    ? "txt-validated"
                    : "txt-size-inselected"
                }
              >
                Large
              </p>
            </div>
          </div>
          {modeSize.toUpperCase() === "SMALL" ? (
            <div className="pzz-items-content">
              <div className="pzz-items-wrapper">
                <div className="input-dought-prie-wrapper">
                  <input
                    className="dought-price-input"
                    value={pizzaSmall?.doughPrice}
                    placeholder="cout de la pate"
                    color="#159B7B"
                    onChange={(e) => changeDoughtPriceSmall(e)}
                  />
                  <div className="tnd-wrapper">
                    <p style={{ color: "#E8E8E8", textAlign: "end" }}>TND</p>
                  </div>
                </div>

                <div className="horizontale-line-y" />
              </div>
              {pizzaSmall?.toppings.length > 0 ? (
                pizzaSmall?.toppings.map((topping: Topping) => (
                  <div className="pzz-item-container">
                    <div className="pzz-plus-minus-container">
                      <button
                        style={{ borderWidth: 0, backgroundColor: "#fff" }}
                        disabled={topping.quantity === 1 ? true : false}
                        onClick={() =>
                          decrease(PizzaSizeEnum.small, topping.value)
                        }
                      >
                        <i
                          style={{
                            fontSize: 25,
                            color: "#000",
                            marginRight: 10,
                          }}
                          className="bx bx-minus"
                        ></i>
                      </button>
                      <div style={{ marginRight: 10 }}>
                        <p>{topping?.quantity}</p>
                      </div>
                      <i
                        onClick={() =>
                          increase(PizzaSizeEnum.small, topping.value)
                        }
                        style={{ fontSize: 25, color: "#000", marginRight: 10 }}
                        className="bx bx-plus"
                      ></i>
                    </div>

                    <div className="pzz-name-ingrediant-count">
                      <p style={{ color: "#000" }}>{topping?.value}</p>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          ) : modeSize.toUpperCase() === "MEDIUM" ? (
            <div className="pzz-items-content">
              <div className="pzz-items-wrapper">
                <div className="input-dought-prie-wrapper">
                  <input
                    className="dought-price-input"
                    value={
                      pizzaMedium?.doughPrice ? pizzaMedium?.doughPrice : ""
                    }
                    placeholder="cout de la pate"
                    onChange={(e) => changeDoughtPriceMedium(e)}
                  />
                  <div className="tnd-wrapper">
                    <p style={{ color: "#E8E8E8", textAlign: "end" }}>TND</p>
                  </div>
                </div>
                <div className="horizontale-line-y" />
              </div>
              {pizzaMedium?.toppings.length ? (
                pizzaMedium?.toppings.map((topping: Topping) => (
                  <div className="pzz-item-container">
                    <div className="pzz-plus-minus-container">
                      <button
                        style={{ borderWidth: 0, backgroundColor: "#fff" }}
                        disabled={topping.quantity === 1 ? true : false}
                        onClick={() =>
                          decrease(PizzaSizeEnum.medium, topping.value)
                        }
                      >
                        <i
                          style={{
                            fontSize: 25,
                            color: "#000",
                            marginRight: 10,
                          }}
                          className="bx bx-minus"
                        ></i>
                      </button>
                      <div style={{ marginRight: 10 }}>
                        <p>{topping?.quantity}</p>
                      </div>
                      <i
                        onClick={() =>
                          increase(PizzaSizeEnum.medium, topping.value)
                        }
                        style={{ fontSize: 25, color: "#000", marginRight: 10 }}
                        className="bx bx-plus"
                      ></i>
                    </div>

                    <div className="pzz-name-ingrediant-count">
                      <p style={{ color: "#000" }}>{topping?.value}</p>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          ) : (
            <div className="pzz-items-content">
              <div className="pzz-items-wrapper">
                <div className="input-dought-prie-wrapper">
                  <input
                    className="dought-price-input"
                    value={pizzaLarge?.doughPrice ? pizzaLarge?.doughPrice : ""}
                    placeholder="cout de la pate"
                    onChange={(e) => changeDoughtPriceLarge(e)}
                  />
                  <div className="tnd-wrapper">
                    <p style={{ color: "#E8E8E8", textAlign: "end" }}>TND</p>
                  </div>
                </div>
                <div className="horizontale-line-y" />
              </div>
              {pizzaLarge?.toppings.length > 0 ? (
                pizzaLarge?.toppings.map((topping: Topping, index: any) => (
                  <div key={index} className="pzz-item-container">
                    <div className="pzz-plus-minus-container">
                      <button
                        style={{ borderWidth: 0, backgroundColor: "#fff" }}
                        disabled={topping.quantity === 1 ? true : false}
                        onClick={() =>
                          decrease(PizzaSizeEnum.large, topping.value)
                        }
                      >
                        <i
                          style={{
                            fontSize: 25,
                            color: "#000",
                            marginRight: 10,
                          }}
                          className="bx bx-minus"
                        ></i>
                      </button>
                      <div style={{ marginRight: 10 }}>
                        <p>{topping?.quantity}</p>
                      </div>
                      <i
                        onClick={() =>
                          increase(PizzaSizeEnum.large, topping.value)
                        }
                        style={{ fontSize: 25, color: "#000", marginRight: 10 }}
                        className="bx bx-plus"
                      ></i>
                    </div>

                    <div className="pzz-name-ingrediant-count">
                      <p style={{ color: "#000" }}>{topping?.value}</p>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
        <div className="summary-container">
          <p className="summary-txt">Résumé</p>
          <p className="pizza-name-txt">
            Pizza {pizzaSmall?.name.substring(4)}
          </p>
          <div style={{ width: "100%" }}>
            <p>Small</p>
            <div className="pzz-ing-container">
              {pizzaSmall?.toppings?.length > 0 ? (
                pizzaSmall?.toppings?.map((topping: Topping, index: any) => (
                  <div key={index} className="pzz-ing-text-container">
                    <p className="sum-ing-txt">
                      {" "}
                      {topping.quantity} X {topping?.value}
                    </p>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <p>Medium</p>
            <div className="pzz-ing-container">
              {pizzaMedium?.toppings.length > 0 ? (
                pizzaMedium?.toppings?.map((topping: Topping, index: any) => (
                  <div key={index} className="pzz-ing-text-container">
                    <p className="sum-ing-txt">
                      {" "}
                      {topping.quantity} X {topping?.value}
                    </p>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <p>Large</p>
            <div className="pzz-ing-container">
              {pizzaLarge?.toppings.length > 0 ? (
                pizzaLarge?.toppings?.map((topping: Topping, index: any) => (
                  <div key={index} className="pzz-ing-text-container">
                    <p className="sum-ing-txt">
                      {" "}
                      {topping.quantity} X {topping?.value}
                    </p>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="pzz-footer-s">
        <div className="steppers-container">
          <div className="pzz-stepper-content-f">
            <div className="pzz-inactive-step" />
            <div className="pzz-active-step" />
            <div className="pzz-inactive-step" />
          </div>
        </div>
        <div className="btns-container-pcs">
          <div className="back-btn-wrapper">
            <button className="back-btn-pcs" onClick={() => setStep("first")}>
              Precedent
            </button>
          </div>
          <div className="next-btn-wrapper-pcs">
            <button
              disabled={
                !validationDoughPrices(pizzaSmall, pizzaMedium, pizzaLarge)
              }
              className={
                !validationDoughPrices(pizzaSmall, pizzaMedium, pizzaLarge)
                  ? "next-btn-pcs-inactive"
                  : "next-btn-pcs"
              }
              onClick={() => setStep("third")}
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PizzaCreateSecondStep;
