import { FilterType } from "./allHeadersFilterTypes";

export type HeaderTitleType = {
  title: string;
  filterName: FilterType;
};

const ordersTableHeaderTitles: HeaderTitleType[] = [
  { title: "ID", filterName: "id" },
  { title: "Numéro d'ordre", filterName: "orderNumber" },
  { title: "Client", filterName: "client" },
  { title: "Runner", filterName: "runner" },
  { title: "Store", filterName: "store" },
  { title: "Date de l’ordre", filterName: "orderDate" },
  { title: "Type d'ordre", filterName: "OrderType" },
  { title: "Delais", filterName: "delay" },
  { title: "Status", filterName: "Status" },
];

export default ordersTableHeaderTitles;
