// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.queue-txt-warpper {
  padding: 10px;
  margin-top: 20px;
}
.queue-txt-warpper .queue-txt {
  font-weight: 700;
  color: #159b7b;
}

.lo-empty-lottie-container {
  width: 80vw;
  height: 50vh;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/ListOrdersCard/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;AACF;AAAE;EACE,gBAAA;EACA,cAAA;AAEJ;;AACA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAEF","sourcesContent":[".queue-txt-warpper {\n  padding: 10px;\n  margin-top: 20px;\n  .queue-txt {\n    font-weight: 700;\n    color: #159b7b;\n  }\n}\n.lo-empty-lottie-container {\n  width: 80vw;\n  height: 50vh;\n  align-self: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
