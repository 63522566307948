import "./index.scss";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import Modal from "react-modal";
import PizzaCreateFirstStep from "../PizzaCreateFirstStep";
import PizzaCreateSecondStep from "../PizzaCreateSecondStep";
import PizzaCreateThirdStep from "../PizzaCreateThirdStep";
import getAllIngrediants from "../../services/api/get-all-ingrediants";
import { useSelector, useStore } from "react-redux";
import { PIZZA_SLICE_RESET } from "../../store/slices/reducers/pizzaSlice";
import { Topping } from "../../utils/Types/Topping";

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change the background color and opacity here
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "65%",
    width: "50%",
    minWidth : "350px",
    minHeight : "300px",
    maxHeight : "420px",
    

    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(269deg, #F1FFFC 0%, #FFFCF7 100%), #FFF`,
  },
};

type ScreenTitleProps = {
  title: string;
  openModalAddRunner?: any
  getAllPizzas? : any
};

function ScreenTitle({ title , openModalAddRunner , getAllPizzas }: ScreenTitleProps): JSX.Element {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modeSize, setModeSize] = useState("small");
  const [step, setStep] = useState("first");
  const [listToppings, setListToppings] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState(null);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    resetStore();
    setIsOpen(false);
    setStep("first");
  }

  const options = listToppings.map((topping: Topping) => ({
    value: topping.toppingName,
    label: topping.toppingName,
  }));

  const store = useStore();

  const pizza = useSelector(
    (state: Storage) => state?.entities?.pizzaSlice?.pizza
  );
  const filteredOptions = options.filter((option) => {
    return !pizza?.toppings.some((selected) => selected.value === option.value);
  });

  function getListIngrediants() {
    getAllIngrediants()
      .then((response) => {
        if (response?.data?.status === 500) {
          console.log("server  error");
          return;
        }
        if (!response.data.success) {
          console.log("error");
          return;
        }
        if (response?.data?.success) {
          setListToppings(response.data.content.toppings);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function resetStore() {
    try {
      store.dispatch({
        type: PIZZA_SLICE_RESET,
        payload: {},
      });
    } catch (error) {
      console.log("errror redux");
    }
  }

  useEffect(() => {
    if (title !== "Pizzas") {
      return;
    } else {
      getListIngrediants();
    }
    //eslint-disable-next-line
  }, []);

  return (
    <div className="page-titles-container">
      <div style={{ marginRight: 15 }}>{title}</div>
      {title === "Pizzas" ? (
        <div
          style={{
            height: 25,
            minWidth: 30,
            backgroundColor: "#159B7B",
            padding: 10,
            paddingRight: 15,
            paddingLeft: 15,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 4,
            cursor:'pointer'
          }}
          onClick={() => openModal()}
        >
          <FontAwesomeIcon icon={faPlus} color="#fff" />
          <p
            style={{
              fontSize: 12,
              fontWeight: "700",
              marginLeft: 10,
              color: "#fff",
            }}
          >
            New
          </p>
        </div>
      ) : title == "Courier" ? (
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <div
            style={{
              height: 25,
              minWidth: 30,
              backgroundColor: "#159B7B",
              padding: 10,
              paddingRight: 15,
              paddingLeft: 15,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 4,
            }}
            onClick={() => openModalAddRunner()}
          >
            <FontAwesomeIcon icon={faPlus} color="#fff" />
            <p
              style={{
                fontSize: 12,
                fontWeight: "700",
                marginLeft: 10,
                color: "#fff",
              }}
            >
              Ajouter ZZ Courier
            </p>
          </div>
          <div className="line-after-title"></div>
        </div>
      ) : (
        <div className="line-after-title"></div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        {step === "first" ? (
          <PizzaCreateFirstStep
            selectedOption={selectedOption}
            filteredOptions={filteredOptions}
            closeModal={closeModal}
            setStep={setStep}
          />
        ) : step === "second" ? (
          <PizzaCreateSecondStep
            setModeSize={setModeSize}
            closeModal={closeModal}
            modeSize={modeSize}
            setStep={setStep}
          />
        ) : (
          <PizzaCreateThirdStep setStep={setStep} closeModal={closeModal} getAllPizzas={getAllPizzas} />
        )}
      </Modal>
    </div>
  );
}

export default ScreenTitle;
