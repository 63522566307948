// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.right-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 30px 25px 0px;
  width: 100%;
}
.right-header-container .date-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  text-transform: capitalize;
}
.right-header-container .meteo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f7efef;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 700;
  padding: 10px 10px;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.right-header-container .meteo-container p {
  min-width: 1% !important;
  margin: 0px !important;
  padding: 0px 6px !important;
  text-align: center;
}

.loader-container-r {
  height: 50px;
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/layout/app-layout/right-header-layout/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,2BAAA;EACA,WAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,0BAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,8BAAA;EACA,iBAAA;AAEJ;AADI;EACE,wBAAA;EACA,sBAAA;EACA,2BAAA;EACA,kBAAA;AAGN;;AACA;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AAEF","sourcesContent":[".right-header-container {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  padding: 25px 30px 25px 0px;\n  width: 100%;\n  .date-container {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    font-size: 16px;\n    text-transform: capitalize;\n  }\n  .meteo-container {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    background: #f7efef;\n    border-radius: 4px;\n    font-size: 12px;\n    font-weight: 700;\n    padding: 10px 10px;\n    justify-content: space-between;\n    flex-wrap: nowrap;\n    p {\n      min-width: 1% !important;\n      margin: 0px !important;\n      padding: 0px 6px !important;\n      text-align: center;\n    }\n  }\n}\n.loader-container-r {\n  height: 50px;\n  width: 140px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
