// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ing-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media (max-width: 470px) {
  .ing-container {
    flex-direction: column;
  }
}
@media (min-width: 471px) and (max-width: 1200px) {
  .ing-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
@media (min-width: 1201px) {
  .ing-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
.add-ing-btn-wrapper {
  width: 155px;
  height: 35px;
  border-width: 0px;
  background-color: #159b7b;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  margin-bottom: 15px;
}
.add-ing-btn-wrapper .add-btn-stl {
  background-color: #159b7b;
  border-width: 0px;
  color: #fff;
}

.loading-wrapper {
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.lg-content {
  height: 100%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/ListIngrediants/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,2BAAA;AACF;;AAEA;EACE;IACE,sBAAA;EACF;AACF;AAEA;EACE;IACE,mBAAA;IACA,eAAA;IACA,2BAAA;EAAF;AACF;AAGA;EACE;IACE,mBAAA;IACA,eAAA;IACA,2BAAA;EADF;AACF;AAIA;EACE,YAAA;EACA,YAAA;EACA,iBAAA;EACA,yBAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,6BAAA;EACA,gBAAA;EACA,mBAAA;AAFF;AAGE;EACE,yBAAA;EACA,iBAAA;EACA,WAAA;AADJ;;AAKA;EACE,WAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AAFF;;AAKA;EACE,YAAA;EACA,WAAA;AAFF","sourcesContent":[".ing-container {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n}\n\n@media (max-width: 470px) {\n  .ing-container {\n    flex-direction: column;\n  }\n}\n\n@media (min-width: 471px) and (max-width: 1200px) {\n  .ing-container {\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: flex-start;\n  }\n}\n\n@media (min-width: 1201px) {\n  .ing-container {\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: flex-start;\n  }\n}\n\n.add-ing-btn-wrapper {\n  width: 155px;\n  height: 35px;\n  border-width: 0px;\n  background-color: #159b7b;\n  color: #fff;\n  border-radius: 4px;\n  padding: 5px;\n  display: flex;\n  justify-content: space-around;\n  margin-top: 15px;\n  margin-bottom: 15px;\n  .add-btn-stl {\n    background-color: #159b7b;\n    border-width: 0px;\n    color: #fff;\n  }\n}\n\n.loading-wrapper {\n  height: 60%;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.lg-content {\n  height: 100%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
