import { useNavigate } from "react-router-dom";
import "./index.scss";
import { Pizza } from "../../../utils/Types/PizzaDataTypes";
import { useState } from "react";
import Lottie from "react-lottie";
import { defaultOptionsLoading } from "../../../assets/lottie-animations/animationOptions";

interface props {
  pizza: Pizza;
}
const PizzaCard = ({ pizza }: props) => {
  const navigate = useNavigate();
  const [loadingImage, setLoadingImage] = useState(true);

  const handleLoadEnd = () => {
    setLoadingImage(false);
  };

  return (
    <div
      className="Pizza-Card-Container"
      onClick={() => navigate(`/pizza/${pizza?.name}`)}
    >
      <div className="Pizza-Card-Content">
        {loadingImage && (
          <div className="loading-wrapper-rapper">
            <Lottie
              options={defaultOptionsLoading}
              height={"90%"}
              width={"50%"}
            />
          </div>
        )}

        <img
          className={`img-pzz ${loadingImage ? "hidden" : ""}`}
          src={`${process.env?.REACT_APP_SERVER_URL}/photo?pizza=${pizza.name}`}
          alt="Pizza"
          onLoad={handleLoadEnd}
        />
      </div>
      <div className="Pzz-details">
        <div className="pzz-details-info">
          <p className="pzz-name-txt">PIZZA {pizza?.name.substring(4)}</p>
        </div>
        <div className="pzz-ingrediants-content">
          {pizza?.toppings?.map((topping, index) => (
            <div key={index} className="pzz-ingrediant-txt-content">
              <p className="pzz-ing-txt">{topping?.toppingName}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PizzaCard;
