import { UserType } from "../../../utils/Types/UserType";
import "./index.scss";

interface props {
  ChangeStatusAction: (args : any) => void;
  user: UserType;
  closeModal: () => void;
}

const ModalChangeStatusClientConfirm = ({
  ChangeStatusAction,
  user,
  closeModal,
}: props) => {
  return (
    <div className="change-status-modal-container">
      <div className="change-status-modal-header">
        <p className="change-status-modal-header-txt">
          Vous etes sure de vouloir bloquer
        </p>
      </div>
      <div className="change-status-modal-body">
        <p className="change-status-modal-body-txt">
          {Boolean(user?.FullName) ? user?.FullName : user?.phoneNumber}
        </p>
      </div>
      <div className="change-status-modal-footer">
        <button
          className="change-status-modal-footer-btn-cancel"
          onClick={() => closeModal()}
        >
          Annuler
        </button>
        <button
          className={
            user?.Status.toUpperCase() !== "VALID"
              ? "change-status-modal-footer-btn-confirm-valid"
              : "change-status-modal-footer-btn-confirm-blocked"
          }
          onClick={ChangeStatusAction}
        >
          {user?.Status.toUpperCase() === "VALID" ? "Bloquer" : "Debloquer"}
        </button>
      </div>
    </div>
  );
};
export default ModalChangeStatusClientConfirm;
