import { defaultOptionsEmpty } from "../../assets/lottie-animations/animationOptions";
import { OrderTypes } from "../../utils/Types/OrdersDataTypes";
import OrderCard from "../cards/OrderCard";
import "./index.scss";
import Lottie from "react-lottie";

interface props {
  listCurrentOrders: OrderTypes[];
}
const ListOrdersCard = ({ listCurrentOrders }: props) => {
  return (
    <div>
      <div className="queue-txt-warpper">
        <p className="queue-txt">Queue</p>
      </div>
      <div className="orders-container">
        {listCurrentOrders?.length > 0 ? (
          listCurrentOrders?.map((order: OrderTypes, index: any) => (
            <OrderCard key={index} order={order} />
          ))
        ) : (
          <div className="lo-empty-lottie-container">
            <Lottie
              options={defaultOptionsEmpty}
              height={"100%"}
              width={"50%"}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default ListOrdersCard;
