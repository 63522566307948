import "./index.scss";
import React, { useState } from "react";
import ScreenTitle from "../../components/page-title";
import { useNavigate, useParams } from "react-router-dom";
import getPizzasByName from "../../services/api/get-all-pizza-by-name";
import { PizzaSizeEnum } from "../../utils/Types/enum-size";
import { toast } from "react-toastify";
import Lottie from "react-lottie";
import { defaultOptionsLoading } from "../../assets/lottie-animations/animationOptions";

function PizzaDetailScreen() {
  const navigate = useNavigate();
  const { pizzaName } = useParams();
  const [pizzaSmall, setPizzaSmall] = useState<any>();
  const [pizzaMedium, setPizzaMedium] = useState<any>();
  const [pizzaLarge, setPizzaLarge] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [selectedSize, setSelectedSize] = useState("small");

  const getAllPizzaSizes = () => {
    setLoading(true);
    getPizzasByName(pizzaName)
      .then((response) => {
        setLoading(false);

        if (response.data.status === 500) {
          console.log("error serveur");
          return;
        }
        if (!response.data.success) {
          console.log(response.data.message);
          toast.error(response.data.message);
          return;
        }
        if (response.data.success) {
          const Small = response.data.content.pizzas.filter(
            (pizza) => pizza.size === PizzaSizeEnum.small
          );
          const Medium = response.data.content.pizzas.filter(
            (pizza) => pizza.size === PizzaSizeEnum.medium
          );
          const Large = response.data.content.pizzas.filter(
            (pizza) => pizza.size === PizzaSizeEnum.large
          );

          setPizzaSmall(Small[0]);
          setPizzaMedium(Medium[0]);
          setPizzaLarge(Large[0]);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
        console.log(error);
      });
  };

  React.useEffect(() => {
    getAllPizzaSizes();
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <ScreenTitle title="Détails" />
      {loading ? (
        <div>
          <Lottie
            options={defaultOptionsLoading}
            height={"80%"}
            width={"40%"}
          />
        </div>
      ) : (
        <div className="pizza-container">
          <div className="Image-container">
            <div className="image-wrapper">
              <div className="img-content-wrapper">
                <div className="Img-content-view">
                  <img
                    className="img-pzz-view"
                    src={`${process.env?.REACT_APP_SERVER_URL}/photo?pizza=${pizzaName}`}
                    alt="Pizza"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="Info-Container">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <h2>PIZZA {pizzaName.substring(4)}</h2>
              </div>
              <div
                style={{
                  height: 30,
                  width: 60,
                  backgroundColor: "#159B7B",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 20,
                  borderRadius: 4,
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/pizza/update/${pizzaName}`)}
              >
                <p style={{ color: "#fff", fontSize: 12, fontWeight: 700 }}>
                  Editer
                </p>
              </div>
            </div>
            <div style={{ marginTop: 20, marginLeft: 20 }}>
              <p>Ingrédients</p>
            </div>

            <div className="container">
              <div className="content">
                {pizzaMedium?.toppings?.map((topping, index) => (
                  <div key={index} className="item">
                    <p style={{ color: "#000", fontSize: 16, fontWeight: 700 }}>
                      {topping?.toppingName}
                    </p>
                  </div>
                ))}
              </div>
              <div style={{ padding: 20 }}>
                <h3 style={{ color: "#159B7B" }}>Dosage</h3>
              </div>
              <div className="dosage-row">
                <div
                  className="dosage-item"
                  onClick={() => setSelectedSize("small")}
                >
                  <p
                    style={{
                      color: selectedSize === "small" ? "#000" : "#B6B6B6",
                    }}
                  >
                    Small
                  </p>
                  {selectedSize === "small" && (
                    <div
                      style={{
                        width: "100%",
                        borderWidth: 6,
                        height: 3,
                        backgroundColor: "#159B7B",
                      }}
                    />
                  )}
                </div>
                <div
                  className="dosage-item"
                  onClick={() => setSelectedSize("medium")}
                >
                  <p
                    style={{
                      color: selectedSize === "medium" ? "#000" : "#B6B6B6",
                    }}
                  >
                    Medium
                  </p>
                  {selectedSize === "medium" && (
                    <div
                      style={{
                        width: "100%",
                        borderWidth: 6,
                        height: 3,
                        backgroundColor: "#159B7B",
                      }}
                    />
                  )}
                </div>
                <div
                  className="dosage-item"
                  onClick={() => setSelectedSize("large")}
                >
                  <p
                    style={{
                      color: selectedSize === "large" ? "#000" : "#B6B6B6",
                    }}
                  >
                    Large
                  </p>
                  {selectedSize === "large" && (
                    <div
                      style={{
                        width: "100%",
                        borderWidth: 6,
                        height: 3,
                        backgroundColor: "#159B7B",
                      }}
                    />
                  )}
                </div>
              </div>
              {selectedSize === "small" ? (
                <div className="content-ing">
                  {pizzaSmall?.toppings.length > 0 ? (
                    pizzaSmall?.toppings?.map((topping, index) => (
                      <div key={index} className="item-ingrediants">
                        <p
                          style={{
                            color: "#000",
                            fontSize: 16,
                            fontWeight: 700,
                          }}
                        >
                          {topping?.quantity} X {topping?.toppingName}
                        </p>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              ) : selectedSize === "medium" ? (
                <div className="content-ing">
                  {pizzaMedium?.toppings.length > 0 ? (
                    pizzaMedium?.toppings?.map((topping, index) => (
                      <div key={index} className="item-ingrediants">
                        <p
                          style={{
                            color: "#000",
                            fontSize: 16,
                            fontWeight: 700,
                          }}
                        >
                          {topping?.quantity} X {topping?.toppingName}
                        </p>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div className="content-ing">
                  {pizzaLarge?.toppings.length > 0 ? (
                    pizzaLarge?.toppings?.map((topping, index) => (
                      <div key={index} className="item-ingrediants">
                        <p
                          style={{
                            color: "#000",
                            fontSize: 16,
                            fontWeight: 700,
                          }}
                        >
                          {topping?.quantity} X {topping?.toppingName}
                        </p>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PizzaDetailScreen;
