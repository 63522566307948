import "./index.scss";

import React from "react";

import { ReactComponent as CloseIcon } from "../../../../assets/icons/close-icon.svg";

import { ReactComponent as BackIcon } from "../../../../assets/icons/back-icon.svg";

import getHistoryDataByOrderId from "../../../../services/api/get-history-by-order-id";

import { toast } from "react-toastify";

import OrderTrackingBody from "./order-tracking-body";

import { OrderHistoryType } from "./helpers/order-history-type";

import transformOrderHistory from "./helpers/transformOrderHistory";

import Lottie from "react-lottie";

import animationData from "../../../../assets/lottie-animations/loading.json";
import {
  getSocketInstance,
  useSocket,
  useSocketConfig,
} from "../../../../services/socket";

type SlideOutPanelOrderTrackingPagePropsType = {
  setActivePage: (
    arg: "orderDetailsPage" | "InvoicePage" | "OrderTrackingPage"
  ) => void;
  onClose: Function;
  orderNumber: string;
  orderId: string;
};

function SlideOutPanelOrderTrackingPage({
  setActivePage,
  onClose,
  orderNumber,
  orderId,
}: SlideOutPanelOrderTrackingPagePropsType): JSX.Element {
  const socket = getSocketInstance();

  const [historyData, setHistoryData] = React.useState<OrderHistoryType>();

  const [loadingHistoryData, setLoadingHistoryData] =
    React.useState<boolean>(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  React.useEffect(() => {
    setLoadingHistoryData(true);
    getHistoryDataByOrderId(orderId).then((res) => {
      setLoadingHistoryData(false);
      if (!res?.data?.success) {
        toast.error(
          "Erreur lors de la récupération des données de l'historique de commande : " +
            res?.data?.message
        );
        return;
      }
      setHistoryData(res?.data?.content?.order);
    }).catch((error) => {
      toast.error(error)
    })
    return () => {
      setLoadingHistoryData(false);
    };
  }, []);

  React.useEffect(() => {
    socket.on("OrderStatusHistoryUpdate", (data: OrderHistoryType) => {
      toast(`L'ordre N°${data?.orderNumber} à été mis à jour`);
      if (data?.orderId === historyData?.orderId) {
        setHistoryData((prevData) => {
          return { ...prevData, status: data?.status };
        });
      }
    });
    return () => {};
  }, []);

  return (
    <>
      <div className="header">
        <div
          onClick={() => setActivePage("orderDetailsPage")}
          className="header-icon"
        >
          <BackIcon width={15} height={15} />
        </div>
        <div className="order-tracking-title">Suivi de commande</div>
        <div className="header-icon" onClick={() => onClose()}>
          <CloseIcon width={15} height={15} />
        </div>
      </div>

      <div className="content-slideout">
        <p>N°{orderNumber}</p>

        {loadingHistoryData ? (
          <div className="loader-container">
            <Lottie options={defaultOptions} height={100} width={100} />
          </div>
        ) : (
          transformOrderHistory(historyData)?.status?.map((data, index) => {
            return (
              <React.Fragment key={index}>
                <OrderTrackingBody data={data} />
              </React.Fragment>
            );
          })
        )}
      </div>
    </>
  );
}

export default SlideOutPanelOrderTrackingPage;
