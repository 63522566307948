import "./index.scss";

import React from "react";

import ScreenTitle from "../../components/page-title";

import { ReactComponent as FilterIcon } from "../../assets/icons/filter-inactive.svg";
import { ReactComponent as FilterActiveIcon } from "../../assets/icons/filter.svg";
import { ReactComponent as CloseFilter } from "../../assets/icons/close-filters.svg";
import { ReactComponent as DownArrow } from "../../assets/icons/down-arrow-icon.svg";
import { ReactComponent as CloseFilterItem } from "../../assets/icons/close-filter-item.svg";
import { ReactComponent as DbSearchIcon } from "../../assets/icons/db-search.svg";

import getAllOrderData from "../../services/api/get-all-orders-data";

import { OrderDataTypes } from "../../utils/Types/OrdersDataTypes";

import ordersTableHeaderTitles, {
  HeaderTitleType,
} from "./utils/orders-table-header-titles";

import SlideOutPanel from "./slideout-panel";

import { FilterType } from "./utils/allHeadersFilterTypes";

import getSortFunction from "./utils/getSortFunction";

import { useSelector, useStore } from "react-redux";
import {
  OMS_ORDERS_ADD_ALL_ORDERS,
  OMS_ORDERS_DELETE_ORDER,
  OMS_ORDERS_EDIT_ORDER,
  OMS_ORDERS_UPDATE_ORDERLINE,
  OMS_ORDERS_UPDATE_RUNNER,
} from "../../store/slices/reducers/omsOrders";

import { useSelectorStateType } from "../../store/types/useSelectorStateType";

import { toast } from "react-toastify";

import Lottie from "react-lottie";

import loaderFile from "../../assets/lottie-animations/loading.json";

import getAllOrdersHistory from "../../services/api/get-all-order-history";

import searchOrders from "./utils/search-orders";

import { OrderHistoryType } from "./slideout-panel/order-tracking-page/helpers/order-history-type";

import OrderRowData from "./order-row-data";

import mockOrder from "./utils/mockOrder";
import getDataByFilter from "../../services/api/get-data-by-filter";

import orderInsertedSound from "../../assets/orderInserted.mp3";
import orderDeleted from "../../assets/orderDeleted.mp3";
import orderUpdated from "../../assets/orderUpdated.mp3";
import getRunnerByPhoneNumber from "../../services/api/get-runner-by-phoneNumber";
import moment from "moment";
import getOrderLineByOrderId from "../../services/api/get-order-line-by-order-id";
import getAllActiveRunners from "../../services/api/get-all-active-runners";
import {
  OMSS_SET_LOADING_ACTIVE_RUNNERS,
  OMSS_UNSET_LOADING_ACTIVE_RUNNERS,
  OMS_ADD_ALL_ACTIVE_RUNNERS,
  OMS_DELETE_ACTIVE_RUNNERS,
  OMS_EDIT_ACTIVE_RUNNERS,
} from "../../store/slices/reducers/omsActiveRunners";
import {
  getSocketInstance,
  useSocket,
  useSocketConfig,
} from "../../services/socket";

type sortSettingsType = {
  column: FilterType;
  isDesc: boolean;
};

function OrdersPage(): JSX.Element {
  const socket = getSocketInstance();

  const store = useStore();

  const allOrders: OrderDataTypes[] =
    useSelector(
      (state: useSelectorStateType) => state?.entities?.omsOrders?.allOrders
    ) || [];

  let ordersData = [...allOrders];

  const [sortSettings, setSortSettings] = React.useState<sortSettingsType>({
    column: "orderDate",
    isDesc: true,
  });

  const [serchedData, setSerchedData] = React.useState<OrderDataTypes[]>();

  const [filtredDataFromDB, setFiltredDataFromDB] =
    React.useState<OrderDataTypes[]>();

  const [isFiltredFromDB, setIsFiltredFromDB] = React.useState<boolean>(false);

  const [ordersHistory, setOrdersHistory] =
    React.useState<OrderHistoryType[]>();

  const [searchString, setSearchString] = React.useState<string>("");

  const [isFilterActivated, setIsFilterActivated] =
    React.useState<boolean>(false);

  const [loadingOrdersData, setLoadingOrdersData] =
    React.useState<boolean>(false);

  const [showPanel, setShowPanel] = React.useState<boolean>(false);

  const [panelData, setPanelData] = React.useState<OrderDataTypes>();
  console.log("panel " , panelData);
  
  const [inputValues, setInputValues] = React.useState({});

  const [selectedFields, setSelectedFields] = React.useState<string[]>([]);

  const [notSelectedFields, setNotSelectedFieldss] = React.useState<string[]>(
    Object.keys(mockOrder)
  );

  const [loadingGetRunnerData, setLoadingGetRunnerData] =
    React.useState<boolean>(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderFile,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  //********************************************** ON ROW CLICK **********************************/
  const handleRowClick = (rowId: string) => {
    setShowPanel(false);
    const rowData = ordersData.find((row) => row.id === rowId);
    setShowPanel(true);
    setPanelData(rowData);
  };

  //********************************************** Handle select field for filter in db **********************************/
  function handleSelectChange(event) {
    const selectedField = event.target.value;
    setSelectedFields((prev) => [...prev, selectedField]);
    setNotSelectedFieldss((previousData) =>
      previousData.filter((oneItem) => oneItem !== selectedField)
    );
    setInputValues((prev) => {
      return { ...prev, [selectedField]: null };
    });
  }

  //********************************************** Handle delete  field from  filter list **********************************/
  function handleDeleteFilter(filterChamp: string) {
    setSelectedFields((prev) =>
      prev.filter((oneItem) => oneItem !== filterChamp)
    );
    setNotSelectedFieldss((previousData) => [...previousData, filterChamp]);

    setInputValues((prevState) => {
      const newInputValues = { ...prevState };
      delete newInputValues[filterChamp];
      return newInputValues;
    });
  }

  //********************************************** Handle delete  all orders  **********************************/
  function deleteAllFilters() {
    setSelectedFields([]);
    setNotSelectedFieldss(Object.keys(mockOrder));
    setIsFilterActivated(false);
    setInputValues({});
    setIsFiltredFromDB(false);
  }

  //********************************************** Confirm filter from db  **********************************/
  async function validateFilterFromDB() {
    try {
      setLoadingOrdersData(true);
      const res = await getDataByFilter(inputValues);
      if (!res?.data?.success) {
        throw new Error(res?.data?.message);
      }
      setLoadingOrdersData(false);
      setFiltredDataFromDB(res?.data?.content?.Orders);
      setIsFiltredFromDB(true);
      console.log(res);
      toast(`Filtre de données succés`);
    } catch (error) {
      setLoadingOrdersData(false);
      toast.error(`Erreur dans le filtre des données ${error}`);
    }
  }

  //********************************************** GET ALL ORDER HISTORY **********************************/
  React.useEffect(() => {
    (async () => {
      try {
        setLoadingOrdersData(true);
        const res = await getAllOrdersHistory();

        if (!res?.data?.success) {
          throw new Error(res?.data?.message);
        }
        setOrdersHistory(res?.data?.content?.orders);
        setLoadingOrdersData(false);
      } catch (error) {
        setLoadingOrdersData(false);
        toast.error(
          "Erreur lors de la récupération de l'histoaiue toutes les commandes : " +
            error
        );
      }
    })();
  }, []);

  //********************************************** GET ALL ORDERS + Add to store **********************************/
  React.useEffect(() => {
    (async () => {
      try {
        setLoadingOrdersData(true);
        const res = await getAllOrderData();
        if (!res?.data?.success) {
          throw new Error(res?.data?.message);
        }

        store.dispatch({
          type: OMS_ORDERS_ADD_ALL_ORDERS,
          payload: res?.data.content?.Orders,
        });

        setLoadingOrdersData(false);
      } catch (error) {
        setLoadingOrdersData(false);

        toast.error(
          "Erreur lors de la récupération de toutes les commandes : " + error
        );
      }
    })();
  }, []);

  //********************************************** ORDERS SOCKET LISTENNING + update data in store **********************************/
  React.useEffect(() => {
    // Stop the audio after 2 seconds

    socket.on(
      "orderUpdate",
      (data: { fullDocument: OrderDataTypes; updatedField: any }) => {
        toast(`L'ordre N°${data?.fullDocument?.orderNumber} à été mis à jour`);

        if (isFiltredFromDB) validateFilterFromDB();
        const audio = new Audio(orderUpdated);
        audio.play();
        setTimeout(() => {
          audio.pause();
          audio.currentTime = 0;
        }, 2000);
        store.dispatch({
          type: OMS_ORDERS_EDIT_ORDER,
          payload: {
            ...data?.fullDocument,
            CreationDate: moment(data?.fullDocument?.createdAt)
              .format("DD/MM/YYYY HH:mm:ss")
              ?.toString(),
          },
        });
        if (data?.updatedField?.deliveredBy?.length) {
          setLoadingGetRunnerData(true);
          getRunnerByPhoneNumber(data?.updatedField?.deliveredBy).then(
            (res) => {
              setLoadingGetRunnerData(false);

              store.dispatch({
                type: OMS_ORDERS_UPDATE_RUNNER,
                payload: {
                  id: data?.fullDocument?.id,
                  Runner: { ...res.data.content },
                },
              });
            }
          );
        } else {
          store.dispatch({
            type: OMS_ORDERS_UPDATE_RUNNER,
            payload: {
              id: data?.fullDocument?.id,
              Runner: {},
            },
          });
        }
      }
    );

    socket.on("orderInsert", (data: OrderDataTypes) => {
      if (isFiltredFromDB) validateFilterFromDB();
      toast(`L'ordre N°${data?.orderNumber} à été ajouté`);

      const audio = new Audio(orderInsertedSound);
      audio.play();
      setTimeout(() => {
        audio.pause();
        audio.currentTime = 0;
      }, 2000);
      store.dispatch({
        type: OMS_ORDERS_EDIT_ORDER,
        payload: {
          ...data,
          CreationDate: moment(data?.createdAt)
            .format("DD/MM/YYYY HH:mm:ss")
            ?.toString(),
        },
      });

      getOrderLineByOrderId(data.id).then((res) => {
        store.dispatch({
          type: OMS_ORDERS_UPDATE_ORDERLINE,
          payload: {
            id: data?.id,
            orderLine: { ...res.data.content.orderLine },
          },
        });
      });
    });

    socket.on("orderDelete", (data: OrderDataTypes) => {
      toast.error(`L'ordre ${data} à été supprimé`);
      if (isFiltredFromDB) validateFilterFromDB();
      const audio = new Audio(orderDeleted);
      audio.play();
      setTimeout(() => {
        audio.pause();
        audio.currentTime = 0;
      }, 2000);
      store.dispatch({
        type: OMS_ORDERS_DELETE_ORDER,
        payload: data,
      });
    });
    return () => {};
  }, [socket, ordersData.length]);

  //********************************************** ORDER HISTORY SOCKET LISTENNING + update history data  **********************************/
  React.useEffect(() => {
    socket.on("OrderStatusHistoryInsert", (data: OrderHistoryType) => {
      setOrdersHistory((prevData) => {
        return [...prevData, data];
      });
    });
    socket.on("OrderStatusHistoryUpdate", (data: OrderHistoryType) => {
      toast.info(
        `Le status de l'ordre N°${data?.orderNumber} à été mis à jour`
      );
      setOrdersHistory((prevData) => {
        const filtredData = prevData.filter(
          (orderHistory) => orderHistory?.orderId !== data?.orderId
        );
        return [...filtredData, data];
      });
    });

    return () => {};
  }, [socket]);

  //********************************************** GET ALL ACTIVE RUNNERS **********************************/
  React.useEffect(() => {
    (async () => {
      try {
        store.dispatch({
          type: OMSS_SET_LOADING_ACTIVE_RUNNERS,
        });

        const res = await getAllActiveRunners();

        if (!res?.data?.success) {
          throw new Error(res?.data?.message);
        }

        store.dispatch({
          type: OMS_ADD_ALL_ACTIVE_RUNNERS,
          payload: res?.data?.content?.runners,
        });

        store.dispatch({
          type: OMSS_UNSET_LOADING_ACTIVE_RUNNERS,
        });
      } catch (error) {
        store.dispatch({
          type: OMSS_UNSET_LOADING_ACTIVE_RUNNERS,
        });
        toast.error(
          "Erreur lors de la récupération de la listes des courreurs : " + error
        );
      }
    })();
  }, []);

  //********************************************** RUNNERS SOCKET LISTENNING + update active runners data  **********************************/
  React.useEffect(() => {
    socket.on("RunnerUpdate", (data: any) => {
      if (data?.isActive) {
        toast(
          `Un nouveau courreur est disponible "${data?.Name} ${data?.LastName}" `
        );
        store.dispatch({
          type: OMS_EDIT_ACTIVE_RUNNERS,
          payload: {
            id: data?._id,
            ...data,
          },
        });
      } else {
        toast.error(
          `Le courreir  "${data?.Name} ${data?.LastName}" n'est plus disponible`
        );
        store.dispatch({
          type: OMS_DELETE_ACTIVE_RUNNERS,
          payload: data?._id,
        });
      }
    });

    socket.on("RunnerDelete", (data: any) => {
      toast.error(`Un courreur à été supprimer`);
      store.dispatch({
        type: OMS_DELETE_ACTIVE_RUNNERS,
        payload: data,
      });
    });
    return () => {};
  }, []);

  return (
    <>
      <ScreenTitle title="Commandes" />
      <div className="order-page-container">
        <div className="search-container">
          <form onSubmit={(e) => e.preventDefault()} style={{minWidth : 140}}>
            <input
              type="search"
              placeholder=""
              onChange={(e) => {
                setSearchString(e.target.value);
                if (e.target.value.length >= 1) {
                  setSerchedData(
                    searchOrders(
                      e.target.value,
                      isFiltredFromDB ? filtredDataFromDB : ordersData
                    )
                  );
                }
              }}
            />
            <button type="submit">Search</button>
          </form>
          <button
            style={{
              marginLeft: 5,
              cursor: "pointer",
              borderWidth: 0,
              background: "transparent",
            }}
            onClick={() => {
              if (isFilterActivated) {
                deleteAllFilters();
                setIsFiltredFromDB(false);
              } else {
                setIsFilterActivated((prev: boolean) => !prev);
              }
            }}
          >
            {!isFilterActivated ? <FilterIcon /> : <FilterActiveIcon />}
          </button>
          {isFilterActivated ? (
            <>
              <div>
                <select
                  className="select-container"
                  onChange={handleSelectChange}
                  value={"first"}
                  disabled={notSelectedFields.length === 0}
                >
                  <option value={"first"} disabled>
                    Champs
                  </option>

                  {notSelectedFields.map((key) => {
                    return (
                      <option
                        value={key}
                        disabled={selectedFields.includes(key)}
                        key={key}
                      >
                        {key}
                      </option>
                    );
                  })}
                </select>
              </div>

              <button
                className="confirm-filter-button"
                onClick={(e) => {
                  validateFilterFromDB();
                }}
              >
                <DbSearchIcon />
              </button>
            </>
          ) : null}
        </div>
        {isFilterActivated && (
          <>
            <div className="filters-title">
              <p>Filters </p>
              <CloseFilter
                style={{ marginLeft: "1%" }}
                onClick={deleteAllFilters}
              />
              <div className="filter-line"></div>
            </div>
            <form onSubmit={(e) => e.preventDefault()}>
              {selectedFields.length
                ? selectedFields.map((field, index) => {
                    return (
                      <div className="filters-item" key={field}>
                        <CloseFilterItem
                          style={{ marginRight: "1%" }}
                          onClick={() => handleDeleteFilter(field)}
                        />
                        <p>{field} </p>
                        <input
                          type="text"
                          className="filter-input"
                          onChange={(e) => {
                            setInputValues((prev) => {
                              return { ...prev, [field]: e.target.value };
                            });
                          }}
                        />
                      </div>
                    );
                  })
                : null}
            </form>
          </>
        )}
        <table>
          <thead>
            <tr>
              {ordersTableHeaderTitles.map(
                (headersTitle: HeaderTitleType, index: number) => {
                  return (
                    <th
                      style={{
                        cursor:
                          headersTitle.title !== "Delais"
                            ? "pointer"
                            : "no-drop",
                      }}
                      key={index}
                      onClick={() => {
                        headersTitle.title !== "Delais" &&
                          setSortSettings({
                            column: headersTitle.filterName,
                            isDesc: !sortSettings.isDesc,
                          });
                      }}
                    >
                      {loadingGetRunnerData &&
                      headersTitle.title === "Runner" ? (
                        <>Loading ...</>
                      ) : (
                        <div className="th-row">
                          {headersTitle.title}
                          {sortSettings.column === headersTitle.filterName &&
                          headersTitle.title !== "Delais" ? (
                            sortSettings.isDesc ? (
                              <div className="filter-icon-header">
                                <DownArrow width={40} height={5} />
                              </div>
                            ) : (
                              <div className="filter-icon-header">
                                <DownArrow
                                  width={40}
                                  height={5}
                                  style={{ transform: "rotate(180deg)" }}
                                />
                              </div>
                            )
                          ) : null}
                        </div>
                      )}
                    </th>
                  );
                }
              )}
            </tr>
          </thead>
          <tbody>
            {loadingOrdersData ? (
              <tr>
                <td className="loader-container" rowSpan={20} colSpan={9}>
                  <Lottie options={defaultOptions} height={200} width={200} />
                </td>
              </tr>
            ) : (searchString.length >= 1 && serchedData.length === 0) ||
              (isFiltredFromDB && filtredDataFromDB.length === 0) ||
              (!isFiltredFromDB && ordersData.length === 0) ? (
              <tr>
                <td className="no-item-container" rowSpan={20} colSpan={9}>
                  Ouuup ! Rien à voir
                </td>
              </tr>
            ) : (
              (searchString.length >= 1
                ? serchedData.sort(getSortFunction(sortSettings))
                : (isFiltredFromDB ? filtredDataFromDB : ordersData).sort(
                    getSortFunction(sortSettings)
                  )
              )?.map((row: OrderDataTypes) => {
                console.log("row" , row);
                
                return (
                  <React.Fragment key={row.id}>
                    <OrderRowData
                      handleRowClick={handleRowClick}
                      ordersHistory={ordersHistory}
                      panelData={panelData}
                      row={row}
                      loadingGetRunnerData={loadingGetRunnerData}
                    />
                  </React.Fragment>
                );
              })
            )}
          </tbody>
        </table>
        {showPanel ? (
          <SlideOutPanel
            data={panelData}
            onClose={() => {
              setPanelData(undefined);
              setShowPanel(false);
            }}
            showPanel={showPanel}
          />
        ) : null}
      </div>
    </>
  );
}

export default OrdersPage;
