import "./index.scss";

import ScreenTitle from "../../components/page-title";
import PizzaCard from "../../components/cards/PizzaCard";
import getAllPizzaData from "../../services/api/get-all-pizza-data";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Pizza } from "../../utils/Types/PizzaDataTypes";
import Lottie from "react-lottie";
import {
  defaultOptionsEmpty,
  defaultOptionsLoading,
} from "../../assets/lottie-animations/animationOptions";

function PizzaScreen() {
  const [pizzaList, setPizzaList] = useState<Pizza[]>([]);
  const [loading, setLoading] = useState(false);
  const getAllPizzas = () => {
    setLoading(true);
    getAllPizzaData()
      .then((response) => {
        setLoading(false);
        if (response?.data?.status === 500) {
          toast.error("Server error");
          return;
        }
        if (!response?.data?.success) {
          toast.error("error" + response.data.message);

          console.log("error");
          return;
        }
        if (response?.data?.success) {
          const targetElement = "PZZ_Start";
          const index = response.data.content.pizzas.findIndex(
            (item) => item.name === targetElement
          );

          if (index > -1) {
            const [removed] = response.data.content.pizzas.splice(index, 1);
            response.data.content.pizzas.unshift(removed);
          }
          setPizzaList(response?.data?.content?.pizzas);
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error("error" + error);
      });
  };
  useEffect(() => {
    getAllPizzas();
  }, []);

  return (
    <div>
      <ScreenTitle title="Pizzas" getAllPizzas={getAllPizzas} />
      {loading ? (
        <div className="pizza-container-list">
          <div className="pzz-animation-container">
            <Lottie
              options={defaultOptionsLoading}
              height={120}
              width={120}
            />
          </div>
        </div>
      ) : (
        <div className="pizza-container-list">
          {pizzaList.length > 0 ? (
            pizzaList.map((pizza: Pizza, index: any) => (
              <PizzaCard key={index} pizza={pizza} />
            ))
          ) : (
            <div className="pzz-animation-container">
              <Lottie
                options={defaultOptionsEmpty}
                height={"40%"}
                width={"40%"}
              />
              <h2 style={{ color: "#fd6644" }}>La liste de pizza est vide</h2>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default PizzaScreen;
