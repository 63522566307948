import { patchRequest, postRequest } from "../../utils/axios";

type DataFromAxiosType = {
  data: {
    success: boolean;
    message: string;
    status: number;
    content: Record<string, any>;
  };
};

async function updateToppingPhotos(bodyData , topping): Promise<DataFromAxiosType> {
  try {
    const { data }: DataFromAxiosType = await patchRequest(
      `/photos-topping/${topping}`,
      bodyData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (!data.success) {
      throw new Error(data?.message);
    }

    return {
      data,
    };
  } catch (error: any) {
    const returnedData: DataFromAxiosType = {
      data: {
        success: false,
        message: error?.message ?? "",
        status: error?.response?.status ?? 500,
        content: [],
      },
    };
    return returnedData;
  }
}

export default updateToppingPhotos;
