import "./index.scss";
import React from "react";

import mapPin from "../../../assets/icons/map-pin-icon.svg";

import { GoogleMap, Marker } from "@react-google-maps/api";

function HomeScreenMapView() {
  const center = React.useMemo(() => ({ lat: 35.841015, lng: 10.589049 }), []);
  const mapOptions = {
    disableDefaultUI: true,
  };

  return (
    <GoogleMap
      clickableIcons={false}
      zoom={15}
      center={center}
      mapContainerClassName="map-container"
    >
      <Marker
        position={center}
        visible={true}
        zIndex={1000}
        cursor="pointer"
        title="heloooooooooooooooooooooooo"
      />
    </GoogleMap>
  );
}

export default HomeScreenMapView;
