// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pzz-extra-card-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.pzz-extra-card-body {
  display: flex;
  flex-direction: row;
  padding: 5px;
}
.pzz-extra-card-body .pzz-extra-card-body-wrapper {
  width: 30%;
  height: 80px;
}

.lottie-wrapper {
  height: 100%;
  width: 100%;
  background-color: #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-area-wrapper {
  width: 70%;
  height: 50px;
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/ExtraCard/index.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;AAEF;AADE;EACE,UAAA;EACA,YAAA;AAGJ;;AACA;EACE,YAAA;EACA,WAAA;EACA,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAEF;;AACA;EACE,UAAA;EACA,YAAA;EACA,YAAA;AAEF","sourcesContent":[".pzz-extra-card-content {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n.pzz-extra-card-body {\n  display: flex;\n  flex-direction: row;\n  padding: 5px;\n  .pzz-extra-card-body-wrapper {\n    width: 30%;\n    height: 80px;\n  }\n}\n\n.lottie-wrapper {\n  height: 100%;\n  width: 100%;\n  background-color: #e2e2e2;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.text-area-wrapper {\n  width: 70%;\n  height: 50px;\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
