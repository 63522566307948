// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-extra-container {
  height: 100%;
  width: 100%;
}
.list-extra-container .btn-new-extra-container {
  min-width: 70px;
  width: 15%;
  height: 40px;
  min-height: 35px;
  border-width: 0px;
  background-color: #159b7b;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 10px;
}
.list-extra-container .btn-new-extra-container .btn-new-extra {
  background-color: #159b7b;
  border-width: 0px;
  color: #fff;
}
.list-extra-container .store-container .loader-container {
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.list-extra-container .store-container .empty-lottie-container {
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/components/ListExtras/index.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;AACF;AAAE;EACE,eAAA;EACA,UAAA;EACA,YAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,6BAAA;EACA,gBAAA;EACA,mBAAA;AAEJ;AADI;EACE,yBAAA;EACA,iBAAA;EACA,WAAA;AAGN;AACI;EACE,WAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AACN;AACI;EACE,WAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AACN","sourcesContent":[".list-extra-container {\n  height: 100%;\n  width: 100%;\n  .btn-new-extra-container {\n    min-width: 70px;\n    width: 15%;\n    height: 40px;\n    min-height: 35px;\n    border-width: 0px;\n    background-color: #159b7b;\n    color: #fff;\n    border-radius: 4px;\n    padding: 5px;\n    display: flex;\n    justify-content: space-around;\n    margin-top: 10px;\n    margin-bottom: 10px;\n    .btn-new-extra {\n      background-color: #159b7b;\n      border-width: 0px;\n      color: #fff;\n    }\n  }\n  .store-container {\n    .loader-container {\n      height: 60%;\n      width: 100%;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      flex-direction: column;\n    }\n    .empty-lottie-container {\n      height: 60%;\n      width: 100%;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      flex-direction: column;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
