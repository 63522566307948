import { DefaultEventsMap } from "@socket.io/component-emitter";
import React from "react";

import { Socket, io } from "socket.io-client";

const socketEvents = {
  connect: () => {
    console.log("connected");
  },
  disconnect: () => {
    console.log("disconnected");
  },
  connect_error: (error: any) => {
    console.log("connect_error", error);
  },
  connect_timeout: (timeout: any) => {
    console.log("connect_timeout", timeout);
  },
  error: (error: any) => {
    console.log("error", error);
  },
  reconnect: (attemptNumber: any) => {
    console.log("reconnect", attemptNumber);
  },
  reconnect_attempt: (attemptNumber: any) => {
    console.log("reconnect_attempt", attemptNumber);
  },
  reconnecting: (attemptNumber: any) => {
    console.log("reconnecting", attemptNumber);
  },
  reconnect_error: (error: any) => {
    console.log("reconnect_error", error);
  },
  reconnect_failed: () => {
    console.log("reconnect_failed");
  },
  ping: () => {
    console.log("ping");
  },
  pong: (latency: any) => {
    console.log("pong", latency);
  },
};

const socketInstance = Object.create({
  socket: null,
});

// create socketInstance setter and getter
function setSocketInstance(value: Socket<DefaultEventsMap, DefaultEventsMap>) {
  socketInstance.socket = value;
}
export function getSocketInstance(params?: undefined) {
  return socketInstance.socket;
}

const useSocketConfig = ({
  host = process.env?.REACT_APP_SOCKET_URL || "http://192.168.1.92:5000",
  config = {},
  init = false,
} = {}): any => {
  if (init) {
    const socket = io(host, {
      autoConnect: false,
      ...config,
    });
    setSocketInstance(socket);
  }

  return getSocketInstance();
};

const useSocket = ({ socket, customSocketEvents = {} }: any = {}) => {
  React.useEffect(() => {
    if (!socket) {
      return;
    }
    if (!socket.connected) {
      if (typeof socket.connect === "function") {
        socket.connect();
      }
    }
    return () => {
      if (socket.connected) {
        socket.disconnect();
      }
    };
  }, [socket]);

  React.useEffect(() => {
    if (!socket) {
      return;
    }
    Object.entries({ ...socketEvents, ...customSocketEvents }).forEach(
      ([eventName, event]) => {
        socket.on(eventName, event);
      }
    );
    return () => {
      Object.entries({ ...socketEvents, ...customSocketEvents }).forEach(
        ([eventName, event]) => {
          socket.off(eventName, event);
        }
      );
    };
  }, [customSocketEvents, socket]);

  return socket;
};

export { useSocketConfig, useSocket };
