import { defaultOptionsLoading } from "../../assets/lottie-animations/animationOptions";
import { Topping } from "../../utils/Types/Topping";
import "./index.scss";
import Lottie from "react-lottie";

interface ingredientImagesType {
  photo1: string;
  photo2: string;
  photo3: string;
  photo4: string;
  icon: string;
}
interface initialImageStateType {
  selectedImage: Blob | MediaSource;
  imageUrl: string;
}

interface ingincType {
  value: number;
  name: string;
}
interface props {
  images: initialImageStateType[];
  handleFileChangeIcon: (
    arg1: React.ChangeEvent<HTMLInputElement>,
    arg2: string
  ) => Topping | null;
  setImages: (args: Function) => void;
  toUpdateIng: Topping;
  ingredientImages: ingredientImagesType;
  imginc: ingincType;
  loadingImages: any;
}

const AnimationIconComponent = ({
  images,
  handleFileChangeIcon,
  setImages,
  toUpdateIng,
  ingredientImages,
  imginc,
  loadingImages,
}: props) => {
  return (
    <div className="animation-icon-container">
      <div className="ai-image-upload-section">
        {images[imginc?.value].selectedImage ? (
          <div className="ai-selected-img-wrapper">
            <div className="ai-img-wrapper">
              <img
                src={images[imginc?.value].imageUrl}
                alt="Selected"
                className="img-style"
              />
            </div>
            <div className="ai-input-image-container">
              <div className="ai-input-image-wrapper">
                <input
                  type="file"
                  id="file-upload-first"
                  accept=".jpg,.gif,.png"
                  className="hidden-input"

                  multiple
                  onChange={(e) => handleFileChangeIcon(e, imginc?.name)}
                />
                <label
                  className="ai-label-input-style"
                  htmlFor={`file-upload-${imginc?.value}`}
                >
                  <i
                    style={{
                      fontSize: 25,
                      color: "#000",
                      alignSelf: "center",
                      textAlign: "center",
                    }}
                    className="bx bx-repost"
                  ></i>
                </label>
              </div>
              <div
                className="ai-input-image-wrapper"
                onClick={() =>
                  setImages((prevImages) => {
                    const updatedImages = [...prevImages];
                    updatedImages[imginc.value].selectedImage = null;
                    updatedImages[imginc.value].imageUrl = null;
                    return updatedImages;
                  })
                }
              >
                <i className="bx bx-trash"></i>
              </div>
            </div>
          </div>
        ) : (
          <>
            {Boolean(toUpdateIng) ? (
              <div className="ai-selected-img-wrapper">
                <div className="ai-img-wrapper">
                  {loadingImages ? (
                    <div className="lottie-container">
                      <Lottie
                        options={defaultOptionsLoading}
                        height={"50%"}
                        width={"80%"}
                      />
                    </div>
                  ) : (
                    <img
                      src={
                        imginc?.value === 1
                          ? `data:image/*;base64,${ingredientImages?.photo1}`
                          : imginc?.value === 2
                          ? `data:image/*;base64,${ingredientImages?.photo2}`
                          : imginc?.value === 3
                          ? `data:image/*;base64,${ingredientImages?.photo3}`
                          : `data:image/*;base64,${ingredientImages?.photo4}`
                      }
                      alt="Selected"
                      className="ai-image-in-edit-stl"
                    />
                  )}
                </div>
                <div className="ai-input-image-container">
                  <div className="ai-input-image-wrapper">
                    <input
                      type="file"
                      id={`file-upload-${imginc?.name}`}
                      accept=".jpg,.gif,.png"
                      multiple
                      className="hidden-input"
                      onChange={(e) => handleFileChangeIcon(e, imginc?.name)}
                    />
                    <label
                      className="ai-label-input-style"
                      htmlFor={`file-upload-${imginc?.name}`}
                    >
                      <i
                        style={{
                          fontSize: 25,
                          color: "#000",
                          alignSelf: "center",
                          textAlign: "center",
                        }}
                        className="bx bx-repost"
                      ></i>
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <div className="custom-file-upload-first">
                <input
                  type="file"
                  id={`file-upload-${imginc?.name}`}
                  accept=".jpg,.gif,.png"
                  className="hidden-input"

                  multiple
                  onChange={(e) => handleFileChangeIcon(e, imginc?.name)}
                />
                <label
                  className="ai-label-input-style"
                  htmlFor={`file-upload-${imginc?.name}`}
                >
                  <img
                    src={require("./../../assets/uploadImg.png")}
                    alt="imgupload"
                    style={{ height: "100%", width: "100%" }}
                  />
                </label>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AnimationIconComponent;
