// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orders-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media (max-width: 470px) {
  .orders-container {
    flex-direction: column;
  }
}
@media (min-width: 471px) and (max-width: 1200px) {
  .orders-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
@media (min-width: 1201px) {
  .orders-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
.pzz-store-detail-container {
  height: 100vh;
  width: 100%;
  margin-top: 25px;
  padding: 20px;
}
.pzz-store-detail-container .pzz-store-detail-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.pzz-store-detail-container .pzz-store-detail-header .pzz-btn-edit {
  border-width: 0px;
  background-color: #159b7b;
  padding: 8px;
  color: #fff;
  border-radius: 4px;
  width: 74px;
}
.pzz-store-detail-container .pzz-store-detail-head {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
}
.pzz-store-detail-container .pzz-store-detail-map-container {
  height: 30vh;
  width: 40%;
  min-width: 40vw;
  margin-top: 25px;
}`, "",{"version":3,"sources":["webpack://./src/screens/store-detail/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,2BAAA;AACF;;AAEA;EACE;IACE,sBAAA;EACF;AACF;AAEA;EACE;IACE,mBAAA;IACA,eAAA;IACA,2BAAA;EAAF;AACF;AAGA;EACE;IACE,mBAAA;IACA,eAAA;IACA,2BAAA;EADF;AACF;AAIA;EACE,aAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;AAFF;AAGE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AADJ;AAEI;EACE,iBAAA;EACA,yBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;AAAN;AAGE;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;AADJ;AAGE;EACE,YAAA;EACA,UAAA;EACA,eAAA;EACA,gBAAA;AADJ","sourcesContent":[".orders-container {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n}\n\n@media (max-width: 470px) {\n  .orders-container {\n    flex-direction: column;\n  }\n}\n\n@media (min-width: 471px) and (max-width: 1200px) {\n  .orders-container {\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: flex-start;\n  }\n}\n\n@media (min-width: 1201px) {\n  .orders-container {\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: flex-start;\n  }\n}\n\n.pzz-store-detail-container {\n  height: 100vh;\n  width: 100%;\n  margin-top: 25px;\n  padding: 20px;\n  .pzz-store-detail-header {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    .pzz-btn-edit {\n      border-width: 0px;\n      background-color: #159b7b;\n      padding: 8px;\n      color: #fff;\n      border-radius: 4px;\n      width: 74px;\n    }\n  }\n  .pzz-store-detail-head {\n    display: flex;\n    flex-direction: row;\n    padding-top: 20px;\n  }\n  .pzz-store-detail-map-container {\n    height: 30vh;\n    width: 40%;\n    min-width: 40vw;\n    margin-top: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
