import "./index.scss";

import React from "react";

import { OrderDataTypes } from "../../../utils/Types/OrdersDataTypes";

import SlideOutOrderDetailsPage from "./order-details-page";

import SlideOutInvoicePage from "./invoice-page";

import SlideOutPanelOrderTrackingPage from "./order-tracking-page";

type SlideOutPanelPropsTypes = {
  data: OrderDataTypes | undefined;
  onClose: any;
  showPanel: any;
};

const SlideOutPanel = ({ data, onClose }: SlideOutPanelPropsTypes) => {
  const [activePage, setActivePage] = React.useState<
    "orderDetailsPage" | "InvoicePage" | "OrderTrackingPage"
  >("orderDetailsPage");

  React.useEffect(() => {
    setActivePage("orderDetailsPage");

    return () => {
      setActivePage("orderDetailsPage");
    };
  }, [data?.Runner, data?.Status, data?.id]);

  return (
    <div className={`slide-out-panel`}>
      {activePage === "orderDetailsPage" ? (
        <SlideOutOrderDetailsPage
          data={data}
          onClose={onClose}
          setActivePage={setActivePage}
        />
      ) : activePage === "InvoicePage" ? (
        <SlideOutInvoicePage
          setActivePage={setActivePage}
          onClose={onClose}
          orderLine={data?.orderLine?.[0]}
          deliveryFrees={data?.deliveryFees}
          totalFees={data?.totalCost}
          orderId={data?.id}
        />
      ) : activePage === "OrderTrackingPage" ? (
        <SlideOutPanelOrderTrackingPage
          setActivePage={setActivePage}
          onClose={onClose}
          orderNumber={data?.orderNumber}
          orderId={data?.id}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
export default SlideOutPanel;
