import {
  OrderDataTypes,
  OrderLineTypes,
} from "../../../utils/Types/OrdersDataTypes";

type dataSetType = {
  allOrders: OrderDataTypes[];
};

const omsOrders = {
  initialState: { allOrders: [] },
  reducers: {
    addAllOrders(
      dataSet: dataSetType,
      action: { payload: OrderDataTypes[] | undefined }
    ) {
      dataSet.allOrders = action?.payload ?? [];
    },

    deleteOrder(dataSet: dataSetType, action: { payload: string }) {
      const excludeById = dataSet.allOrders?.filter(
        (data) => data?.id !== action?.payload
      );
      dataSet.allOrders = [...excludeById];
    },

    editOrder(dataSet: dataSetType, action: { payload: OrderDataTypes }) {
      const orderId = action?.payload?.id;
      const orderIndex = dataSet.allOrders.findIndex(
        (order) => order?.id === orderId
      );
      if (orderIndex >= 0) {
        dataSet.allOrders[orderIndex] = {
          ...action?.payload,
          orderLine: dataSet?.allOrders[orderIndex]?.orderLine,
          Runner: dataSet?.allOrders[orderIndex]?.Runner,
        };
      } else {
        dataSet.allOrders = [...dataSet.allOrders, action?.payload];
      }
    },

    updateRunner(
      dataSet: dataSetType,
      action: { payload: { id: string; Runner: any } }
    ) {
      const orderId = action?.payload?.id;
      const orderIndex = dataSet.allOrders.findIndex(
        (order) => order?.id === orderId
      );
      if (orderIndex >= 0) {
        dataSet.allOrders[orderIndex] = {
          ...dataSet.allOrders[orderIndex],
          Runner: action?.payload?.Runner,
        };
      }
    },

    updateOrderLine(
      dataSet: dataSetType,
      action: { payload: { orderLine: OrderLineTypes; id: string } }
    ) {
      const orderId = action?.payload?.id;
      const orderIndex = dataSet.allOrders.findIndex(
        (order) => order?.id === orderId
      );
      if (orderIndex >= 0) {
        dataSet.allOrders[orderIndex] = {
          ...dataSet.allOrders[orderIndex],
          orderLine: [action?.payload?.orderLine],
        };
      }
    },

    reset(dataSet: dataSetType, action) {
      dataSet.allOrders = [];
    },
  },
};

export default omsOrders;

export const OMS_ORDERS_ADD_ALL_ORDERS = "omsOrders/addAllOrders";

export const OMS_ORDERS_EDIT_ORDER = "omsOrders/editOrder";

export const OMS_ORDERS_DELETE_ORDER = "omsOrders/deleteOrder";

export const OMS_ORDERS_RESET_ORDER = "omsOrders/reset";

export const OMS_ORDERS_UPDATE_RUNNER = "omsOrders/updateRunner";

export const OMS_ORDERS_UPDATE_ORDERLINE = "omsOrders/updateOrderLine";
