import "./index.scss";

import React from "react";

import { TransformedOrderHistoryStatusType } from "../helpers/transformOrderHistory";
import ordersStatusDescription from "../helpers/ordersStatusDescription";

type OrderTrackingBodyPropsType = {
  data: TransformedOrderHistoryStatusType;
};

function OrderTrackingBody({ data }: OrderTrackingBodyPropsType): JSX.Element {
  return (
    <React.Fragment>
      {data?.date && (
        <div className="full-date-container">
          {data?.date}
          <div className="divider-style" />
        </div>
      )}
      {data?.status?.length &&
        data?.status?.map((value, index) => {
          return (
            <div className="status-history-container" key={index}>
              <p className="status-history-time">{value?.time}</p>
              <p className="status-history-details">
                {ordersStatusDescription[value?.status]}
              </p>
            </div>
          );
        })}
    </React.Fragment>
  );
}

export default OrderTrackingBody;
