import ListRunners from "../../components/ListRunners";
import { useState, useEffect } from "react";
import "./index.scss";
import SearchBarUsers from "../../components/SearchBarUsers";
import getListRunners from "../../services/api/get-list-runners";
import { toast } from "react-toastify";
import getSearchRunners from "../../services/api/get-search-runners";
import Modal from "react-modal";
import ModalAddRunner from "../../components/Modals/ModalAddRunner";
import { RunnerType } from "../../utils/Types/RunnerType";
import Lottie from "react-lottie";
import { defaultOptionsLoading } from "../../assets/lottie-animations/animationOptions";

const CourrierScreen = () => {
  const [listRunners, setListRunners] = useState<RunnerType[]>([]);
  const [query, setQuery] = useState<string>("");
  const [limit, setLimit] = useState<number>(13);
  const [page, setPage] = useState<number>(1);
  const [toastPages, setTotalPages] = useState<number>(1);
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      height: success ? "25vh" : "45vh",
      width: success ? "20%" : "35%",
      minWidth: "350px",
      minHeight: "370px",
      maxHeight: "420px",

      borderWidth: 0.2,
      borderRadius: 12,

      background: success
        ? "#fff"
        : `linear-gradient(269deg, #F1FFFC 0%, #FFFCF7 100%), #FFF`,
    },
  };
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const doGetListRunners = (limit: number, page: number) => {
    setLoading(true);
    getListRunners(limit, page)
      .then((response) => {
        setLoading(false);
        if (response.data.status === 500) {
          toast.error("Internal server error");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);
          return;
        }
        if (response.data.success) {
          setTotalPages(response.data.content.totalPages);
          setListRunners([...listRunners, ...response.data.content.runners]);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  };

  const getInitialeListRunners = (): void => {
    setLoading(true);

    getListRunners(13, 1)
      .then((response) => {
        setLoading(false);

        if (response.data.status === 500) {
          toast.error("Internal server error");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);
          return;
        }
        if (response.data.success) {
          setTotalPages(response.data.content.totalPages);
          setListRunners(response.data.content.runners);
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error);
      });
  };
  const loadMoreAction = (): void => {
    console.log(toastPages, page);

    if (toastPages <= page) {
      return;
    } else {
      setPage(page + 1);

      doGetListRunners(limit, page + 1);
    }
  };

  const doGetBySearch = (): void => {
    setLoading(true);

    getSearchRunners(query)
      .then((response) => {
        setLoading(false);

        if (response.data.status === 500) {
          toast.error("Internal server error");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);
          return;
        }
        if (response.data.success) {
          setTotalPages(1);
          setListRunners(response.data.content.Runners);
        }
      })
      .catch((error) => {
        setLoading(false);

        console.log("error in serach client", error);

        toast.error(error);
      });
  };

  useEffect(() => {
    doGetListRunners(limit, page);

    return () => {
      setPage(1);
      setLimit(13);
      setTotalPages(1);
    };
  }, []);

  useEffect(() => {
    if (!query) {
      setPage(1);
      setLimit(13);
      getInitialeListRunners();
    }
  }, [query]);

  return (
    <div>
      <div className="header-courrier-container">
        <div className="header-courrier-content">
          <p style={{ color: "#AAA" }}>ZZ Courier</p>
          <div className="btn-add-runner-container" onClick={() => openModal()}>
            <i className="bx bx-plus" style={{ color: "#fff" }}></i>
            <button className="btn-add-runner">Ajouter Courier</button>
          </div>
        </div>
        <div className="horizontale-line" />
      </div>
      <div>
        <div className="courrier-screen-search-bar-container">
          <SearchBarUsers
            doGetBySearch={doGetBySearch}
            query={query}
            setQuery={setQuery}
          />
        </div>
      </div>
      <div>
        {loading ? (
          <div className="loading-container-cs">
            <Lottie options={defaultOptionsLoading} height={120} width={120} />
          </div>
        ) : (
          <ListRunners
            listRunners={listRunners}
            loadMoreAction={loadMoreAction}
            getInitialeListRunners={getInitialeListRunners}
          />
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <ModalAddRunner
          closeModal={closeModal}
          getInitialeListRunners={getInitialeListRunners}
          success={success}
          setSuccess={setSuccess}
        />
      </Modal>
    </div>
  );
};
export default CourrierScreen;
