// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pzz-search-bar-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.pzz-search-bar-container .search-bar-input-container {
  height: 40%;
  width: 25%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 9px 0px 0px 9px;
  box-shadow: 0px 0px 16px 0px rgba(123, 123, 123, 0.1);
}
.pzz-search-bar-container .search-bar-input-container .search-btn-container {
  height: 100%;
  width: 20%;
  display: flex;
  justify-content: end;
  align-items: center;
  border-width: 0px;
  background-color: #fff;
  padding-right: 5px;
  border-radius: 0px 9px 9px 0px;
}
.pzz-search-bar-container .search-bar-input-container .input-search-style {
  height: 100%;
  width: 80%;
  min-width: 150px;
  border-width: 0px;
  border-radius: 9px;
  outline: 0px;
  padding-left: 7px;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchBarUsers/index.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,oBAAA;AACF;AAAE;EACE,WAAA;EACA,UAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,8BAAA;EACA,qDAAA;AAEJ;AADI;EACE,YAAA;EACA,UAAA;EACA,aAAA;EACA,oBAAA;EACA,mBAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;EACA,8BAAA;AAGN;AADI;EACE,YAAA;EACA,UAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,oBAAA;AAGN","sourcesContent":[".pzz-search-bar-container {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  padding-left: 15px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  .search-bar-input-container {\n    height: 40%;\n    width: 25%;\n    background-color: #fff;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    border-radius: 9px 0px 0px 9px;\n    box-shadow: 0px 0px 16px 0px rgba(123, 123, 123, 0.1);\n    .search-btn-container {\n      height: 100%;\n      width: 20%;\n      display: flex;\n      justify-content: end;\n      align-items: center;\n      border-width: 0px;\n      background-color: #fff;\n      padding-right: 5px;\n      border-radius: 0px 9px 9px 0px;\n    }\n    .input-search-style {\n      height: 100%;\n      width: 80%;\n      min-width: 150px;\n      border-width: 0px;\n      border-radius: 9px;\n      outline: 0px;\n      padding-left: 7px;\n      font-size: 16px;\n      padding-top: 10px;\n      padding-bottom: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
