import { OrderHistoryType } from "./order-history-type";

export type TransformedOrderHistoryStatusType = {
  date: string;
  status: {
    time: string;
    status: string;
  }[];
};

export type TransformedOrderHistoryType = {
  id: string;
  orderId: string;
  orderNumber: string;
  status: TransformedOrderHistoryStatusType[];
};

function transformOrderHistory(
  orderHistory: OrderHistoryType | undefined
): TransformedOrderHistoryType {
  if (orderHistory && Object.keys(orderHistory).length !== 0) {
    return orderHistory.status.reduce(
      (acc, curr) => {
        const date = curr.date.split(" ")[0];
        const existingStatus = acc.status.find(
          (status) => status?.date === date
        );

        if (existingStatus) {
          existingStatus.status.push({
            time: curr.date.split(" ")[1],
            status: curr?.status,
          });
        } else {
          acc.status.push({
            date,
            status: [
              {
                time: curr?.date.split(" ")[1],
                status: curr?.status,
              },
            ],
          });
        }

        return acc;
      },
      {
        id: orderHistory.id,
        orderId: orderHistory.orderId,
        orderNumber: orderHistory.orderNumber,
        status: [],
      }
    );
  } else {
    return;
  }
}

export default transformOrderHistory;
