import ListExtras from "../../components/ListExtras";
import ScreenTitle from "../../components/page-title";
import "./index.scss";

const ExtraScreen = () => {
  return (
    <div>
      <ScreenTitle title="Extras" />

      <ListExtras />
    </div>
  );
};
export default ExtraScreen;
