import { deleteRequest } from "../../utils/axios";

type DataFromAxiosType = {
  data: {
    success: boolean;
    message: string;
    status: number;
    content: Record<string, any>;
  };
};

async function deletePizza(pizzaName: string): Promise<DataFromAxiosType> {
  try {
    const { data }: DataFromAxiosType = await deleteRequest(
      `/pizzas/delete?name=${pizzaName}`
    );

    if (!data.success) {
      throw new Error(data?.message);
    }

    return {
      data,
    };
  } catch (error: any) {
    const returnedData: DataFromAxiosType = {
      data: {
        success: false,
        message: error?.message ?? "",
        status: error?.response?.status ?? 500,
        content: [],
      },
    };
    return returnedData;
  }
}

export default deletePizza;
