import React, {
  createContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";

export type AppContextType = {
  optionsToppings: any;
  setOptionsToppings: React.Dispatch<React.SetStateAction<any>>;
  selectedOptionTypeTopping: any;
  setSelectedOptionTypeTopping: React.Dispatch<React.SetStateAction<any>>;

};
interface ContextValueType {
  optionsToppings: any[];
  setOptionsToppings: Dispatch<SetStateAction<any[]>>;
  selectedOptionTypeTopping: any;
  setSelectedOptionTypeTopping: Dispatch<SetStateAction<any>>;
}
const AppContextInit = {
  optionsToppings: [],
  setOptionsToppings: () => {},
  selectedOptionTypeTopping: {},
  setSelectedOptionTypeTopping: () => {},
};
const initialOptionsToppings: any[] = [];
const initialSelectedOptionTypeTopping: any = {};

export const AppContext = createContext<ContextValueType | undefined>(
  AppContextInit
);

const ContextApp: React.FC<any> = ({ children }) => {
  const [optionsToppings, setOptionsToppings] = useState<any>(
    initialOptionsToppings
  );
  const [selectedOptionTypeTopping, setSelectedOptionTypeTopping] =
    useState<any>(initialSelectedOptionTypeTopping);

  return (
    <AppContext.Provider
      value={{
        optionsToppings,
        setOptionsToppings,
        selectedOptionTypeTopping,
        setSelectedOptionTypeTopping,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default ContextApp;
