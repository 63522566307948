// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pizza-container-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media (max-width: 767px) {
  .pizza-container-list {
    flex-direction: column;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .pizza-container-list {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .pizza-container-list {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media (min-width: 1200px) {
  .pizza-container-list {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.pzz-animation-container {
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/screens/pizzas-screen/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,2BAAA;AACF;;AAEA;EACE;IACE,sBAAA;EACF;AACF;AAEA;EACE;IACE,mBAAA;IACA,eAAA;IACA,8BAAA;EAAF;AACF;AAGA;EACE;IACE,mBAAA;IACA,eAAA;IACA,8BAAA;EADF;AACF;AAIA;EACE;IACE,mBAAA;IACA,eAAA;IACA,8BAAA;EAFF;AACF;AAKA;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AAHF","sourcesContent":[".pizza-container-list {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n}\n\n@media (max-width: 767px) {\n  .pizza-container-list {\n    flex-direction: column;\n  }\n}\n\n@media (min-width: 768px) and (max-width: 991px) {\n  .pizza-container-list {\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: space-between;\n  }\n}\n\n@media (min-width: 992px) and (max-width: 1199px) {\n  .pizza-container-list {\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: space-between;\n  }\n}\n\n@media (min-width: 1200px) {\n  .pizza-container-list {\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: space-between;\n  }\n}\n\n.pzz-animation-container {\n  height: 70vh;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
