// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast {
  --toastify-color-progress-light: rgba(21, 155, 123, 1);
}

.Toastify__toast-body {
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/custom-toast/index.scss"],"names":[],"mappings":"AAAA;EACE,sDAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".toast {\n  --toastify-color-progress-light: rgba(21, 155, 123, 1);\n  //   --toastify-toast-width: 250px;\n}\n.Toastify__toast-body {\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
