import ListUsers from "../../components/ListUsers";
import SearchBarUsers from "../../components/SearchBarUsers";
import ScreenTitle from "../../components/page-title";
import getListUsers from "../../services/api/get-list-users";
import "./index.scss";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Lottie from "react-lottie";

import getSearchClients from "../../services/api/get-search-clients";
import { defaultOptionsLoading } from "../../assets/lottie-animations/animationOptions";
const UsersScreen = () => {
  const [ListUsersState, setListUsersState] = useState<any>([]);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [toastPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState<string>("");
  const doGetListUsers = (limit, page) => {
    setLoading(true);
    getListUsers(limit, page)
      .then((response) => {
        setLoading(false);
        if (response.data.status === 500) {
          toast.error("Internal server error");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);
          return;
        }
        if (response.data.success) {
          setTotalPages(response.data.content.totalPages);
          setListUsersState([
            ...ListUsersState,
            ...response.data.content.clients,
          ]);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  };

  const getInitialeListUsers = () => {
    setLoading(true);
    getListUsers(20, 1)
      .then((response) => {
        setLoading(false);
        if (response.data.status === 500) {
          toast.error("Internal server error");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);
          return;
        }
        if (response.data.success) {
          setTotalPages(response.data.content.totalPages);
          setListUsersState(response.data.content.clients);
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error);
      });
  };
  const loadMoreAction = () => {
    console.log(toastPages, page);

    if (toastPages === page) {
      return;
    } else {
      let newPage = page + 1;
      setPage(newPage);

      doGetListUsers(7, newPage);
    }
  };

  const doGetBySearch = () => {
    setLoading(true);
    getSearchClients(query)
      .then((response) => {
        setLoading(false);

        if (response.data.status === 500) {
          toast.error("Internal server error");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);
          return;
        }
        if (response.data.success) {
          setTotalPages(1);
          setListUsersState(response.data.content.clients);
        }
      })
      .catch((error) => {
        console.log("error in serach client", error);
        setLoading(false);

        toast.error(error);
      });
  };

  useEffect(() => {
    doGetListUsers(limit, page);

    return () => {
      setPage(1);
      setLimit(5);
      setTotalPages(1);
    };
  }, []);

  useEffect(() => {
    if (!Boolean(query)) {
      getInitialeListUsers();
    }
  }, [query]);

  return (
    <div>
      <div>
        <ScreenTitle title="Users" />
      </div>
      <div style={{ height: 150, width: "100%" }}>
        <SearchBarUsers
          doGetBySearch={doGetBySearch}
          setQuery={setQuery}
          query={query}
        />
      </div>
      <div className="list-users-user-screen">
        {loading ? (
          <div className="loading-u-s-container">
            <Lottie options={defaultOptionsLoading} height={120} width={120} />
          </div>
        ) : (
          <ListUsers
            listUsersState={ListUsersState}
            loadMoreAction={loadMoreAction}
            getInitialeListUsers={getInitialeListUsers}
            setListUsersState={setListUsersState}
          />
        )}
      </div>
    </div>
  );
};
export default UsersScreen;
