import { store } from "../../utils/Types/StoreDefaultType";
import StoreCard from "../cards/StoreCard";
import "./index.scss";
import { useState, useEffect } from "react";
import Modal from "react-modal";
import NewStoreModal from "../Modals/NewStoreModal";
import getAllStoresWithCounts from "../../services/api/get-all-stores-with-count";
import AddAddressModal from "../Modals/AddAddressModal";
import addStore from "../../services/api/post-store";
import { toast } from "react-toastify";
import Lottie from "react-lottie";
import {
  defaultOptionsEmpty,
  defaultOptionsLoading,
} from "../../assets/lottie-animations/animationOptions";
const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "70vh",
    width: "50%",
    minWidth : "350px",

    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(269deg, #F1FFFC 0%, #FFFCF7 100%), #FFF`,
  },
};
interface position {
  latitude: number;
  longitude: number;
}
interface addressMap {
  addressName: string;
  position: position;
}

const ListStore = () => {
  const [listStores, setListStores] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState("first");
  const [loading, setLoading] = useState(false);
  const [addressPosition, setAddressPosition] = useState<addressMap>({
    addressName: "",
    position: { latitude: 35.8534375, longitude: 10.5469375 },
  });
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [storeName, setStoreName] = useState<string>("");
  const doGetAllStores = () => {
    setLoading(true);
    getAllStoresWithCounts()
      .then((response: any) => {
        if (response.data.status === 500) {
          toast.error("server error !");
          setLoading(false);
          console.log("server error");
          return;
        }
        if (!response.data.success) {
          toast.error(response?.data?.message);
          setLoading(false);
          console.log("error :" + response.data.message);
          return;
        }
        if (response.data.success) {
          setLoading(false);
          setListStores(response.data.content);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
    setStep("first");
    setAddressPosition({
      addressName: "",
      position: { latitude: 35.8534375, longitude: 10.5469375 },
    });
  }
  const doAddStore = () => {
    const data = {
      phoneNumber: phoneNumber?.substring(4),
      name: storeName,
      adresses: {
        name: addressPosition?.addressName,
        latitude: addressPosition?.position?.latitude,
        longtitude: addressPosition?.position?.longitude,
      },
    };
    addStore(data)
      .then((response: any) => {
        if (response.data.status === 500) {
          toast.error("server error !");
          console.log("server error");
          return;
        }
        if (!response.data.success) {
          toast.error(response?.data?.message);
          console.log("error :" + response.data.message);
          return;
        }
        if (response.data.success) {
          toast.success("store added successfuly !");
          closeModal();
          doGetAllStores();
        }
      })
      .catch((error) => {
        console.log("error :" + error);
      });
  };

  useEffect(() => {
    doGetAllStores();
  }, []);
  return (
    <div>
      <div className="list-store-container">
        <div className="btn-add-store-container">
          <div className="list-store-header" onClick={() => openModal()}>
            <i className="bx bx-plus" style={{ fontSize: 22 }}></i>
            <button
              style={{
                backgroundColor: "#159B7B",
                borderWidth: 0,
                color: "#fff",
              }}
            >
              New store
            </button>
          </div>
        </div>
        {loading ? (
          <div className="store-container">
            <div className="list-store-lottie-container">
              <Lottie
                options={defaultOptionsLoading}
                height={120}
                width={120}
              />
            </div>
          </div>
        ) : (
          <div className="store-container">
            {listStores?.length > 0 ? (
              listStores?.map((store: store, index) => (
                <StoreCard key={index} store={store} />
              ))
            ) : (
              <div className="list-store-empty-lottie-container">
                <Lottie
                  options={defaultOptionsEmpty}
                  height={"40%"}
                  width={"40%"}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        {step === "first" ? (
          <NewStoreModal
            closeModal={closeModal}
            setStep={setStep}
            addressPosition={addressPosition}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            storeName={storeName}
            setStoreName={setStoreName}
            doAddStore={doAddStore}
          />
        ) : (
          <AddAddressModal
            setStep={setStep}
            setAddressPosition={setAddressPosition}
            addressPosition={addressPosition}
            edit={false}
          />
        )}
      </Modal>
    </div>
  );
};
export default ListStore;
