import { OrderStatusType } from "../../utils/Types/OrderStatusTypes";

import { patchRequest } from "../../utils/axios";

type DataFromAxiosType = {
  data: {
    success: boolean;
    message: string;
    status: number;
    content: Record<string, any>;
  };
};

type BodyType = {
  Status: OrderStatusType;
  deliveredBy: string | null;
};

async function updateOrderStatusDeliveredBy(
  orderId: string,
  status: OrderStatusType,
  deliveredBy: string | null
): Promise<DataFromAxiosType> {
  try {
    const body: BodyType = {
      Status: status,
      deliveredBy: deliveredBy || "",
    };

    const { data }: DataFromAxiosType = await patchRequest(
      `/Oms/${orderId}/update-Order`,
      body
    );

    if (!data.success) {
      throw new Error(data?.message);
    }

    return {
      data,
    };
  } catch (error: any) {
    const returnedData: DataFromAxiosType = {
      data: {
        success: false,
        message: error?.message ?? "",
        status: error?.response?.status ?? 500,
        content: [],
      },
    };
    return returnedData;
  }
}

export default updateOrderStatusDeliveredBy;
