import "./index.scss";
import { useState } from "react";
import { ExtraEnum } from "../../utils/Types/enum-unit";
import deleteExtra from "../../services/api/delete-extra";
import { toast } from "react-toastify";
import Modal from "react-modal";
import DeleteExtraModal from "../DeleteExtraModal";
import ExtraType from "../../utils/Types/ExtraType";
import Lottie from "react-lottie";
import { defaultOptionsLoading } from "../../assets/lottie-animations/animationOptions";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "30vh",
    width: "30%",
    minWidth: "350px",

    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(269deg, #F1FFFC 0%, #FFFCF7 100%), #FFF`,
  },
};

interface props {
  extra: ExtraType;
  doGetAllExtras: () => void;
}

const ExtraCard = ({ extra, doGetAllExtras }: props) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loadingImage, setLoadingImage] = useState(true);
  const [loading, setLoading] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  const doDeleteExtra = () => {
    setLoading(true);
    deleteExtra(extra?.id)
      .then((response) => {
        setLoading(false);

        if (response.data.status === 500) {
          toast.error("internal server error");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);
          return;
        }
        if (response?.data?.success) {
          toast.success("Extra deleted successfuly !");
          doGetAllExtras();
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error ==>", error);
        toast.error(error);
      });
  };
  const doDeleteExtraPermission = () => {
    setIsOpen(true);
  };
  const handleLoadEnd = () => {
    setLoadingImage(false);
  };

  const doEditExtra = () => {};

  return (
    <>
      <div className="pzz-card-ing-container">
        <div className="pzz-extra-card-content">
          <div style={{ padding: 5 }} onClick={() => doEditExtra()}>
            <i className="bx bx-pencil" style={{ fontSize: 20 }}></i>
          </div>
          <div style={{ padding: 5 }} onClick={() => doDeleteExtraPermission()}>
            <i className="bx bx-trash" style={{ fontSize: 20 }}></i>
          </div>
        </div>
        <div className="pzz-extra-card-body">
          <div className="pzz-extra-card-body-wrapper">
            {loadingImage && (
              <div className="lottie-wrapper">
                <Lottie
                  options={defaultOptionsLoading}
                  height={"40%"}
                  width={"40%"}
                />
              </div>
            )}
            <img
              className="img-pzz"
              src={`${process.env?.REACT_APP_SERVER_URL}/extraPhoto?extra=${extra?.name}`}
              alt="extra-img"
              onLoad={handleLoadEnd}
            />
          </div>
          <div className="text-area-wrapper">
            <p>{extra?.name}</p>
            <p style={{ color: "#000", fontSize: 16, fontWeight: "500" }}>
              {extra?.volume} {ExtraEnum?.[extra?.unit]}
            </p>
            <p style={{ color: "#000", fontSize: 16, fontWeight: "700" }}>
              {extra?.price} DT
            </p>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <DeleteExtraModal
          closeModal={closeModal}
          doGetAllExtras={doGetAllExtras}
          doDeleteExtra={doDeleteExtra}
          extra={extra}
          loading={loading}
        />
      </Modal>
    </>
  );
};
export default ExtraCard;
