import { defaultOptionsLoading } from "../../../assets/lottie-animations/animationOptions";
import { RunnerType } from "../../../utils/Types/RunnerType";
import { Topping } from "../../../utils/Types/Topping";
import "./index.scss";
import Lottie from "react-lottie";
interface propos {
  topping?: Topping;
  doDeleteTopping?: () => void;
  closeModal?: () => void;
  runner?: RunnerType;
  DeblockRunner?: () => void;
  BlockRunner?: () => void;
  AprouveRunner?: () => void;
  action?: string;
  loading?: any;
}

const ModalDeletePermission = ({
  topping,
  doDeleteTopping,
  runner,
  DeblockRunner,
  closeModal,
  BlockRunner,
  AprouveRunner,
  action,
  loading,
}: propos) => {
  const doAction = () => {
    if (action === "deblock") {
      DeblockRunner();
    } else if (action === "block") {
      BlockRunner();
    } else {
      AprouveRunner();
    }
  };

  return (
    <>
      {Boolean(topping) ? (
        <div className="delete-modal-container">
          <div className="delete-modal-header">
            <p>Vous êtes sûre de vouloir supprimer</p>
          </div>
          <div className="delete-modal-body">
            <p className="delete-modal-ing-text">Ingrédient</p>
            <p className="delete-modal-ing-name-text">{topping?.toppingName}</p>
          </div>
          <div className="delete-modal-footer">
            <button
              className="delete-modal-btn-cancel"
              onClick={() => closeModal()}
            >
              Annuler
            </button>
            {loading ? (
              <Lottie options={defaultOptionsLoading} height={80} width={80} />
            ) : (
              <button
                className="delete-modal-btn-confirm"
                onClick={() => doDeleteTopping()}
              >
                Supprimer
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="delete-modal-container">
          <div className="delete-modal-header">
            <p>Vous êtes sûre de vouloir {action}</p>
          </div>
          <div className="delete-modal-body">
            <p className="delete-modal-ing-text">Runner</p>
            <p className="delete-modal-ing-name-text">
              {Boolean(runner?.name) ? runner?.name : runner?.phoneNumber}
            </p>
          </div>
          <div className="delete-modal-footer">
            <button
              className="delete-modal-btn-cancel"
              onClick={() => closeModal()}
            >
              Annuler
            </button>
            <button
              className="delete-modal-btn-confirm"
              onClick={() => doAction()}
            >
              {action}
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default ModalDeletePermission;
