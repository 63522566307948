// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #ffffff;
  border-radius: 8px;
  margin: 13px 13px 0px 0px;
  width: 47%;
}
.card-container .icon-container {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 43px;
}
.card-container .icon-container .icon {
  width: 23px;
  height: 23px;
  object-fit: contain;
}
.card-container .card-content {
  align-items: center;
  align-self: center;
  justify-content: center;
  text-align: center;
  margin: 10% 0px 15% 0px;
}
.card-container .card-content strong {
  font-size: 48px;
}
.card-container .card-content p {
  font-weight: 600;
  font-size: 12px;
}

@media (max-width: 1024px) {
  .card-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 8px;
    margin: 13px 13px 0px 0px;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .card-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 8px;
    margin: 13px 13px 0px 0px;
    width: 80%;
  }
}
@media (max-width: 576px) {
  .home-page-details-container {
    height: 40vh;
  }
}`, "",{"version":3,"sources":["webpack://./src/screens/home-screen/details-card/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;EACA,UAAA;AACF;AAAE;EACE,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AAEJ;AADI;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AAGN;AAAE;EACE,mBAAA;EACA,kBAAA;EACA,uBAAA;EACA,kBAAA;EACA,uBAAA;AAEJ;AADI;EACE,eAAA;AAGN;AADI;EACE,gBAAA;EACA,eAAA;AAGN;;AAEA;EACE;IACE,aAAA;IACA,sBAAA;IACA,aAAA;IACA,mBAAA;IACA,kBAAA;IACA,yBAAA;IACA,WAAA;EACF;AACF;AACA;EACE;IACE,aAAA;IACA,sBAAA;IACA,aAAA;IACA,mBAAA;IACA,kBAAA;IACA,yBAAA;IACA,UAAA;EACF;AACF;AAEA;EACE;IACE,YAAA;EAAF;AACF","sourcesContent":[".card-container {\n  display: flex;\n  flex-direction: column;\n  padding: 10px;\n  background: #ffffff;\n  border-radius: 8px;\n  margin: 13px 13px 0px 0px;\n  width: 47%;\n  .icon-container {\n    border-radius: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 43px;\n    height: 43px;\n    .icon {\n      width: 23px;\n      height: 23px;\n      object-fit: contain;\n    }\n  }\n  .card-content {\n    align-items: center;\n    align-self: center;\n    justify-content: center;\n    text-align: center;\n    margin: 10% 0px 15% 0px;\n    strong {\n      font-size: 48px;\n    }\n    p {\n      font-weight: 600;\n      font-size: 12px;\n    }\n  }\n}\n\n@media (max-width: 1024px) {\n  .card-container {\n    display: flex;\n    flex-direction: column;\n    padding: 10px;\n    background: #ffffff;\n    border-radius: 8px;\n    margin: 13px 13px 0px 0px;\n    width: 100%;\n  }\n}\n@media (max-width: 768px) {\n  .card-container {\n    display: flex;\n    flex-direction: column;\n    padding: 10px;\n    background: #ffffff;\n    border-radius: 8px;\n    margin: 13px 13px 0px 0px;\n    width: 80%;\n  }\n}\n\n@media (max-width: 576px) {\n  .home-page-details-container {\n    height: 40vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
