// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 10px 20px 10px;
  align-items: center;
}
.header .header-icon {
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 1000px;
  padding: 5px 5px 5px 5px;
  box-shadow: 0px 0px 4px rgba(30, 161, 130, 0.3);
  cursor: pointer;
}
.header .order-tracking-title {
  font-weight: 700;
}

.content-slideout .loader-container {
  margin-top: 20%;
}`, "",{"version":3,"sources":["webpack://./src/screens/orders-screen/slideout-panel/order-tracking-page/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,2BAAA;EACA,mBAAA;AACF;AAAE;EACE,aAAA;EACA,qBAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,qBAAA;EACA,wBAAA;EACA,+CAAA;EACA,eAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ;;AAEE;EACE,eAAA;AACJ","sourcesContent":[".header {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin: 20px 10px 20px 10px;\n  align-items: center;\n  .header-icon {\n    display: flex;\n    justify-items: center;\n    justify-content: center;\n    align-items: center;\n    background: #ffffff;\n    border-radius: 1000px;\n    padding: 5px 5px 5px 5px;\n    box-shadow: 0px 0px 4px rgba(30, 161, 130, 0.3);\n    cursor: pointer;\n  }\n  .order-tracking-title {\n    font-weight: 700;\n  }\n}\n.content-slideout {\n  .loader-container {\n    margin-top: 20%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
