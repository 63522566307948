import "./index.scss";

import ScreenTitle from "../../components/page-title";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import getAllIngrediants from "../../services/api/get-all-ingrediants";
import { useSelector, useStore } from "react-redux";
import getPizzasByName from "../../services/api/get-all-pizza-by-name";
import { toast } from "react-toastify";
import Lottie from "react-lottie";

import {
  PIZZA_SLICE_ADD_DOUGHTPRICE,
  PIZZA_SLICE_ADD_PIZZA_NAME,
  PIZZA_SLICE_ADD_TOPPINGS,
  PIZZA_SLICE_DECREASE_QUANTITY,
  PIZZA_SLICE_INCREASE_QUANTITY,
  PIZZA_SLICE_REMOVE_TOPPING,
  PIZZA_SLICE_RESET,
  PIZZA_SLICE_SETPIZZA,
} from "../../store/slices/reducers/pizzaSlice";
import getPhoto from "../../services/api/get-photo-data";
import updateMultiplePizza from "../../services/api/update-multiple-pizza";
import updatePizzaPhoto from "../../services/api/update-pizza-photo";
import Modal from "react-modal";
import ValidationDeleteMoadl from "../../components/ValidationDeleteModal";
import { Pizza } from "../../utils/Types/PizzaDataTypes";
import { Topping } from "../../utils/Types/Topping";
import { PizzaSizeEnum } from "../../utils/Types/enum-size";
import { defaultOptionsLoading } from "../../assets/lottie-animations/animationOptions";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Change the background color and opacity here
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "25vh",
    width: "25%",
    minWidth: "350px",
    minHeight: "320px",
    maxHeight: "420px",
    borderWidth: 0.2,
    borderRadius: 12,
    background: "#fff",
    padding: 15,
  },
};

function PizzaUpdateScreen() {
  const { pizzaNameUpdate } = useParams();
  const [listToppings, setListToppings] = useState<any>([]);
  const store = useStore();
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<any>();
  const [success, setSuccess] = useState<boolean>(false);
  const [selectedSize, setSelectedSize] = useState<string>("small");
  const [displayImage, setDisplayImage] = useState<boolean>(false);
  const [loadingImage, setLoadingImage] = useState(true);
  const [pizzaSmallDoughtPrice, setPizzaSmallDoughtPrice] = useState<any>(0);
  const [pizzaMediumDoughtPrice, setPizzaMediumDoughtPrice] =
    useState<number>(0);
  const [pizzaLargeDoughtPrice, setPizzaLargeDoughtPrice] = useState<number>(0);

  const handleLoadEnd = () => {
    setLoadingImage(false);
  };

  const pizza: Pizza = useSelector(
    (state: Storage) => state?.entities?.pizzaSlice?.pizza
  );

  const pizzaStoreSmall: Pizza = useSelector(
    (state: Storage) => state?.entities?.pizzaSlice?.pizzaSmall
  );
  const pizzaStoreMedium: Pizza = useSelector(
    (state: Storage) => state?.entities?.pizzaSlice?.pizzaMedium
  );
  const pizzaStoreLarge: Pizza = useSelector(
    (state: Storage) => state?.entities?.pizzaSlice?.pizzaLarge
  );

  const options = listToppings?.map((topping) => ({
    value: topping?.toppingName,
    label: topping?.toppingName,
    toppingName: topping?.toppingName,
  }));

  const getAllPizzaSizes = () => {
    setLoading(true);
    getPizzasByName(pizzaNameUpdate)
      .then((response) => {
        setLoading(false);
        if (response.data.status === 500) {
          return;
        }
        if (!response.data.success) {
          console.log(response.data.message);
          toast.error(response.data.message);
          return;
        }
        if (response.data.success) {
          const Small = response.data.content.pizzas.filter(
            (pizza: Pizza) => pizza.size === PizzaSizeEnum.small
          );
          const Medium = response.data.content.pizzas.filter(
            (pizza: Pizza) => pizza.size === PizzaSizeEnum.medium
          );
          const Large = response.data.content.pizzas.filter(
            (pizza: Pizza) => pizza.size === PizzaSizeEnum.large
          );

          try {
            store.dispatch({
              type: PIZZA_SLICE_SETPIZZA,
              payload: {
                pizzaSmall: Small?.[0],
                pizzaMedium: Medium?.[0],
                pizzaLarge: Large?.[0],
              },
            });
          } catch (error) {
            console.log("errror redux");
          }
        }
      })
      .catch((error) => {
        setLoading(false);

        console.log(error);
        toast.error(error);
      });
  };

  function onChangePizzaNameStore(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const name = e.target.value;
    try {
      store.dispatch({
        type: PIZZA_SLICE_ADD_PIZZA_NAME,
        payload: { name: name },
      });
    } catch (error) {
      console.log("errror redux");
    }
  }

  const filteredOptions = options.filter((option) => {
    return !pizza?.toppings.some(
      (selected) => selected?.toppingName === option.value
    );
  });

  const handleOptionSelectToStore = (option: Topping) => {
    const topping = {
      value: option.value,
      label: option.label,
      toppingName: option.value,
      quantity: 1,
    };
    try {
      store.dispatch({
        type: PIZZA_SLICE_ADD_TOPPINGS,
        payload: topping,
      });
    } catch (error) {
      console.log("errror redux");
    }
  };

  const getListIngrediants = () => {
    setLoading(true);

    getAllIngrediants()
      .then((response) => {
        setLoading(false);

        if (response?.data?.status === 500) {
          console.log("server  error");
          return;
        }
        if (!response.data.success) {
          console.log("error");
          return;
        }
        if (response?.data?.success) {
          setListToppings(response.data.content.toppings);
        }
      })
      .catch((error) => {
        setLoading(false);

        console.log(error);
        toast.error(error);
      });
  };

  const removeIngrediantFromStore = (option) => {
    const topping = {
      value: option.toppingName,
      label: option.toppingName,
      toppingName: option.toppingName,
      quantity: 1,
    };
    try {
      store.dispatch({
        type: PIZZA_SLICE_REMOVE_TOPPING,
        payload: topping,
      });
    } catch (error) {
      console.log("errror redux");
    }
  };

  function closeModal() {
    setIsOpen(false);
  }

  const increase = (size: string, value: string) => {
    try {
      store.dispatch({
        type: PIZZA_SLICE_INCREASE_QUANTITY,
        payload: { size, value },
      });
    } catch (error) {
      console.log("errror redux");
    }
  };

  const decrease = (size: string, value: string) => {
    try {
      store.dispatch({
        type: PIZZA_SLICE_DECREASE_QUANTITY,
        payload: { size, value },
      });
    } catch (error) {
      console.log("errror redux");
    }
  };

  const changeDoughtPriceMedium = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    try {
      store.dispatch({
        type: PIZZA_SLICE_ADD_DOUGHTPRICE,
        payload: { size: PizzaSizeEnum.medium, price: e.target.value },
      });
    } catch (error) {
      console.log("errror redux");
    }
  };

  const changeDoughtPriceSmall = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    try {
      store.dispatch({
        type: PIZZA_SLICE_ADD_DOUGHTPRICE,
        payload: { size:PizzaSizeEnum.small, price: e.target.value },
      });
    } catch (error) {
      console.log("errror redux");
    }
  };

  const changeDoughtPriceLarge = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    try {
      store.dispatch({
        type: PIZZA_SLICE_ADD_DOUGHTPRICE,
        payload: { size:PizzaSizeEnum.large, price: e.target.value },
      });
    } catch (error) {
      console.log("errror redux");
    }
  };

  const getImage = () => {
    getPhoto(pizzaNameUpdate).then((response) => {
      if (typeof response.data == "string") {
        setDisplayImage(true);
      } else {
        setDisplayImage(false);
      }
    });
  };

  const doUpdatePizza = () => {
    setLoading(true);
    const pizzaSmallNew = { ...pizzaStoreSmall };
    pizzaSmallNew.doughPrice = Number(pizzaSmallNew.doughPrice);
    const pizzaMediumNew = { ...pizzaStoreMedium };
    pizzaMediumNew.doughPrice = Number(pizzaMediumNew.doughPrice);
    const pizzaLargeNew = { ...pizzaStoreLarge };
    pizzaLargeNew.doughPrice = Number(pizzaLargeNew.doughPrice);
    const bodyData = {
      smallPizza: pizzaSmallNew,
      mediumPizza: pizzaMediumNew,
      largePizza: pizzaLargeNew,
    };
    updateMultiplePizza(bodyData, pizzaNameUpdate)
      .then((response) => {
        console.log("response", response);

        if (response.data.status === 500) {
          setLoading(false);

          console.log("server error");
          return;
        }
        if (!response.data.success) {
          setLoading(false);
          toast.error(response.data.message);
          console.log("error", response.data.message);
          return;
        }
        if (response.data.success) {
          if (selectedImage) {
            const bodyData = { file: selectedImage };
            if (pizzaNameUpdate !== pizzaStoreSmall.name) {
              updatePizzaPhoto(
                bodyData,
                pizzaStoreSmall.name,
                pizzaStoreSmall.name
              )
                .then((response) => {
                  if (response.data.status === 500) {
                    setLoading(false);
                    toast.error("internal server error");

                    console.log("server error");
                    return;
                  }
                  if (!response.data.success) {
                    setLoading(false);
                    toast.error("error : " + response.data.message);
                    console.log(response.data.message);
                    return;
                  }
                  if (response.data.success) {
                    setLoading(false);
                    toast.success("Pizza Updated successfuly ");
                    setSuccess(true);
                    navigate("/pizza");
                  }
                })
                .catch((error) => {
                  setLoading(false);
                  toast.error("error" + error);

                  console.log(error);
                });
            } else {
              updatePizzaPhoto(bodyData, pizzaNameUpdate, pizzaStoreSmall.name)
                .then((response) => {
                  setLoading(false);
                  if (response.data.status === 500) {
                    toast.error("Internal server error !");
                    return;
                  }
                  if (!response.data.success) {
                    toast.error(response?.data?.message);
                    return;
                  }
                  if (response.data.success) {
                    toast.success("Pizza updated successfuly !");
                    setLoading(false);
                    setSuccess(true);
                    navigate("/pizza");
                  }
                })
                .catch((error) => {
                  setLoading(false);

                  console.log(error);
                });
            }
          } else {
            setLoading(false);
            setSuccess(true);
            navigate("/pizza");
            toast.success("Pizza Updated successfuly ");
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("error" + error);

        console.log(error);
      });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  useEffect(() => {
    getImage();
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
    setPizzaSmallDoughtPrice(pizzaStoreSmall?.doughPrice);

    //eslint-disable-next-line
  }, [selectedImage]);

  useEffect(() => {
    getListIngrediants();
  }, []);

  useEffect(() => {
    getAllPizzaSizes();
    return () => {
      try {
        store.dispatch({
          type: PIZZA_SLICE_RESET,
          payload: {},
        });
      } catch (error) {
        console.log("errror redux");
      }
    };
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <ScreenTitle title="Détails" />
      {loading ? (
        <div>
          <Lottie
            options={defaultOptionsLoading}
            height={"90%"}
            width={"50%"}
          />
        </div>
      ) : (
        <div className="pizza-container">
          <div className="Image-container">
            <div className="img-content">
              <div className="Img-btn-wrapper">
                <div className="Img-content">
                  {selectedImage ? (
                    <img
                      src={imageUrl}
                      alt="Selected"
                      className="img-displayed"
                    />
                  ) : !displayImage ? (
                    <div className="upload-file-content">
                      {selectedImage && (
                        <img
                          src={imageUrl}
                          alt="Selected"
                          className="img-displayed"
                        />
                      )}
                    </div>
                  ) : (
                    <>
                      {loadingImage && (
                        <div className="loader-container">
                          <Lottie
                            options={defaultOptionsLoading}
                            height={"150px"}
                            width={"150px"}
                            backgroundColor={"#000"}
                          />
                        </div>
                      )}
                      <img
                        src={`${process.env?.REACT_APP_SERVER_URL}/photo?pizza=${pizzaNameUpdate}`}
                        alt="Selected"
                        className="img-displayed"
                        onLoad={handleLoadEnd}
                      />
                    </>
                  )}
                </div>
                <div className="btn-wrapper">
                  <div className="custom-file-upload">
                    <input
                      type="file"
                      id="file-upload"
                      accept=".jpg,.gif,.png"
                      multiple
                      onChange={handleFileChange}
                    />
                    <label className="reposte-label" htmlFor="file-upload">
                      <i
                        style={{
                          fontSize: 25,
                          color: "#000",
                          marginRight: 10,
                        }}
                        className="bx bx-repost"
                      ></i>
                    </label>
                  </div>
                  {selectedImage != null ? (
                    <div
                      className="trash-btn-content"
                      onClick={() => setSelectedImage(null)}
                    >
                      <i
                        style={{ fontSize: 25, color: "#000", marginRight: 10 }}
                        className="bx bx-trash"
                      ></i>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <div className="Info-Container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div className="Input-name-wrapper">
                <input
                  className="pzz-input"
                  value={pizza?.name}
                  onChange={(e) => onChangePizzaNameStore(e)}
                  placeholder="ex: PZZ_Tuna"
                ></input>
              </div>

              <div className="pzz-save-and-delete-content">
                <div
                  style={{ cursor: "pointer" }}
                  className="pzz-save-content"
                  onClick={() => doUpdatePizza()}
                >
                  <p
                    style={{ color: "#159B7B", fontSize: 12, fontWeight: 700 }}
                  >
                    Enregistrer
                  </p>
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  className="pzz-delete-content"
                  onClick={() => setIsOpen(true)}
                >
                  <i style={{ fontSize: 25 }} className="bx bx-trash"></i>
                </div>
              </div>
            </div>
            <div style={{ marginBottom: 20 }}>
              <div style={{ marginTop: 20, marginLeft: 20 }}>
                <p>Ingrédients</p>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="Input-Ingrediant-Wrapper">
                <Select
                  placeholder="ex : thon"
                  options={filteredOptions}
                  onChange={handleOptionSelectToStore}
                />
              </div>
              <div style={{ width: "30%", marginLeft: 15 }}>
                <p style={{ color: "#000" }}>
                  Appuyer sur "Entrer" pour ajouter
                </p>
              </div>
            </div>

            <div className="container">
              <div className="content">
                {pizza?.toppings?.length > 0 ? (
                  pizza?.toppings.map((topping, index) => (
                    <div key={index} className="item">
                      <p className="item-txt">{topping?.toppingName}</p>
                      <div
                        className="pzz-x-remove-topping"
                        onClick={() => removeIngrediantFromStore(topping)}
                      >
                        <i
                          style={{ fontSize: 25, color: "#CD4443" }}
                          className="bx bx-x"
                        ></i>
                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
              <div style={{ padding: 20 }}>
                <h3 style={{ color: "#159B7B" }}>Dosage</h3>
              </div>
              <div className="dosage-row">
                <div
                  style={{ cursor: "pointer" }}
                  className="dosage-item"
                  onClick={() => setSelectedSize("small")}
                >
                  <p
                    style={{
                      color: selectedSize === "small" ? "#000" : "#B6B6B6",
                    }}
                  >
                    Small
                  </p>
                  {selectedSize === "small" && (
                    <div
                      style={{
                        width: "100%",
                        borderWidth: 6,
                        height: 3,
                        backgroundColor: "#159B7B",
                      }}
                    />
                  )}
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  className="dosage-item"
                  onClick={() => setSelectedSize("medium")}
                >
                  <p
                    style={{
                      color: selectedSize === "medium" ? "#000" : "#B6B6B6",
                    }}
                  >
                    Medium
                  </p>
                  {selectedSize === "medium" && (
                    <div
                      style={{
                        width: "100%",
                        borderWidth: 6,
                        height: 3,
                        backgroundColor: "#159B7B",
                      }}
                    />
                  )}
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  className="dosage-item"
                  onClick={() => setSelectedSize("large")}
                >
                  <p
                    style={{
                      color: selectedSize === "large" ? "#000" : "#B6B6B6",
                    }}
                  >
                    Large
                  </p>
                  {selectedSize === "large" && (
                    <div
                      style={{
                        width: "100%",
                        borderWidth: 6,
                        height: 3,
                        backgroundColor: "#159B7B",
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="content-ing">
                {selectedSize === "small" ? (
                  <div className="content-ing">
                    <div className="content-ing-under">
                      <input
                        className="content-ing-under-input"
                        value={pizzaStoreSmall?.doughPrice}
                        placeholder="cout de la pate"
                        color="#159B7B"
                        onChange={(e) => changeDoughtPriceSmall(e)}
                      />
                      <div className="content-ing-under-tnd-container">
                        <p className="content-ing-under-tnd-txt">TND</p>
                      </div>
                    </div>
                    {pizzaStoreSmall?.toppings.length > 0 ? (
                      pizzaStoreSmall?.toppings?.map((topping, index) => (
                        <div key={index} className="item-ingrediants">
                          <div className="Box-ing">
                            <p style={{ textAlign: "center" }}>
                              {topping?.quantity}
                            </p>
                            <div>
                              <i
                                style={{ fontSize: 14, color: "#CD4443" }}
                                className="bx bx-caret-up"
                                onClick={() =>
                                  increase(
                                    PizzaSizeEnum.small,
                                    topping?.toppingName
                                  )
                                }
                              ></i>
                              <i
                                style={{ fontSize: 14, color: "#CD4443" }}
                                className="bx bx-caret-down"
                                onClick={() =>
                                  decrease(
                                    PizzaSizeEnum.small,
                                    topping?.toppingName
                                  )
                                }
                              ></i>
                            </div>
                          </div>
                          <p
                            style={{
                              color: "#000",
                              fontSize: 16,
                              fontWeight: 700,
                            }}
                          >
                            {topping?.toppingName}
                          </p>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                ) : selectedSize === "medium" ? (
                  <div className="content-ing">
                    <div className="content-ing-under">
                      <input
                        className="content-ing-under-input"
                        value={pizzaStoreMedium?.doughPrice}
                        placeholder="cout de la pate"
                        color="#159B7B"
                        onChange={(e) => changeDoughtPriceMedium(e)}
                      />
                      <div className="content-ing-under-tnd-container">
                        <p className="content-ing-under-tnd-txt">TND</p>
                      </div>
                    </div>

                    {pizzaStoreMedium?.toppings.length > 0 ? (
                      pizzaStoreMedium?.toppings?.map((topping, index) => (
                        <div key={index} className="item-ingrediants">
                          <div className="Box-ing">
                            <p style={{ textAlign: "center" }}>
                              {topping?.quantity}
                            </p>
                            <div>
                              <i
                                style={{ fontSize: 14, color: "#CD4443" }}
                                className="bx bx-caret-up"
                                onClick={() =>
                                  increase(
                                    PizzaSizeEnum.medium,
                                    topping?.toppingName
                                  )
                                }
                              ></i>
                              <i
                                style={{ fontSize: 14, color: "#CD4443" }}
                                className="bx bx-caret-down"
                                onClick={() =>
                                  decrease(
                                    PizzaSizeEnum.medium,
                                    topping?.toppingName
                                  )
                                }
                              ></i>
                            </div>
                          </div>
                          <p
                            style={{
                              color: "#000",
                              fontSize: 16,
                              fontWeight: 700,
                            }}
                          >
                            {topping?.toppingName}
                          </p>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <div className="content-ing">
                    <div className="content-ing-under">
                      <input
                        className="content-ing-under-input"
                        value={pizzaStoreLarge?.doughPrice}
                        placeholder="cout de la pate"
                        color="#159B7B"
                        onChange={(e) => changeDoughtPriceLarge(e)}
                      />
                      <div className="content-ing-under-tnd-container">
                        <p className="content-ing-under-tnd-txt">TND</p>
                      </div>
                    </div>
                    {pizzaStoreLarge?.toppings?.length > 0 ? (
                      pizzaStoreLarge?.toppings?.map((topping, index) => (
                        <div key={index} className="item-ingrediants">
                          <div className="Box-ing">
                            <p style={{ textAlign: "center" }}>
                              {topping?.quantity}
                            </p>
                            <div>
                              <i
                                style={{ fontSize: 14, color: "#CD4443" }}
                                className="bx bx-caret-up"
                                onClick={() =>
                                  increase(
                                    PizzaSizeEnum.large,
                                    topping?.toppingName
                                  )
                                }
                              ></i>
                              <i
                                style={{ fontSize: 14, color: "#CD4443" }}
                                className="bx bx-caret-down"
                                onClick={() =>
                                  increase(
                                    PizzaSizeEnum.large,
                                    topping?.toppingName
                                  )
                                }
                              ></i>
                            </div>
                          </div>
                          <p
                            style={{
                              color: "#000",
                              fontSize: 16,
                              fontWeight: 700,
                            }}
                          >
                            {topping?.toppingName}
                          </p>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        ariaHideApp={false}
        style={customStyles}
      >
        <ValidationDeleteMoadl
          closeModal={closeModal}
          pizzaNameUpdate={pizzaNameUpdate}
        />
      </Modal>
    </div>
  );
}

export default PizzaUpdateScreen;
