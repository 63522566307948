import { useNavigate } from "react-router-dom";
import { store } from "../../../utils/Types/StoreDefaultType";
import "./index.scss";
interface props {
  store: store;
}
const StoreCard = ({ store }: props) => {
  const navigate = useNavigate();

  return (
    <div
      className="pzz-store-card-container"
      onClick={() => navigate(`/stores/${store?.id}`)}
    >
      <div className="pzz-store-card-header">
        <div className="pzz-details-info-store">
          <div className="pzz-store-header-content">
            <img
              src="./../../../assets/storeIconCard.png"
              alt="imageIconStore"
            />
            <p className="pzz-store-name-txt">{store?.name}</p>
          </div>
        </div>
      </div>
      <div className="pzz-store-card-body">
        <div className="pzz-store-address-txt-container">
          <p className="pzz-store-address-txt">Address</p>
        </div>
        <div className="pzz-store-store-name-txt-container">
          <p className="pzz-store-store-name-txt">{store?.adresses?.name}</p>
        </div>
        <div className="pzz-store-queue-name-container">
          <div className="pzz-store-queue-name-title">
            <p className="pzz-queue-title-txt">Queue</p>
          </div>
          <div className="pzz-store-queue-title-value">
            {" "}
            <p className="pzz-queue-title-value-txt">
              {store?.inProgressCount}
            </p>
          </div>
        </div>
      </div>
      <div className="pzz-store-card-seperator" />
      <div className="pzz-store-card-footer">
        <div className="pzz-store-card-footer-content">
          <div className="pzz-store-card-footer-total-order-txt-container">
            <p className="pzz-store-card-footer-total-order-txt">
              Total Orders
            </p>
          </div>
          <div className="pzz-store-card-footer-total-order-value-txt-container">
            <p className="pzz-store-card-footer-total-order-value-txt">{store?.allOrdersCount}</p>
          </div>
          <div className="pzz-open-target-container">
            <div className={store?.active ?  "pzz-open-target-txt-content" : "pzz-close-target-txt-content"} />
            <p className="pzz-open-target-txt">{store?.active ? "open" : "closed" } </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StoreCard;
