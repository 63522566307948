import "./index.scss";
const SearchBarUsers = ({ query, setQuery, doGetBySearch }) => {
  const handleChange = (e) => {
    e.preventDefault();
    setQuery(e.target.value);
  };
  return (
    <div className="pzz-search-bar-container">
      <div className="search-bar-input-container">
        <input
          className="input-search-style"
          value={query}
          onChange={(e) => handleChange(e)}
        />
        <button className="search-btn-container" disabled={Boolean(query) ? false : true} onClick={() => doGetBySearch()}>
          <i
            className="bx bx-search"
            style={{
              fontSize: 32,
              color: !Boolean(query) ? "#e2e2e2" : "#000",
              textAlign : "end"
            }}
          ></i>
        </button>
      </div>
    </div>
  );
};

export default SearchBarUsers;
