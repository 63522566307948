import getExtras from "../../services/api/get-extras";
import addExtra from "../../services/api/post-extra";
import "./index.scss";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Lottie from "react-lottie";
import Modal from "react-modal";

import {
  defaultOptionsEmpty,
  defaultOptionsLoading,
} from "../../assets/lottie-animations/animationOptions";
import ExtraCard from "../ExtraCard";
import NewExtra from "../NewExtra";
import AddExtraPhoto from "../AddExtraPhoto";
import addExtraPhotos from "../../services/api/post-extra-photo";
import ExtraType from "../../utils/Types/ExtraType";
const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Change the background color and opacity here
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "40vh",
    width: "50%",
    minWidth : "370px",
    minHeight : "370px",
    maxHeight : "420px",
    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(269deg, #F1FFFC 0%, #FFFCF7 100%), #FFF`,
  },
};

interface value {
  name: string;
  volume: number;
  price: number;
  unity: string;
}

const initialeState: value = {
  name: "",
  volume: 0,
  price: 0,
  unity: "gramme",
};

const ListExtras = () => {
  const [listExtras, setListExtras] = useState<ExtraType[]>([]);
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [step, setStep] = useState<string>("first");
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [values, setValues] = useState<value>(initialeState);
  const [selectedImage, setSelectedImage] = useState<Blob | MediaSource>(null);
  const [imageUrl, setImageUrl] = useState<string>("");

  const doGetAllExtras = () => {
    setLoading(true);
    getExtras()
      .then((response: any) => {
        if (response.data.status === 500) {
          toast.error("server error !");
          setLoading(false);
          console.log("server error");
          return;
        }
        if (!response.data.success) {
          toast.error(response?.data?.message);
          setLoading(false);
          console.log("error :" + response.data.message);
          return;
        }
        if (response.data.success) {
          setLoading(false);
          setListExtras(response.data.content);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.error(error);
      });
  };

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setValues(initialeState);
    setSelectedImage(null);
    setImageUrl(null);
    setIsOpen(false);
    setStep("first");
  }

  const doAddExtra = () => {
    setLoadingAdd(true);
    const data = {
      name: values.name,
      volume: Number(values.volume),
      price: Number(values.price),
      unit: values.unity,
    };
    addExtra(data)
      .then((response: any) => {
        setLoadingAdd(false);

        if (!response.data.success) {
          toast.error(response?.data?.message);
          console.log("error :" + response.data.message);
          return;
        }
        if (response.data.status == 500) {
          toast.error("server error !");
          console.log("server error");
          return;
        }
        if (response.data.success) {
          const bodyData = {
            photo: selectedImage,
          };
          addExtraPhotos(bodyData, values.name)
            .then((response) => {
              if (response.data.status === 500) {
                toast.error("server errror");
                return;
              }
              if (!response.data.success) {
                toast.error(response.data.message);
                return;
              }
              if (response.data.success) {
                toast.success("Extra added successfuly ! ");
                closeModal();
                doGetAllExtras();
              }
            })
            .catch((error) => {
              setLoadingAdd(false);

              toast.error(error);
            });
        }
      })
      .catch((error) => {
        console.log("error :" + error);
      });
  };

  useEffect(() => {
    doGetAllExtras();
  }, []);
  return (
    <div>
      <div className="list-extra-container">
        <div className="btn-add-store-container">
          <div className="btn-new-extra-container" onClick={() => openModal()}>
            <i className="bx bx-plus" style={{ fontSize: 22 }}></i>
            <button className="btn-new-extra">New Extra</button>
          </div>
        </div>
        {loading ? (
          <div className="store-container">
            <div className="loader-container">
              <Lottie
                options={defaultOptionsLoading}
                height={120}
                width={120}
              />
            </div>
          </div>
        ) : (
          <div className="store-container">
            {listExtras?.length > 0 ? (
              listExtras?.map((extra: ExtraType, index) => (
                <ExtraCard
                  key={index}
                  extra={extra}
                  doGetAllExtras={doGetAllExtras}
                />
              ))
            ) : (
              <div className="empty-lottie-container">
                <Lottie
                  options={defaultOptionsEmpty}
                  height={"40%"}
                  width={"40%"}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        {step === "first" ? (
          <NewExtra setStep={setStep} values={values} setValues={setValues} />
        ) : (
          <AddExtraPhoto
            setStep={setStep}
            values={values}
            setImageUrl={setImageUrl}
            imageUrl={imageUrl}
            setSelectedImage={setSelectedImage}
            selectedImage={selectedImage}
            doAddExtra={doAddExtra}
            loadingAdd={loadingAdd}
          />
        )}
      </Modal>
    </div>
  );
};

export default ListExtras;
