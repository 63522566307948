import "./index.scss";

import React from "react";

import { ReactComponent as CloseIcon } from "../../../../assets/icons/close-icon.svg";
import { ReactComponent as InvoiceIcon } from "../../../../assets/icons/invoice-icon.svg";
import { ReactComponent as OrderTrackingIcon } from "../../../../assets/icons/order-tracking-icon.svg";

import { OrderDataTypes } from "../../../../utils/Types/OrdersDataTypes";

import getAllActiveRunners from "../../../../services/api/get-all-active-runners";
import updateOrderStatusDeliveredBy from "../../../../services/api/update-order-status-deliveredBy";
import { OrderStatusType } from "../../../../utils/Types/OrderStatusTypes";
import { toast } from "react-toastify";
import Lottie from "react-lottie";

import animationData from "../../../../assets/lottie-animations/loading.json";
import { useStore } from "react-redux";
import { OMS_ORDERS_EDIT_ORDER } from "../../../../store/slices/reducers/omsOrders";
import { ActiveRunnersListTypes } from "./types/active-runner-types";
import getAllStatusByOrderType from "../utils/getAllStatus";
import { OrderTypeTypes } from "../../../../utils/Types/orders-type-types";

type SlideOutOrderDetailsPagePropsTypes = {
  data: OrderDataTypes | undefined;
  onClose: Function;
  setActivePage: (
    args: "orderDetailsPage" | "InvoicePage" | "OrderTrackingPage"
  ) => void;
};

function SlideOutOrderDetailsPage({
  data,
  onClose,
  setActivePage,
}: SlideOutOrderDetailsPagePropsTypes): JSX.Element {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const store = useStore();

  const selectStatusRef = React.useRef(null);

  const selectRunnerRef = React.useRef(null);

  const [activeRunners, setActiveRunner] = React.useState<
    ActiveRunnersListTypes[]
  >([]);

  const [loadingRunnerList, setLoadingRunnerList] =
    React.useState<boolean>(false);

  const [loadingUpdateStatus, setLoadingUpdateStatus] =
    React.useState<boolean>(false);

  const [isChangedStatus, setIsChangedStatus] = React.useState<boolean>(false);

  const [newStatusSelected, setNewStatusSelected] =
    React.useState<OrderStatusType>();

  const [initialStatus, setInitialeStatus] = React.useState<OrderStatusType>(
    data?.Status
  );

  const [selectedRunner, setSelectedRunner] = React.useState<
    ActiveRunnersListTypes | any
  >(data?.Runner);

  function handleCancelUpdateStatus() {
    setIsChangedStatus(false);
    setSelectedRunner(data?.Runner || {});
    setInitialeStatus(data?.Status);
    selectStatusRef.current.value = data?.Status;
    selectRunnerRef.current.value = "select";
  }

  function handleConfirmUpdateStatus() {
    setLoadingUpdateStatus(true);
    updateOrderStatusDeliveredBy(
      data?.id,
      newStatusSelected,
      Object.keys(selectedRunner)?.length ? selectedRunner?.phoneNumber : ""
    ).then((res) => {
      setLoadingUpdateStatus(false);
      if (!res?.data?.success) {
        toast.error(
          "Erreur lors de la mise à jour du statut de la commande : " +
            res?.data?.message
        );
        return;
      }

      store.dispatch({
        type: OMS_ORDERS_EDIT_ORDER,
        payload: {
          ...data,
          Status: newStatusSelected || data?.Status,
          deliveredBy: selectedRunner?.phoneNumber || data?.deliveredBy,
          Runner: Object.keys(selectedRunner)?.length
            ? {
                ...selectedRunner,
                fullName: selectedRunner?.Name + " " + selectedRunner?.LastName,
              }
            : {},
        },
      });

      setInitialeStatus(newStatusSelected || data?.Status);
      setSelectedRunner({
        ...selectedRunner,
        fullName: selectedRunner?.Name + " " + selectedRunner?.LastName,
      });
      setIsChangedStatus(false);
      setNewStatusSelected(null);
    });
  }

  React.useEffect(() => {
    setSelectedRunner(data?.Runner);
    setIsChangedStatus(false);
    setLoadingRunnerList(true);
    getAllActiveRunners().then((res) => {
      setLoadingRunnerList(false);
      if (!res?.data?.success) {
        toast.error(
          "Erreur lors de la récupération de la liste des livreurs : " +
            res?.data?.message
        );
        return;
      }
      setActiveRunner(res?.data?.content?.runners);
    });
    return () => {
      setLoadingRunnerList(false);
      setLoadingUpdateStatus(false);
    };
  }, [data, data?.Runner, data?.Status]);

  React.useEffect(() => {
    setInitialeStatus(data?.Status);
  }, [data.Status]);

  return (
    <>
      <div className="header">
        <div>
          ID <strong>{data?.id}</strong>
        </div>
        <div>
          Numéro de l'ordre <strong>{data?.orderNumber}</strong>
        </div>
        <div onClick={() => onClose()} className="close-icon">
          <CloseIcon width={15} height={15} />
        </div>
      </div>
      <div className="buttons-container">
        <button
          className="invoice-button"
          onClick={() => setActivePage("InvoicePage")}
        >
          <InvoiceIcon />
          <>Facture</>
        </button>
        <button
          className="order-tracking-button"
          onClick={() => setActivePage("OrderTrackingPage")}
        >
          <OrderTrackingIcon />
          <>Suivi commande</>
        </button>
      </div>
      <div className="content-slideout">
        {loadingUpdateStatus ? (
          <div className="loader-update">
            <Lottie options={defaultOptions} height={50} width={50} />
          </div>
        ) : (
          <>
            <div className="status-container">
              <p>Status</p>
              <select
                ref={selectStatusRef}
                className="select-container"
                onChange={(e) => {
                  setIsChangedStatus(true);
                  setNewStatusSelected(e?.target?.value as OrderStatusType);
                }}
              >
                {Object.entries(getAllStatusByOrderType(data?.OrderType)).map(
                  ([key, value], index) => {
                    return (
                      <option
                        value={key}
                        key={index}
                        selected={key === initialStatus}
                      >
                        {value}
                      </option>
                    );
                  }
                )}
              </select>
            </div>
            {data?.OrderType?.toUpperCase() ===
            OrderTypeTypes?.delivery?.toUpperCase() ? (
              <div className="runner-select-container">
                <p className="runner-select">Sélectionner Courreur</p>
                <>
                  {loadingRunnerList ? (
                    <div className="loader-container">
                      <Lottie options={defaultOptions} height={50} width={50} />
                    </div>
                  ) : (
                    <select
                      ref={selectRunnerRef}
                      className={`select-container`}
                      onChange={(e) => {
                        setIsChangedStatus(true);
                        const selectedRunnerObject = activeRunners.find(
                          (oneRunner) => oneRunner?.id === e?.target?.value
                        );
                        setSelectedRunner(selectedRunnerObject || {});
                      }}
                      defaultValue={selectedRunner?.id || "noOne"}
                    >
                      <option value={"noOne"}>Aucun Livreur</option>
                      {activeRunners?.map((activeRunner, index) => {
                        return (
                          <option
                            value={activeRunner.id}
                            key={index}
                            selected={activeRunner.id === selectedRunner?.id}
                          >
                            {activeRunner?.Name + " " + activeRunner?.LastName}
                          </option>
                        );
                      })}
                    </select>
                  )}
                </>
              </div>
            ) : null}

            {isChangedStatus ? (
              <div className="confirmation-status">
                <div>Confirmation</div>
                <div className="confirmation-buttons">
                  <button
                    className="no-button"
                    onClick={() => {
                      handleCancelUpdateStatus();
                    }}
                  >
                    No
                  </button>
                  <button
                    className="yes-button"
                    onClick={() => handleConfirmUpdateStatus()}
                  >
                    Yes
                  </button>
                </div>
              </div>
            ) : null}
          </>
        )}
        <div className="row-data client-data-container">
          <>
            <p>Client</p>
          </>
          <div className="data-style">{data?.Client.FullName}</div>
        </div>
        <div className="row-data">
          <>
            <p>Tél</p>
          </>
          <div className="data-style">+216 {data?.Client.phoneNumber}</div>
        </div>

        <div className="row-data">
          <>
            <p>Adresse</p>
          </>
          <div className="data-style">{data?.Client.address.name}</div>
        </div>

        <div className="row-data store-data-container">
          <>
            <p>Store</p>
          </>
          <div className="data-style">{data?.Store.name}</div>
        </div>

        <div className="row-data">
          <>
            <p>Tél</p>
          </>
          <div className="data-style">+216 {data?.Store.phoneNumber}</div>
        </div>
        <div className="row-data">
          <>
            <p>Delais de préparation</p>
          </>
          <div className="data-style">-</div>
        </div>

        <div className="row-data runner-data-container">
          <>
            <p>Runner</p>
          </>
          <div className="data-style">
            {data?.Runner?.LastName
              ? data?.Runner?.LastName + " " + data?.Runner?.Name
              : " - "}
          </div>
        </div>

        <div className="row-data">
          <>
            <p>Tél</p>
          </>
          <div className="data-style">
            {data?.Runner?.LastName ? data?.deliveredBy : " - "}
          </div>
        </div>

        <div className="row-data">
          <>
            <p>Delais de livraison</p>
          </>
          <div className="data-style"> - </div>
        </div>
      </div>
    </>
  );
}

export default SlideOutOrderDetailsPage;
