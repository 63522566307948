import "./index.scss";

import { homePageDataTypes } from "../utils/HomePageDataTypes";

type HomeScrreenDetailsCardProps = {
  data: homePageDataTypes;
};

function HomeScrreenDetailsCard({
  data,
}: HomeScrreenDetailsCardProps): JSX.Element {
  return (
    <div className="card-container">
      <div
        className="icon-container"
        style={{
          background: data?.iconBackgroundColor,
        }}
      >
        <img className="icon" src={data?.icon} alt={`${data?.title} Icon`} />
      </div>
      <div className="card-content">
        <strong style={{ color: data?.textColor }}>{data?.value}</strong>
        <p>{data?.title}</p>
      </div>
    </div>
  );
}

export default HomeScrreenDetailsCard;
