// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slide-out-panel {
  position: fixed;
  bottom: -18px;
  height: 85vh;
  width: 35%;
  background: linear-gradient(267.26deg, #f1fffc -14.03%, #fffcf7 123.32%);
  box-shadow: 0px 0px 12px rgba(30, 161, 130, 0.3);
  border-radius: 4px 0px 0px 0px;
  right: 0;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}`, "",{"version":3,"sources":["webpack://./src/screens/orders-screen/slideout-panel/index.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EAEA,YAAA;EACA,UAAA;EACA,wEAAA;EACA,gDAAA;EACA,8BAAA;EACA,QAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;AAAF","sourcesContent":[".slide-out-panel {\n  position: fixed;\n  bottom: -18px;\n  // right: -300px; // start offscreen\n  height: 85vh;\n  width: 35%;\n  background: linear-gradient(267.26deg, #f1fffc -14.03%, #fffcf7 123.32%);\n  box-shadow: 0px 0px 12px rgba(30, 161, 130, 0.3);\n  border-radius: 4px 0px 0px 0px;\n  right: 0; // animate into view\n  display: flex;\n  flex-direction: column;\n  overflow: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
