import { OrderStatusType } from "../../../utils/Types/OrderStatusTypes";

type getAllStatusOutput = {
  [key in OrderStatusType]: string;
};

const orderDatraStatusDescription: getAllStatusOutput = {
  notAssigned: "Placée",

  canceled: "Annulée",

  assigned: "Acceptée",

  confirmed: "Confirmée",

  rejected: "Rejectée",

  ready: "Prête",

  picked: "Ramassée",

  delivered: "Livrée",

  notdelivered: "Non livré",

  returned: "Retournée",

  taken: "Prise",
};

export default orderDatraStatusDescription;
