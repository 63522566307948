import "./index.scss";
import "react-phone-number-input/style.css";

import { GoogleMap, Marker } from "@react-google-maps/api";
import Input from "react-phone-number-input/input";
import { toast } from "react-toastify";
import { useLayoutEffect, useState } from "react";
interface positionMap {
  lat: number;
  lng: number;
}
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useLayoutEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
const NewStoreModal = ({
  closeModal,
  setStep,
  addressPosition,
  setPhoneNumber,
  phoneNumber,
  storeName,
  setStoreName,
  doAddStore,
}: any) => {
  const center: positionMap = {
    lat: addressPosition.position.latitude,
    lng: addressPosition.position.longitude,
  };

  const { width, height } = useWindowDimensions();
  const submit = () => {
    if (phoneNumber.length > 12) {
      toast.error("phoneNumber is too long");
      return;
    }
    if (phoneNumber.length < 12) {
      toast.error("phoneNumber is too short");
      return;
    }
    if (!Boolean(addressPosition?.addressName)) {
      toast.error("you need to complete the address");
      return;
    }
    doAddStore();
  };

  return (
    <div className="container">
      <div className="header-content">
        <p className="title-new-store">New Store</p>
        <div className="close-wrapper" onClick={() => closeModal()}>
          <i className="bx bx-x" style={{ fontSize: 22 }}></i>
        </div>
      </div>
      <div className="pzz-modal-body">
        <div className="body-header-content">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: width > 1020 ? "row" : "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: width > 1020 ? "60%" : "100%",
              }}
            >
              <label className="label-name">Nom</label>
              <input
                type="text"
                color="#000"
                value={storeName}
                placeholder="ex: store sahloul 1"
                style={{
                  minHeight: 54,
                  minWidth: "40%",
                  width: "90%",
                  borderWidth: 0,
                  borderRadius: 4,
                  backgroundColor: "#fff",
                  boxShadow: `0px 4px 16px 0px rgba(0, 0, 0, 0.05)`,
                  paddingLeft: 10,
                }}
                onChange={(e) => setStoreName(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: width > 1020 ? "50%" : "100%",
              }}
            >
              <label className="label-phone">Téléphone</label>
              <Input
                style={{
                  minHeight: 54,
                  minWidth: "40%",
                  width: "90%",
                  borderWidth: 0,
                  borderRadius: 4,
                  backgroundColor: "#fff",
                  paddingLeft: 10,
                  boxShadow: `0px 4px 16px 0px rgba(0, 0, 0, 0.05)`,
                }}
                country="TN"
                international
                withCountryCallingCode
                value={phoneNumber}
                onChange={setPhoneNumber}
              />
            </div>
          </div>
          {Boolean(addressPosition.addressName) ? (
            <div style={{ height: "60%", width: "100%" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label className="label-address">Adresse</label>
                <label className="label-address-value">
                  {addressPosition?.addressName}
                </label>
              </div>

              <GoogleMap
                clickableIcons={false}
                zoom={15}
                center={center}
                mapContainerClassName="map-container"
              >
                <Marker
                  position={center}
                  visible={true}
                  zIndex={1000}
                  cursor="pointer"
                />
              </GoogleMap>
              <div
                className="edit-address-wrapper"
                onClick={() => setStep("second")}
              >
                <p style={{ color: "#000", fontSize: 14 }}>
                  Modifier l'adresse
                </p>
                <i className="bx bx-pin" style={{ fontSize: 22 }}></i>
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginTop: 15,
                }}
              >
                <label className="label-address">Adresse</label>
                <div
                  style={{
                    justifyContent: width > 1020 ? "space-between" : "center",
                  }}
                  className="add-address-wrapper"
                  onClick={() => setStep("second")}
                >
                  {width > 1020 ? (
                    <p style={{ color: "#000", fontSize: 14 }}>
                      Ajouter l'adresse
                    </p>
                  ) : null}

                  <i className="bx bx-pin" style={{ fontSize: 22 }}></i>
                </div>
              </div>
            </div>
          )}
          <div></div>
        </div>
        <div className="store-img-wrapper">
          <div className="img-store-content">
            <img
              src="./../../../assets/pizzaShop.png"
              alt="imagestore"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          disabled={
            phoneNumber?.length === 12 &&
            Boolean(storeName) &&
            Boolean(addressPosition.position.latitude)
              ? false
              : true
          }
          style={{
            borderWidth: 0,
            color: "#fff",
            width: "20%",
            height: 45,
            backgroundColor:
              phoneNumber?.length == 12 &&
              Boolean(storeName) &&
              Boolean(addressPosition.position.latitude)
                ? "#1EA182"
                : "#C7C7C7",
            borderRadius: 4,
            fontSize: 16,
            fontWeight: "700",
          }}
          onClick={() => submit()}
        >
          {width > 1020 ? (
            "Sauvegarder"
          ) : (
            <i
              className="bx bx-save"
              style={{
                fontSize: 22,
                color: "#000",
              }}
            ></i>
          )}
        </button>
      </div>
    </div>
  );
};
export default NewStoreModal;
