type dataSetType = {
  activeRunner: any;
  loadingRunnerData: boolean;
};

const omsActiveRunners = {
  initialState: { activeRunner: [], loadingRunnerData: false },
  reducers: {
    addActiveRunners(dataSet: dataSetType, action: { payload: any }) {
      dataSet.activeRunner = action?.payload ?? [];
    },

    deleteActiveRunner(dataSet: dataSetType, action: { payload: string }) {
      const excludeById = dataSet.activeRunner?.filter(
        (runner) => runner?.id !== action?.payload
      );

      dataSet.activeRunner = [...excludeById];
    },

    editActiveRunner(dataSet: dataSetType, action: { payload: any }) {
      const runnerId = action?.payload;
      const orderIndex = dataSet.activeRunner.findIndex(
        (runner) => runner?.id === runnerId
      );
      if (orderIndex && orderIndex >= 0) {
        dataSet.activeRunner[orderIndex] = {
          ...action?.payload,
        };
      } else {
        dataSet.activeRunner = [...dataSet.activeRunner, action?.payload];
      }
    },

    setRunnerDataLoading(dataSet: dataSetType, action: { payload: boolean }) {
      dataSet.loadingRunnerData = true;
    },

    unsetRunnerDataLoading(dataSet: dataSetType, action: { payload: boolean }) {
      dataSet.loadingRunnerData = false;
    },

    reset(dataSet: dataSetType, action) {
      dataSet.activeRunner = [];
    },
  },
};

export default omsActiveRunners;

export const OMS_ADD_ALL_ACTIVE_RUNNERS = "omsActiveRunners/addActiveRunners";

export const OMS_EDIT_ACTIVE_RUNNERS = "omsActiveRunners/editActiveRunner";

export const OMS_DELETE_ACTIVE_RUNNERS = "omsActiveRunners/deleteActiveRunner";

export const OMSS_RESET_ACTIVE_RUNNERS = "omsActiveRunners/reset";

export const OMSS_SET_LOADING_ACTIVE_RUNNERS =
  "omsActiveRunners/setRunnerDataLoading";

export const OMSS_UNSET_LOADING_ACTIVE_RUNNERS =
  "omsActiveRunners/unsetRunnerDataLoading";
