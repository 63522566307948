


import { getRequest } from "../../utils/axios";

type DataFromAxiosType = {
  data: {
    success: boolean;
    message: string;
    status: number;
    content: Record<string, any>;
  };
};

async function getPhoto(pizzaName) {
  try {
    const  res  = await getRequest(`/photo?pizza=${pizzaName}`);
  

    return res
    
  } catch (error: any) {
    const returnedData: DataFromAxiosType = {
      data: {
        success: false,
        message: error?.message ?? "",
        status: error?.response?.status ?? 500,
        content: [],
      },
    };
    return returnedData;
  }
}

export default getPhoto;
