import "./index.scss";

import { useEffect, useRef, useState } from "react";

import { Link, useLocation } from "react-router-dom";

import pizzaieLogo from "../../assets/pizzaie-logo.png";
import { sidebarNavItems } from "../../utils";

const Sidebar = ({ isMobileMenuOpen , toggleMobileMenu }: any) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [stepHeight, setStepHeight] = useState(0);
  const sidebarRef = useRef<any>();
  const indicatorRef = useRef<any>();
  const location = useLocation();
  const [displayRhOptions, setDisplayRhOptions] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      const sidebarItem = sidebarRef.current.querySelector(
        ".sidebar__menu__item"
      );
      indicatorRef.current.style.height = `${sidebarItem.clientHeight}px`;
      setStepHeight(sidebarItem.clientHeight);
    }, 50);
  }, []);

  // change active index
  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];
    const activeItem = sidebarNavItems.findIndex(
      (item) => item.section === curPath
    );
    if (activeItem === 7 || curPath === "courier") {
      setDisplayRhOptions(true);
      return;
    } else {
      setDisplayRhOptions(false);
    }

    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);

  return (
    <div className="sidebar">
      <div className="logo-container">
        <div className={isMobileMenuOpen ? "sidebar__logo-m" : "sidebar__logo"}>
          <img src={pizzaieLogo} alt="Pizzaie LOGO" />
          Dashboard
        </div>
        {isMobileMenuOpen && (
          <div onClick={() => toggleMobileMenu()}>
            <i className="bx bx-x" style={{fontSize : 22 , fontWeight : 700}}></i>
          </div>
        )}
      </div>

      <div ref={sidebarRef} className="sidebar__menu">
        <div
          ref={indicatorRef}
          className="sidebar__menu__indicator"
          style={{
            transform: `translateX(-50%) translateY(${
              activeIndex * stepHeight
            }px)`,
          }}
        ></div>
        {sidebarNavItems?.map((item, index) => {
          return (
            <div key={index}>
              {item.section === "rh" ? (
                <>
                  <div
                    onClick={() => {
                      setDisplayRhOptions(true);
                      setActiveIndex(7);
                    }}
                  >
                    <div
                      className={`sidebar__menu__item ${
                        activeIndex === index ? "active" : ""
                      }`}
                    >
                      <div className="sidebar__menu__item__icon">
                        {item.icon}
                      </div>
                      <div className="sidebar__menu__item__text">
                        {item.display}
                      </div>
                    </div>
                  </div>
                  {displayRhOptions && (
                    <>
                      <Link
                        to="/courier"
                        style={{
                          height: 55,
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          paddingInline: 27,
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            height: "100%",
                            marginRight: 15,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={require("./../../assets/runnerIcon.png")}
                            alt="thh"
                          />{" "}
                        </div>
                        <div>
                          <p style={{ color: "#000" }}>ZZ Courier</p>
                        </div>
                      </Link>
                      <div
                        style={{
                          height: 55,
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          paddingInline: 27,
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            height: "100%",
                            marginRight: 15,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <i
                            className="bx bx-user"
                            style={{ fontSize: 28 }}
                          ></i>
                        </div>
                        <div>
                          <p style={{ color: "#000" }}>Personnels</p>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <Link to={item.to} key={index}>
                  <div
                    className={`sidebar__menu__item ${
                      activeIndex === index ? "active" : ""
                    }`}
                  >
                    <div className="sidebar__menu__item__icon">
                      {item?.icon}
                    </div>
                    <div className="sidebar__menu__item__text">
                      {item?.display}
                    </div>
                  </div>
                </Link>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
