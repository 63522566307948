import "./index.scss";

import React, { useState } from "react";

import orderDatraStatusDescription from "../helpers/orderDatraStatusDescription";

import moment from "moment";
import QrIcon from "./../../../assets/QrIcon.svg"
import { OrderDataTypes } from "../../../utils/Types/OrdersDataTypes";
import { OrderHistoryType } from "../slideout-panel/order-tracking-page/helpers/order-history-type";
import ModalQr from "../../../components/ModalQr";
import QRCodePrintComponent from "../../../components/QRcode-Print";

type OrderRowDataPropsType = {
  row: OrderDataTypes;
  ordersHistory: OrderHistoryType[];
  handleRowClick: Function;
  panelData: OrderDataTypes;
  loadingGetRunnerData: boolean;
};

function OrderRowData({
  row,
  ordersHistory,
  handleRowClick,
  panelData,
  loadingGetRunnerData,
}: OrderRowDataPropsType): JSX.Element {
  const [remainingTime, setRemainingTime] = React.useState<string>(null);
  const [qrIsOpen, setQrIsOpen] = useState(false);
  const [opendOrderQr, setOpendOrderQr] = useState<any>(null);

  React.useEffect(() => {
    const singleOrderHistory = ordersHistory?.find(
      (oneOrder) => oneOrder.orderId === row.id
    );

    const endDate = moment(
      singleOrderHistory?.status[singleOrderHistory?.status?.length - 1].date ||
        row.CreationDate,
      "DD/MM/YYYY HH:mm:ss"
    );
    const now = moment();

    const diffInSeconds = now.diff(endDate, "seconds");

    if (
      singleOrderHistory?.status[singleOrderHistory?.status?.length - 1]
        .status === "delivered" ||
      singleOrderHistory?.status[singleOrderHistory?.status?.length - 1]
        .status === "returned" ||
      singleOrderHistory?.status[singleOrderHistory?.status?.length - 1]
        .status === "canceled" ||
      singleOrderHistory?.status[singleOrderHistory?.status?.length - 1]
        .status === "rejected" ||
      singleOrderHistory?.status[singleOrderHistory?.status?.length - 1]
        .status === "taken"
    ) {
      setRemainingTime("Done");
      return () => {};
    }
    if (diffInSeconds > 0) {
      setRemainingTime(
        moment
          .utc(moment.duration(diffInSeconds, "seconds").asMilliseconds())
          .format("HH:mm:ss")
      );

      const intervalId = setInterval(() => {
        setRemainingTime((prevTime) => {
          const newTime = moment
            .utc(moment.duration(prevTime).asMilliseconds() + 1000)
            .format("HH:mm:ss");
          return newTime;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }

    setRemainingTime("Done");
  }, [ordersHistory, row.id, row.Status]);
  const openQrCode = (order: any) => {
    console.log("yess open qr");

    setOpendOrderQr(order);
    setQrIsOpen(true);
  };
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    order: any
  ) => {
    event.preventDefault(); // Prevent default form behavior
    event.stopPropagation(); // Stop event from bubbling up

    openQrCode(order); // Call your action
  };
  const closeQrCode = () => {
    setQrIsOpen(false);
  };
  return (
    <>
      <React.Fragment key={row.id}>
        <tr
          onClick={() => handleRowClick(row.id)}
          style={{ cursor: "pointer" }}
        >
          <td className={row?.id === panelData?.id ? "selected-td" : ""}>
            <img src={QrIcon} onClick={(e: any) => handleClick(e, row)} />
          </td>
          <td className={row?.id === panelData?.id ? "selected-td" : ""}>
            {row?.orderNumber}
          </td>
          <td className={row?.id === panelData?.id ? "selected-td" : ""}>
            {row?.Client?.phoneNumber}
          </td>
          <td className={row?.id === panelData?.id ? "selected-td" : ""}>
            {loadingGetRunnerData
              ? "Loading..."
              : row?.Runner?.fullName || " - "}
          </td>
          <td className={row?.id === panelData?.id ? "selected-td" : ""}>
            {row?.Store?.name}
          </td>
          <td className={row?.id === panelData?.id ? "selected-td" : ""}>
            {row?.CreationDate}
          </td>
          <td className={row?.id === panelData?.id ? "selected-td" : ""}>
            {row?.OrderType}
          </td>
          <td
            className={
              row?.id === panelData?.id ? "timer selected-td" : "timer"
            }
          >
            {remainingTime}
          </td>
          <td className={row?.id === panelData?.id ? "selected-td" : ""}>
            {orderDatraStatusDescription[row?.Status]}
          </td>
        </tr>
      </React.Fragment>
      {qrIsOpen && (
        <ModalQr onClose={() => closeQrCode()}>
          <QRCodePrintComponent order={opendOrderQr} />
        </ModalQr>
      )}
    </>
  );
}

export default OrderRowData;
