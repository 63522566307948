import PhoneInput from "react-phone-number-input/input";
import "./index.scss";
import { Formik } from "formik";
import addRunner from "../../../services/api/post-runner";
import { toast } from "react-toastify";
import { RunnerType } from "../../../utils/Types/RunnerType";
import { useEffect } from "react";

interface props {
  getInitialeListRunners: Function;
  closeModal: Function;
  success: boolean;
  setSuccess: (args: boolean) => void;
}

const ModalAddRunner = ({
  getInitialeListRunners,
  closeModal,
  success,
  setSuccess,
}: props) => {
  const doAddRunner = (data: any) => {
    const bodyData: RunnerType = {
      phoneNumber: Number(data.phoneNumber.substring(4)),
      Name: data.name,
      LastName: data.lastname,
      Email: data.email,
    };
    addRunner(bodyData)
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
          return;
        }
        if (response.data.status === 500) {
          toast.error("Internal server error");
          return;
        }
        if (response.data.success) {
          toast.success("runner added successfuly");
          getInitialeListRunners();
          setSuccess(true);
          setTimeout(() => {
            closeModal();
          }, 2000);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    return () => setSuccess(false);
  }, []);

  return (
    <>
      {success ? (
        <div className="modal-add-runner-container">
          <div className="content-h">
            <i
              className="bx bx-check"
              style={{ fontSize: 72, fontWeight: "700", color: "#159B7B" }}
            ></i>
          </div>
          <div className="body-success">
            <p style={{ textAlign: "center", color: "#000" }}>
              ZZ courier ajouté avec succès
            </p>
          </div>
        </div>
      ) : (
        <div className="modal-add-runner-container">
          <div className="modal-add-runner-header">
            <div className="modal-add-runner-title-container">
              <p className="modal-add-runner-title-txt">Nouveau ZZ courier</p>
            </div>
            <div
              className="modal-runner-close-btn-container"
              onClick={() => closeModal()}
            >
              <i className="bx bx-x" style={{ fontSize: 24 }}></i>
            </div>
          </div>
          <div className="modal-add-runner-body">
            <div className="body-inputs-container">
              <Formik
                initialValues={{
                  name: "",
                  lastname: "",
                  phoneNumber: "",
                  email: "",
                }}
                validate={(values) => {
                  const errors: any = {};
                  if (!values.email) {
                    errors.email = "Required";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = "Invalid email address";
                  }
                  if (!values.name) {
                    errors.name = "Required *";
                  }
                  if (!values.phoneNumber) {
                    errors.phoneNumber = "Required *";
                  }
                  if (!values.lastname) {
                    errors.lastname = "Required *";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    doAddRunner(values);
                    setSubmitting(false);
                  }, 400);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form className="form-container" onSubmit={handleSubmit}>
                    <label className="label-style-n">Nom</label>
                    <input
                      className="input-name-style"
                      type="text"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    <p
                      style={{
                        color: "red",
                        fontSize: 11,
                        fontWeight: 400,
                        marginLeft: 10,
                      }}
                    >
                      {" "}
                      {errors.name && touched.name && errors.name}{" "}
                    </p>

                    <label className="label-style-n">Prénom</label>
                    <input
                      className="input-name-style"
                      type="text"
                      name="lastname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastname}
                    />
                    <p
                      style={{
                        color: "red",
                        fontSize: 11,
                        fontWeight: 400,
                        marginLeft: 10,
                      }}
                    >
                      {errors.lastname && touched.lastname && errors.lastname}
                    </p>

                    <label className="label-style-n">Téléphone</label>
                    <div className="input-phone-style">
                      <PhoneInput
                        className="input-phone-style"
                        style={{
                          minHeight: 54,
                          minWidth: "40%",
                          width: "90%",
                          borderWidth: 0,
                          borderRadius: 4,
                          backgroundColor: "#fff",
                          paddingLeft: 10,
                        }}
                        country="TN"
                        international
                        withCountryCallingCode
                        value={values.phoneNumber}
                        onChange={(phoneNumber) => {
                          handleChange({
                            target: { name: "phoneNumber", value: phoneNumber },
                          });
                        }}
                        onBlur={handleBlur}
                      />
                    </div>
                    <p
                      style={{
                        color: "red",
                        fontSize: 11,
                        fontWeight: 400,
                        marginLeft: 10,
                      }}
                    >
                      {" "}
                      {errors.phoneNumber &&
                        touched.phoneNumber &&
                        errors.phoneNumber}
                    </p>
                    <label className="label-style-n">Email</label>
                    <input
                      className="input-email-style"
                      type="text"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <p
                      style={{
                        color: "red",
                        fontSize: 11,
                        fontWeight: 400,
                        marginLeft: 10,
                      }}
                    >
                      {errors.email && touched.email && errors.email}
                    </p>

                    <button
                      className="modal-add-runner-btn-add-style"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Ajouter
                    </button>
                  </form>
                )}
              </Formik>
            </div>
            <div className="body-image-container">
              <div className="image-runner-container">
                <img
                  src={require("./../../../assets/runnerImage.png")}
                  alt="imagerunner"
                  className="img-runner-style"
                />
              </div>
            </div>
          </div>
          <div className="modal-add-runner-footer"></div>
        </div>
      )}
    </>
  );
};
export default ModalAddRunner;
