// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-date-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1.5px;
  margin-top: 25px;
}
.full-date-container .divider-style {
  width: 100%;
  margin-left: 20px;
  border: 0.2px solid rgba(219, 218, 218, 0.3137254902);
}

.status-history-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 16px;
}
.status-history-container .status-history-time {
  font-size: 16px;
  min-width: 20%;
}
.status-history-container .status-history-details {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/screens/orders-screen/slideout-panel/order-tracking-page/order-tracking-body/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;EACA,gBAAA;AACF;AAAE;EACE,WAAA;EACA,iBAAA;EACA,qDAAA;AAEJ;;AACA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;AAEF;AADE;EACE,eAAA;EACA,cAAA;AAGJ;AADE;EACE,gBAAA;EACA,eAAA;EACA,cAAA;AAGJ","sourcesContent":[".full-date-container {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  font-size: 12px;\n  font-weight: 700;\n  letter-spacing: 1.5px;\n  margin-top: 25px;\n  .divider-style {\n    width: 100%;\n    margin-left: 20px;\n    border: 0.2px solid #dbdada50;\n  }\n}\n.status-history-container {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  margin-top: 20px;\n  margin-bottom: 5px;\n  font-size: 16px;\n  .status-history-time {\n    font-size: 16px;\n    min-width: 20%;\n  }\n  .status-history-details {\n    font-weight: 600;\n    font-size: 16px;\n    color: #000000;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
