import { toast } from "react-toastify";
import deletePizza from "../../services/api/delete-pizza";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Lottie from "react-lottie";
import { defaultOptionsLoading } from "../../assets/lottie-animations/animationOptions";

interface props {
  closeModal: Function;
  pizzaNameUpdate: string;
}
const ValidationDeleteMoadl = ({ closeModal, pizzaNameUpdate }: props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const doDeletePizza = () => {
    setLoading(true);
    deletePizza(pizzaNameUpdate)
      .then((response) => {
        setLoading(false);
        if (response.data.status === 500) {
          console.log("server error");
          toast.error("internal server error");

          return;
        }
        if (!response.data.success) {
          toast.error("internal server error");
          console.log("error");
          return;
        }
        if (response.data.success) {
          toast.success("pizza deleted successfuly");
          navigate("/pizza");
          return;
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("error" + error);
      });
  };

  return (
    <>
      <div className="hight-container-vd">
        <div className="pzz-header-delete">
          <p className="pzz-title-delete">
            Vous êtes sûre de vouloir supprimer{" "}
          </p>
        </div>
        <div className="pzz-body-delete">
          <p className="pzz-body-text-delete">
            Pizza {pizzaNameUpdate.substring(4)}
          </p>
        </div>
        <div className="pzz-footer-delete">
          <div
            className="pzz-cancel-btn-container-delete"
            onClick={() => closeModal()}
          >
            <button className="pzz-cancel-btn-delete">Annuler</button>
          </div>
          {loading ? (
            <div className="pzz-delete-btn-container-delete">
              <Lottie options={defaultOptionsLoading} height={60} width={60} />
            </div>
          ) : (
            <div
              className="pzz-delete-btn-container-delete"
              onClick={() => doDeletePizza()}
            >
              <button className="pzz-delete-btn-delete">Supprimer</button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ValidationDeleteMoadl;
